// @flow
import React from 'react';
import styled from 'styled-components';
import { getFont } from '@finect/tabular-utils/getFont';
import { Icon } from '@finect/tabular-components/Icon';
import { DateLabel } from '@finect/tabular-components/DateLabel';
import { EntityCard } from '@finect/tabular-components/EntityCard';
import { SceneLink } from '@finect/front-core/Routes';
import { HTML } from '@finect/tabular-components/HTML';
import { getCommentHTML } from '../../utils/articles';

const CommentBlock = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.uiL};
  margin: 24px 0;
`;

const CommentAuthor = styled.div`
  display: flex;
  align-items: center;
  ${getFont(0, 'compact')};
`;

const DateDetail = styled.span`
  align-items: center;
  ${getFont(-1, 'compact')};
`;

const Detail = styled.span`
  ${getFont(-2, 'compact')};
  margin: 0 2px;
  color: ${({ theme }) => theme.fontLightColor};
`;

const CommentContent = styled.div`
  padding-top: 12px;
  padding-left: 48px;
  ${getFont(0, 'compact')};

  br {
    display: block;
    margin: 16px 0;
    content: '';
    font-size: 24%;
  }

  a {
    color: ${({ theme }) => theme.linkColor};
  }

  p {
    &:not(:last-child) {
      margin-bottom: 21px;
    }
  }
`;

const CommentImage = styled.div`
  padding-top: 12px;
  padding-left: 48px;

  img {
    max-width: 100%;
    height: auto;
  }
`;

const CommentAuthorIcon = styled.span``;

type Props = {
  comment: Object
};

export const Comment = ({ comment }: Props) => {
  const parsedComment = getCommentHTML(comment.body.text);

  const getUserProps = () => {
    const { features = [] } = comment.author;

    return features.includes('profile')
      ? {
        scene: 'UserProfile',
        title: comment.author.displayName,
        alias: comment.author.alias
      }
      : {
        Component: SceneLink
      };
  };

  const getOrganizationProps = () => ({
    scene: 'GroupProfile',
    profile: comment.author.alias
  });

  const iconProps = ['user'].includes(comment.author.subtype) ? getUserProps() : getOrganizationProps();

  const IconComponent =
    (['user'].includes(comment.author.subtype) && comment.author.features.includes('profile')) ||
    ['organization'].includes(comment.author.subtype)
      ? SceneLink
      : CommentAuthorIcon;

  return (
    <CommentBlock>
      <CommentAuthor>
        <IconComponent {...iconProps}>
          <Icon
            size={1}
            alt={comment.author.displayName}
            image={`https://${process.env.FINECT_BASEURL || 'www.finect.com'}${comment.author.image}`}
            margin="0 12px 0 0"
            verified={comment.author.verified}
          />
        </IconComponent>

        <div>
          <EntityCard
            type="profile"
            entity={{
              ...comment.author,
              name: comment.author.displayName,
              icon: `/image/${comment.author.icon}`
            }}
          >
            {comment.author.displayName}
          </EntityCard>

          <Detail> · </Detail>
          <DateDetail>
            <DateLabel date={new Date(comment.created)} format="LLLS" />
          </DateDetail>
        </div>
      </CommentAuthor>

      {comment.image ? (
        <CommentImage>
          <img src={`https://${process.env.FINECT_BASEURL || 'www.finect.com'}/image/${comment.image}`} />
        </CommentImage>
      ) : null}

      <HTML wrapper={CommentContent} html={parsedComment} />
    </CommentBlock>
  );
};
