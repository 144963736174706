// @flow
import styled from 'styled-components';
import { AppLink } from '@finect/tabular-core/AppLink';

export const BannerBlock = styled.div`
  margin-bottom: 64px;

  ${/* sc-sel */ AppLink} {
    display: block;
    text-align: center;
  }
`;
