// @flow
import React from 'react';
import styled from 'styled-components';
import { Section } from '@finect/tabular-core/Section';
import { H2 } from '@finect/tabular-core/Headings';
import { ParagraphSmall } from '@finect/tabular-core/Paragraph';
import { getProductTypeBySlug, getProductTypeLabel } from '@finect/front-resources/Products';
import { Small } from '@finect/tabular-core/Small';
import { Button } from '@finect/tabular-core/Button';
import { AppLink } from '@finect/tabular-core/AppLink';
import { Datalayer } from '@finect/front-gtm';
import { isBrowser } from '@finect/front-utils/isBrowser';
import { Head } from '@finect/tabular-components/Head';

const SectionInner = styled.div`
  min-height: 380px;
  padding: 90px 30px 30px;
  text-align: center;

  h2,
  p {
    display: block;
  }

  a,
  small {
    color: #fff;
  }
`;

type Props = {
  location?: Object,
  match: Object,
  staticContext?: Object,
  title?: string,
  metaTitle?: string,
  message?: string,
  redirectPath?: string,
  actionText?: string
};

export const ProductNotFound = (props: Props) => {
  const productType = getProductTypeBySlug(props.match.params.typeSlug);
  const {
    location,
    message = '',
    title = 'Ups! Lo sentimos, este producto no está disponible en Finect.',
    metaTitle = 'Producto no disponible'
  } = props;

  const canonical = `${location?.pathname || (productType ? `/${productType.slug}` : '')}`;
  const redirectPath = `/${productType ? `${productType.slug}${props.redirectPath || ''}` : ''}`;
  const actionText = `Ver otros ${getProductTypeLabel(productType, 'plural', 'lowercase') || 'productos'}`;

  return (
    <Section>
      <Head title={metaTitle} robots="noindex" canonical={canonical} />

      {isBrowser() ? (
        <Datalayer
          id="FiEvent"
          type="fiEvent"
          params={{
            category: 'ErrorProduct',
            label: '404',
            action: location ? location.pathname + location.search : undefined
          }}
        />
      ) : null}

      <SectionInner center>
        <H2>{title}</H2>
        <ParagraphSmall center>
          {message}
          <br />
          <Small light>(Error 404)</Small>
        </ParagraphSmall>

        <Button as={AppLink} variant="medium" width="auto" href={redirectPath}>
          {actionText}
        </Button>
      </SectionInner>
    </Section>
  );
};
