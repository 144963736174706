// @flow
import styled from 'styled-components';
import { getFont } from '@finect/tabular-utils/getFont';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { AppLink } from '@finect/tabular-core/AppLink';

export const Inner = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius};
  border-color: rgb(0 0 0 / 0.2);
  box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.uiL};
  box-shadow: rgb(0 0 0 / 0.2) 0 1px 2px 0, rgb(0 0 0 / 0.2) 0 0 3px 0;
  padding: 6px 0;

  ${maxMediaQueries.tablet`
    margin-bottom: 24px;
  `};
`;

export const MainBlock = styled.div`
  padding: 12px 18px 18px 0;
`;

export const DateBlock = styled.div`
  ${getFont(-2)}
  color: ${({ theme }) => theme.colors.fontL};
`;

export const ViewMoreAction = styled(AppLink)`
  display: flex;
  align-items: center;
  margin-top: 7px;
  ${getFont(0)};

  svg {
    margin-left: 6px;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    svg {
      margin-left: 9px;
    }
  }
`;

export const Image = styled.div`
  display: block;
  position: relative;
  flex-flow: column;
  flex-shrink: 0;
  width: 88px;
  height: 88px;
  border-radius: 4px;
  margin: auto 16px;
  background-image: url('${({ imageSrc }) => imageSrc}');
  background-position: contain;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Title = styled.a`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.font};
  ${getFont(0, 'compact')};

  &:hover {
    opacity: 0.9;
  }
`;
