// @flow
import { HOME_FAMILIES } from './data';

type StoreType = {
  families: Array<Object>,
  evergreenArticles: Array<{
    tag: string,
    items: Array<Object>
  }>,
  products: Array<{
    tag: string,
    items: Array<Object>
  }>,
  homeProducts: Array<Object>,
  questions: Array<{
    tag: string,
    items: Array<Object>
  }>,
  managers: Array<{
    tag: string,
    items: Array<Object>
  }>,
  managersContents: Array<{
    manager: string,
    items: Array<Object>
  }>,
  highlights: Array<Object>,
  reviews: {
    items: Array<Object>,
    paging: Object
  }
};

export function home(
  state: StoreType = {
    families: HOME_FAMILIES,
    evergreenArticles: [],
    products: [],
    homeProducts: [],
    questions: [],
    managers: [],
    managersContents: [],
    highlights: [],
    unMinuto: [],
    reviews: {
      items: [],
      authors: [],
      paging: {}
    }
  },
  action: Object
) {
  switch (action.type) {
    case 'SET_HOME_REVIEWS': {
      return {
        ...state,
        reviews: {
          items: [...action.reviews.reviews],
          authors: [...action.reviews.authors],
          paging: { ...action.paging }
        }
      };
    }

    case 'SET_HOME_HIGHLIGHTS': {
      return {
        ...state,
        highlights: [...action.highlights]
      };
    }

    case 'SET_HOME_UN_MINUTO': {
      return {
        ...state,
        unMinuto: [...action.items]
      };
    }

    case 'SET_HOME_TAG_EVERGREEN_ARTICLES': {
      const newState = { ...state };

      const tag = {
        tag: action.tag,
        items: action.items
      };

      newState.evergreenArticles = [...newState.evergreenArticles, tag];

      return { ...state, ...newState };
    }

    case 'SET_HOME_TAG_PRODUCTS': {
      const newState = { ...state };

      const tag = {
        tag: action.tag,
        items: action.items
      };

      newState.products = [...newState.products, tag];

      return { ...state, ...newState };
    }

    case 'SET_HOME_PRODUCTS': {
      const newState = { ...state };

      newState.homeProducts = [...action.items];

      return { ...state, ...newState };
    }

    case 'SET_HOME_TAG_EVERGREEN_QUESTIONS': {
      const newState = { ...state };

      const tag = {
        tag: action.tag,
        items: action.items
      };

      newState.questions = [...newState.questions, tag];

      return { ...state, ...newState };
    }

    case 'SET_HOME_TAG_EVERGREEN_MANAGERS': {
      const newState = { ...state };

      const tag = {
        tag: action.tag,
        items: action.items
      };

      newState.managers = [...newState.managers, tag];

      return { ...state, ...newState };
    }

    case 'SET_HOME_TAG_EVERGREEN_MANAGER_CONTENTS': {
      const newState = { ...state };

      const manager = {
        manager: action.manager,
        items: action.items
      };

      newState.managersContents = [...newState.managersContents, manager];

      return { ...state, ...newState };
    }

    default:
      return state;
  }
}
