// @flow
import React from 'react';
import styled from 'styled-components';
import { FileText } from '@finect/tabular-icons/fileText';
import { Messages } from '@finect/tabular-icons/messages';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';
import { grid } from '@finect/tabular-utils/grid';
import { DateLabel } from '@finect/tabular-components/DateLabel';
import { slugify } from '@finect/front-utils/slugify';
import { Image } from '@finect/tabular-components/Image';
import { getArticleRel } from '@finect/tabular-components/Articles';
import { getArticlePath } from '../../utils/articles';
import { SquareImage, SquareIcon } from '../SquareImage';

export const RelatedContents = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: ${grid(7) + 230 * 2}px;
  margin: 30px auto 0;

  > div {
    width: 50%;

    &:first-child {
      padding-right: 24px;
    }

    &:last-child {
      padding-left: 24px;
    }
  }

  ${maxMediaQueries.tablet`
    display: block;

    > div {
      width: 100%;
      max-width: ${grid(6)}px;
      margin: 0 auto;

      &:first-child {
        padding-right: 0;
      }

      &:last-child {
        padding-top: 12px;
        padding-left: 0;
      }
    }
  `};

  h6 {
    margin-bottom: 24px;
    ${getFont(-1, 'compact')};
    font-weight: 600;
    color: ${({ theme }) => theme.secondaryColor};
  }
`;

const RelatedContent = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  h4 {
    padding-bottom: 3px;
    ${getFont(0, 'xcompact')};
    font-weight: 700;

    a {
      color: currentColor;

      &:hover {
        color: ${({ theme }) => theme.mainColor};
      }
    }

    ${maxMediaQueries.phablet`
      ${getFont(-1, 'compact')};
    `};
  }
`;

const RelatedMeta = styled.div`
  ${getFont(-2, 'compact')};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: ${({ theme }) => theme.fontLightColor};

  strong,
  span {
    margin-right: 6px;
  }
`;

type Props = {
  articles: Array<Object>,
  questions: Array<Object>
};

export const ArticleRecommendedContents = ({ articles, questions }: Props) => (
  <RelatedContents>
    {articles.length ? (
      <div>
        <h6>También te puede interesar</h6>
        {articles.map((article, index) => {
          const articlePath = getArticlePath(article);
          const articleRel = getArticleRel(article);

          return (
            <RelatedContent key={index}>
              {article.image ? (
                <SquareImage
                  rel={articleRel}
                  as="a"
                  href={articlePath}
                  size="72px"
                  margin="0 15px 0 0"
                  data-fi-event="article-bottom"
                  title={article.title}
                >
                  <Image alt={article.title} src={article.image} width={72} height={72} />
                </SquareImage>
              ) : (
                <SquareIcon
                  rel={articleRel}
                  as="a"
                  href={articlePath}
                  size="72px"
                  margin="0 15px 0 0"
                  data-fi-event="article-bottom"
                  title={article.title}
                >
                  <FileText size="42" strokeWidth="1" />
                </SquareIcon>
              )}
              <div>
                <h4>
                  <a href={articlePath} title={article.title} rel={articleRel} data-fi-event="article-bottom">
                    {article.title}
                  </a>
                </h4>
                <RelatedMeta>
                  <strong>{article.author.displayName}</strong>
                  <span>·</span>
                  <DateLabel date={new Date(article.revision.updated)} format={'lll'} />
                </RelatedMeta>
              </div>
            </RelatedContent>
          );
        })}
      </div>
    ) : null}

    {questions.length ? (
      <div>
        <h6>Preguntas relacionadas</h6>
        {questions.map((question, index) => {
          const questionPath = `/preguntas/${question.id}/${slugify(question.title)}`;

          return (
            <RelatedContent key={index}>
              <SquareIcon
                as="a"
                href={questionPath}
                size="72px"
                margin="0 15px 0 0"
                data-fi-event="article-bottom"
                title={question.title}
              >
                <Messages size="42" strokeWidth="4" />
              </SquareIcon>
              <div>
                <h4>
                  <a href={questionPath} title={question.title} data-fi-event="article-bottom">
                    {question.title}
                  </a>
                </h4>
                <RelatedMeta>
                  <strong>{question.author.displayName}</strong>
                  <span>·</span>
                  <DateLabel date={new Date(question.revision.updated)} format={'lll'} />
                </RelatedMeta>
              </div>
            </RelatedContent>
          );
        })}
      </div>
    ) : null}
  </RelatedContents>
);
