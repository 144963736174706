export function questions(
  state = {
    questions: []
  },
  action
) {
  switch (action.type) {
    case 'SET_QUESTIONS_BY_TAG': {
      const newState = { ...state };
      newState.questions = [...action.items];
      return { ...state, ...newState };
    }
    default:
      return state;
  }
}
