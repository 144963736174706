// @flow
import { finectAPI } from '@finect/front-utils/finectAPI';
import { CONTENT_PIECE_DEFINITION } from './contentPieces/contentPieces';

export const getOrganizationPage = (cookie: string, alias: Object) =>
  finectAPI({
    version: 'v4',
    credentials: cookie,
    path: `contents/organizations/${alias}/page`,
    method: 'GET',
    onSuccess: ({ data }: { data: Object }) => data,
    onError: () => null
  });

export const getOrganizationProfile = (cookie: string, alias: Object) =>
  finectAPI({
    version: 'v4',
    credentials: cookie,
    path: `contents/profiles/${alias}`,
    method: 'GET',
    onSuccess: ({ data }: { data: Object }) => data,
    onError: () => null
  });

export const getBankPageContents = (cookie: string, organization: Object, page: Object) => {
  const { tabs = [] } = page || {};

  const contentPieces = tabs.flatMap(({ blocks = [] }) => blocks).flatMap(({ pieces = [] }) => pieces);

  const promises = contentPieces.map((contentPiece: Object): any => {
    const contentAction = CONTENT_PIECE_DEFINITION[contentPiece.type] || CONTENT_PIECE_DEFINITION.NULL;

    const promise = contentAction(cookie, organization, contentPiece);

    return promise.then((result: Object) => ({
      contentPiece,
      contentPieceData: result
    }));
  });

  return Promise.all(promises);
};

/**
 * Recibe un page y un array de pieces y devuelve un page con los pieces reemplazados por los datos
 */
const fillPageWithPiecesData = (page: Object, pieces: Array<Object>) => ({
  ...page,
  tabs: page.tabs.map(tab => ({
    ...tab,
    blocks: tab.blocks.map(block => ({
      ...block,
      pieces: block.pieces.map((piece) => {
        const asyncData = pieces.find(item => item.contentPiece.id === piece.id);

        return {
          ...piece,
          data: asyncData ? asyncData.contentPieceData : null
        };
      })
    }))
  }))
});

export function getOrganizationBankWithCMS(cookie: string, alias: string) {
  return Promise.all([getOrganizationPage(cookie, alias), getOrganizationProfile(cookie, alias)]).then((response) => {
    if (response.some(item => !item)) {
      return Promise.reject();
    }

    const [page, organization] = response;
    return getBankPageContents(cookie, organization, page).then(pieces => ({
      page: fillPageWithPiecesData(page, pieces),
      organization
    }));
  });
}
