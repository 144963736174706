// @flow
import React from 'react';
import { Page, PageTitle, PageSubtitle, Header, Actions } from './partials';

type Props = {
  title?: string,
  subtitle?: string,
  actions?: any,
  bottomMargin?: boolean,
  topMargin?: boolean,
  children?: any
};

export const PageHeader = ({ title, subtitle, actions, bottomMargin = true, children, topMargin = false }: Props) => {
  const displayActions = actions || children;

  return (
    <Header noMargin bottomMargin={bottomMargin} topMargin={topMargin}>
      <Page justify="space-between">
        {title || subtitle ? (
          <div>
            <PageTitle large>{title}</PageTitle>
            {subtitle ? <PageSubtitle>{subtitle}</PageSubtitle> : null}
          </div>
        ) : null}

        {displayActions ? <Actions>{displayActions}</Actions> : null}
      </Page>
    </Header>
  );
};
