// @flow
import styled from 'styled-components';
import { minMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';

export const TableOfContents = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.ui};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.uiLL};
  ${getFont(1, 'xcompact')}

  p {
    margin-bottom: 0;
  }

  a {
    cursor: pointer;
    color: ${({ theme }) => theme.mainColor};
  }

  a:visited {
    color: ${({ theme }) => theme.mainColor};
  }

  h3 {
    ${getFont(2)}
    padding: 4px 0;
    margin-bottom: 8px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.font};
  }

  ul {
    li {
      ${getFont(1, 'xcompact')}
      margin-bottom: 12px;
      list-style: none;
    }
  }
`;

export const WrapperIndex = styled.div`
  ${minMediaQueries.tablet`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
  `}
`;
