// @flow
import React from 'react';
import { finect } from '@finect/tabular-themes/finect';
import { ThemeProvider } from 'styled-components';
import { deepMerge } from '@finect/front-utils/deepMerge';
import { cloneDeep } from '@finect/front-utils/cloneDeep';
import { useSelector } from 'react-redux';
import { darken, lighten } from 'polished';

export const getTheme = (theme: Object) => {
  const originalTheme = cloneDeep(finect);

  if (!theme) {
    return originalTheme;
  }

  const baseColors = {
    primary: theme.colors.primary || theme.mainColor || finect.colors.secondary,
    secondary: theme.colors.secondary || finect.colors.secondary
  };

  const colors = {
    ...baseColors,
    // primaryLL: theme.colors.primaryLL || lighten(0.2, baseColors.primary),
    // primaryL: theme.colors.primaryL || lighten(0.1, baseColors.primary),
    // primaryD: theme.colors.primaryD || darken(0.1, baseColors.primary),
    // primaryDD: theme.colors.primaryDD || darken(0.2, baseColors.primary),

    secondaryLL: theme.colors.secondaryLL || lighten(0.2, baseColors.secondary),
    secondaryL: theme.colors.secondaryL || lighten(0.1, baseColors.secondary),
    secondaryD: theme.colors.secondaryD || darken(0.1, baseColors.secondary),
    secondaryDD: theme.colors.secondaryDD || darken(0.2, baseColors.secondary)
  };

  const baseUseCases = {
    linkColor: colors.primary,
    secondaryButtonColor: theme.secondaryButtonColor || baseColors.secondary,
    secondaryButtonHoverColor: theme.secondaryButtonHoverColor || colors.secondaryD
  };

  const bankTheme = {
    colors,
    ...baseUseCases
  };

  return theme ? deepMerge(originalTheme, bankTheme) : originalTheme;
};

type Props = {
  children: any
};

export const BankThemeProvider = ({ children }: Props) => {
  const page = useSelector(state => state.banks.page.model);

  return (
    <ThemeProvider theme={getTheme(page.theme)}>
      <>{children}</>
    </ThemeProvider>
  );
};
