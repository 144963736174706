// @flow
import React, { Fragment } from 'react';
import { DateLabel } from '@finect/tabular-components/DateLabel';
import { Messages } from '@finect/tabular-icons/messages';
import { AppLink } from '@finect/tabular-core/AppLink';

import { TextEllipsis } from '@finect/tabular-components/TextEllipsis';
import { Flex } from '../components/Flex';
import { ArticleContent, ArticleInfo, SquareImage, PublishDate, QuestionLabel, Small, Answer } from '../partials';

export const AnswerListCard = ({ profileAuthor, answer }: { profileAuthor: Object, answer: Object }) => {
  const title = answer.title;
  const link = answer.link;
  const answerProfile = answer.answers.find(ans => ans.author.id === profileAuthor.id);

  if (!answerProfile) return null;
  const date = answerProfile.updated;
  return (
    <Fragment>
      <ArticleContent>
        <ArticleInfo hasImage={Boolean(true)}>
          <div>
            <Small>
              <QuestionLabel>Pregunta:</QuestionLabel>
              <AppLink href={link}>{title}</AppLink>
            </Small>
            <Answer>
              <QuestionLabel>Respuesta: </QuestionLabel>
              <AppLink href={link}>
                <TextEllipsis lines={3}>{answerProfile.body.text}</TextEllipsis>
              </AppLink>
            </Answer>
            <Flex align="center">
              <PublishDate>
                <DateLabel date={new Date(date)} format="LLL" />
              </PublishDate>
            </Flex>
          </div>
        </ArticleInfo>

        <SquareImage as="a" href={link} size="100px" margin="0 0 12px 15px">
          <Messages size={64} />
        </SquareImage>
      </ArticleContent>
    </Fragment>
  );
};
