// @flow
import styled, { css, keyframes } from 'styled-components';
import { maxMediaQueries, minMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';
import { grid } from '@finect/tabular-utils/grid';
import { hideScrollbar } from '@finect/tabular-utils/hideScrollbar';

const showPop = () => keyframes`
  0% {
    opacity: 0;
    transform: scale(0.4);
  }

  50% {
    transform: scale(1);
  }
`;

export const Box = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0;
`;

const animationStyles = css`
  animation: ${showPop} 0.2s ease-in;
`;

export const Content = styled.div`
  position: relative;
  width: ${grid(7)}px;
  max-width: 100%;
  padding: 46px 36px;
  margin: auto;
  ${({ withAnimation = true }) => withAnimation && animationStyles}

  ${({ fullScreen }) =>
    fullScreen &&
    css`
      height: 100%;
      min-height: 90vh;
    `}
  
  background-color: ${({ theme }) => theme.colors.terciaryLL};

  ${maxMediaQueries.phone`
    border-radius: 0;
    min-height: 100%;
  `};

  ${({ modal = true, theme }) =>
    modal &&
    `
    border-radius: ${theme.borderRadius};
  box-shadow: ${theme.boxShadow};
  `}
`;

export const Overlay = styled.div`
  ${({ modal = true }) =>
    modal &&
    `
      position: fixed;
      z-index: 1000000;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(0 0 0 / 0.8);

      ${hideScrollbar}

      ${Content} {
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        background-color: white;
      }
    `}
`;

const headingStyles = index => css`
  display: inline-block;
  ${getFont(index, 'compact')};
  font-weight: 700;
  ${({ center }) => (center ? 'text-align: center;' : null)};
`;

export const Title = styled.h2`
  margin-bottom: 60px;
  ${headingStyles(3)};

  ${minMediaQueries.phablet`
    ${headingStyles(4)};
  `};

  &:not(:last-child) {
    ${({ noPadding }) => !noPadding && 'padding-bottom: 18px;'};
  }

  &:after {
    display: block;
    position: absolute;
    width: 200px;
    height: 6px;
    border-radius: 4px;
    margin-top: 36px;
    content: '';
    background-color: ${({ theme }) => theme.colors.primary};

    ${minMediaQueries.phablet`
      margin-top: 48px;
    `};
  }
`;

export const Message = styled.span`
  color: ${({ theme }) => theme.colors.uiD};
`;

export const Claim = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-bottom: 36px;

  ${minMediaQueries.phablet`
    margin-right: 30px;
    margin-bottom: 0px;
  `};
`;

export const Options = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  margin-top: 60px;

  > div {
    width: 40%;
    min-height: 300px;
  }

  ${maxMediaQueries.tablet`
    > div {
      width: 45%;
    }
  `};

  ${maxMediaQueries.phablet`
    margin-top: 30px;
    flex-flow: column;
    align-items: center;

    > div {
      width: 100%;
      max-width: 300px;
      
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  `};
`;

export const ActionsFooter = styled.div`
  margin: 30px auto 0;
  font-weight: 600;
  text-align: center;
`;
