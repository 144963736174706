import styled from 'styled-components';

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 9px;

  a {
    margin-left: 9px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

// nav
export const NavInner = styled.div`
  display: flex;
  ${({ bottomMargin = false }) => bottomMargin && 'margin-bottom: 36px;'}

  > div {
    display: flex;
    align-items: right;
    flex-direction: column;
    margin-left: auto;
  }

  p {
    text-align: right;
  }
`;

export const AuthorizationsTitle = styled.span`
  margin-right: 9px;
  font-weight: 500;
  font-variant: small-caps;
  color: ${({ theme }) => theme.colors.fontL};
  text-transform: lowercase;
`;

export const AuthorizationsLink = styled.p`
  margin-right: 9px;
`;
