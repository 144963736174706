// @flow

export function setError(error: { code?: number, message?: string }) {
  return {
    type: 'SET_ERROR',
    error
  };
}

export function discardError() {
  return {
    type: 'DISCARD_ERROR'
  };
}
