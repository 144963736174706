// @flow
import React from 'react';
import { InsideNavigation } from '@finect/tabular-components/InsideNavigation';

type UserProfileInsideNavigationProps = {
  profile: Object,
  articles: Object,
  answers: Object,
  reviews: Object,
  match: Object,
  history: Object
};

export const UserProfileInsideNavigation = ({
  profile,
  articles,
  answers,
  reviews,
  match,
  history
}: UserProfileInsideNavigationProps) => (
  <InsideNavigation
    back={null}
    links={[
      {
        title: 'Perfil',
        path: `/usuario/${profile.model.alias}`
      },
      ...articles.items.length > 0
        ? [
          {
            title: 'Artículos',
            path: `/usuario/${profile.model.alias}/articulos`
          }
        ]
        : [],
      ...answers.items.length > 0
        ? [
          {
            title: `Respuestas (${answers.items.length})`,
            path: `/usuario/${profile.model.alias}/respuestas`,
            rel: 'nofollow'
          }
        ]
        : [],
      ...reviews.items.length > 0
        ? [
          {
            title: `Opiniones (${reviews.items.length})`,
            path: `/usuario/${profile.model.alias}/reviews`,
            rel: 'nofollow'
          }
        ]
        : []
    ]}
    currentPath={match.url}
    withSPA
    position="sticky"
    history={history}
  />
);
