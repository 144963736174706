// @flow
export function cms(
  state: Object = {
    page: {
      model: null,
      fetching: false,
      fetched: false
    }
  },
  action: Object
) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}
