// @flow
import styled, { css } from 'styled-components';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { scrollX } from '@finect/tabular-utils/scrollX';

const size = 72;
const margin = 3;
const factor = 1.62;

/* stylelint-disable */
export const Inner = styled.div`
  padding: 48px 24px;
  border-radius: 12px;
  box-shadow: 0 2px 24px rgb(46 46 51 / 0.08), 0 2px 8px rgb(229 229 229 / 0.8);
  margin-bottom: 72px;
  background-color: ${({ theme }) => theme.colors.primaryLL};
  color: ${({ theme }) => theme.colors.font};
`;
/* stylelint-enable */

export const Logo = styled.img`
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  ${({ hover }) => (hover ? '' : 'filter: grayscale(30%);')};

  ${({ pos, hover }) => css`
    z-index: ${pos};
    margin-left: ${hover ? pos * margin + pos * size : (pos * size) / factor}px;
  `}
`;

export const Logos = styled.div`
  position: relative;
  height: ${size}px;

  img {
    position: absolute;
    width: ${size}px;
    height: ${size}px;
    border: 2px solid ${({ theme }) => theme.colors.uiD};
    border-radius: 50%;
  }

  ${maxMediaQueries.phablet`
    ${scrollX}
  `}
`;

export const LogoLink = styled.a``;
