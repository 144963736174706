// @flow
import React from 'react';
import { Icon } from '@finect/tabular-components/Icon';
import { AppLink } from '@finect/tabular-core/AppLink';
import { finectAPI } from '@finect/front-utils/finectAPI';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openConversation, newMessageToMembers } from '@finect/tabular-conversations/store';
import { Inner } from '../partials';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openConversation,
      newMessageToMembers
    },
    dispatch
  )
});

export const getFeaturedAdvisers = () =>
  finectAPI({
    version: 'v3',
    path: `advisers/people?rank=top`,
    method: 'GET',
    onSuccess: ({ data }) => data.slice(0, 3)
  });

type Props = {
  actions: {
    newMessageToMembers: Function
  }
};

type State = {
  advisers: Array<Object>
};

export class FeaturedAdvisers extends React.Component<Props, State> {
  state = {
    advisers: []
  };

  componentDidMount() {
    getFeaturedAdvisers().then((advisers) => {
      this.setState({ advisers });
    });
  }

  handleProfileConversation = (event: Object, id: string) => {
    event.preventDefault();
    this.props.actions.newMessageToMembers([id]);
  };

  render() {
    const { advisers } = this.state;

    if (advisers.length) {
      return (
        <Inner>
          {advisers.slice(0, 3).map((featuredAdviser, index) => (
            <AppLink
              key={index}
              href={`/mensajes/nuevo?members=${featuredAdviser.id}`}
              rel="nofollow"
              onClick={(event: Object) => {
                this.handleProfileConversation(event, featuredAdviser.id);
              }}
            >
              <Icon size={1} alt={featuredAdviser.displayName} image={featuredAdviser.icon} type="user" />
            </AppLink>
          ))}
        </Inner>
      );
    }

    return null;
  }
}

export default connect(null, mapDispatchToProps)(FeaturedAdvisers);
