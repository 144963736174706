/* eslint-disable no-inline-comments */
// @flow
import React from 'react';
import { AppLink } from '@finect/tabular-core/AppLink';
import { getLoginBaseURL } from '@finect/front-utils/getLoginBaseURL';
import { getBaseURL } from '@finect/front-utils/env';
import { OptionCard } from './components';
import { Overlay, Box, Content, Title, Message, Claim, Options, ActionsFooter } from './partials';

// ---- if user chooses paid option:
// if user is logged in, link to payment page
// if user is not logged in, link to login page with callback to payment page

// ---- if user accepts free option: let him navigate

// ---- if user is not logged in: link to login page with callback to current page

export const SubscribeModalContent = ({ modal = true, user }: { modal?: boolean, user: Object }) => {
  const paymentUrl = `/servicios/finect-subscription/comprar`;

  return (
    <Overlay modal={modal}>
      <Box>
        <Content fullScreen modal={modal}>
          <Claim isLoggedIn={user.isLoggedIn}>
            <Title>Elige tu opción</Title>
            <Message>Gracias por usar la información, análisis y herramientas de Finect para invertir mejor.</Message>
          </Claim>

          <Options>
            <OptionCard
              option="basic"
              url={
                user.isLoggedIn
                  ? `${paymentUrl}?plan=1`
                  : `${getLoginBaseURL()}?next=${getBaseURL() + paymentUrl}?plan=1`
              }
            />
            <OptionCard option="free" url={'/'} />
          </Options>

          {!user.isLoggedIn && (
            <ActionsFooter>
              ¿Ya tienes cuenta con nosotros?
              <br />
              <AppLink href={getLoginBaseURL()}>Inicia sesión</AppLink>
            </ActionsFooter>
          )}
        </Content>
      </Box>
    </Overlay>
  );
};
