// @flow
import {
  BANKS_SET_ORGANIZATION,
  BANKS_SET_ORGANIZATION_FETCHING_STATUS,
  BANKS_SET_ARTICLE,
  BANKS_SET_ARTICLE_FETCHING_STATUS,
  BANKS_SET_QUESTIONS,
  BANKS_SET_QUESTIONS_FETCHING_STATUS,
  BANKS_SET_MANAGEMENT_COMPANIES,
  BANKS_SET_MANAGEMENT_COMPANIES_FETCHING_STATUS,
  BANKS_SET_PAGE,
  BANKS_SET_PAGE_FETCHING_STATUS,
  BANKS_CLEAR
} from '../constants';

import { articleWithSafeHTML } from '../../utils/models';
import type { BanksStoreType } from './types';

const INITIAL_STORE = {
  page: {
    model: null,
    fetching: false,
    fetched: false
  },
  organization: {
    fetching: false,
    fetched: false,
    model: null
  },
  article: {
    fetching: false,
    fetched: false,
    model: null
  },
  questions: {
    fetching: false,
    fetched: false,
    request: null,
    models: []
  },

  // todo: revisar
  products: {
    items: []
  },
  managementCompanies: {
    fetching: false,
    fetched: false,
    request: null,
    models: []
  }
};

export function banks(state: BanksStoreType = INITIAL_STORE, action: Object) {
  switch (action.type) {
    case BANKS_CLEAR: {
      return {
        ...state,
        ...INITIAL_STORE
      };
    }

    case BANKS_SET_PAGE: {
      const newState = { ...state };
      newState.page = {
        model: action.page,
        fetching: false,
        fetched: true
      };

      return { ...state, ...newState };
    }

    case BANKS_SET_PAGE_FETCHING_STATUS: {
      const newState = { ...state };
      newState.page = {
        ...newState.page,
        fetching: action.fetching,
        fetched: action.fetched
      };

      return { ...state, ...newState };
    }

    // Organization
    case BANKS_SET_ORGANIZATION: {
      return {
        ...state,
        organization: {
          fetching: false,
          fetched: true,
          model: action.organization
        }
      };
    }

    case BANKS_SET_ORGANIZATION_FETCHING_STATUS: {
      return {
        ...state,
        organization: {
          fetching: action.fetching,
          fetched: action.fetched,
          model: state.organization.model ? { ...state.organization.model } : null
        }
      };
    }

    // Article
    case BANKS_SET_ARTICLE: {
      return {
        ...state,
        article: {
          fetching: false,
          fetched: true,
          model: articleWithSafeHTML(action.article)
        }
      };
    }

    case BANKS_SET_ARTICLE_FETCHING_STATUS: {
      return {
        ...state,
        article: {
          fetching: action.fetching,
          fetched: action.fetched,
          model: state.article.model ? { ...state.organization.model } : null
        }
      };
    }

    // Questions
    case BANKS_SET_QUESTIONS_FETCHING_STATUS: {
      return {
        ...state,
        questions: {
          ...state.questions,
          fetching: action.fetching,
          fetched: action.fetched
        }
      };
    }

    case BANKS_SET_QUESTIONS: {
      return {
        ...state,
        ...{
          questions: {
            fetching: false,
            fetched: true,
            models: action.questions
          }
        }
      };
    }

    // Management Companies
    case BANKS_SET_MANAGEMENT_COMPANIES_FETCHING_STATUS: {
      return {
        ...state,
        managementCompanies: {
          ...state.managementCompanies,
          ...{
            fetching: action.fetching,
            fetched: action.fetched
          }
        }
      };
    }

    case BANKS_SET_MANAGEMENT_COMPANIES: {
      return {
        ...state,
        managementCompanies: {
          fetching: false,
          fetched: true,
          models: action.managementCompanies
        }
      };
    }

    default:
      return state;
  }
}
