// @flow
import styled from 'styled-components';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';

export const BreadCrumbsContent = styled.div`
  display: flex;
  align-items: center;
`;

export const BreadCrumbSpan = styled.span`
  display: flex;
  margin: 0 4px;
  color: ${({ theme }) => theme.colors.terciary};

  :first-child {
    margin-left: 0;
  }

  :last-child {
    ${maxMediaQueries.phablet`
      display: none;
    `};
  }

  ${({ wrap = true }) => !wrap && `white-space: nowrap;`}
`;

export const BreadCrumbLink = styled.a`
  display: flex;
  margin: 0 4px;
  color: ${({ theme }) => theme.colors.terciary};
  text-decoration: underline;

  ${({ wrap = true }) => !wrap && `white-space: nowrap;`}
`;
