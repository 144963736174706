// @flow
import React from 'react';
import { Icon } from '@finect/tabular-components/Icon';
import { AppLink } from '@finect/tabular-core/AppLink';
import { isUserAdviser } from '@finect/front-core/Profiles';
import { Link } from 'react-router-dom';
import { Inner } from './partials';
import { FeaturedAdvisers } from './FeaturedAdvisers';

type Props = {
  user: Object,
  authorizationsProps: Object
};

const AuthorizationsAdvisers = ({ authorizationsProps, user }: Props) => {
  const {
    authorizations
    // requests
  } = authorizationsProps.userACL;

  const isAdviser = isUserAdviser(user);

  if (isAdviser) {
    return <FeaturedAdvisers />;
  }

  const companiesAuthorizations = authorizations.filter(auth => auth.profile.subtype === 'company');
  const companiesIds = companiesAuthorizations.map(auth => auth.profile.id);

  const usersAuthorizations = authorizations
    .filter(auth => auth.profile.subtype === 'user')
    .filter(auth => !auth.profile.organizations.find(({ id }) => companiesIds.includes(id)));

  const displayAuthorizations = [...companiesAuthorizations, ...usersAuthorizations];

  if (!displayAuthorizations.length) {
    return <FeaturedAdvisers />;
  }

  return (
    <Inner>
      {displayAuthorizations.slice(0, 3).map((authorization, key) => {
        const { profile } = authorization;

        return (
          <AppLink key={key} href={`/usuario/${profile.id}`}>
            <Icon size={1} alt={profile.displayName} image={`/image/${profile.icon}`} type={profile.subtype} verified />
          </AppLink>
        );
      })}

      <AppLink as={Link} to="/tus-autorizaciones" title="Ver todas tus autorizaciones">
        Ver todos
      </AppLink>
    </Inner>
  );
};

export default AuthorizationsAdvisers;
