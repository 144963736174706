// @flow
import React from 'react';
import { OptionsInner, Option } from '../partials';
import type { OptionsType } from './data';
import { options } from './data';

type Props = {
  option: OptionsType,
  url?: string
};

export const OptionCard = ({ option, url }: Props) => {
  const optionData = options[option];

  return (
    <OptionsInner>
      <h4>{optionData.title}</h4>

      <span>{optionData.highlight}</span>

      <div>
        {optionData.price}{optionData.period ? <span> / {optionData.period}</span> : ''}
      </div>

      <Option primary as="a" href={url} rel="nofollow">
        {optionData.button}
      </Option>
    </OptionsInner>
  );
};
