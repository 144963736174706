// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Section } from '@finect/tabular-components/Section';
import { getProductTypeById, getProductTypeLabel } from '@finect/front-resources/Products';
import { getManagementCompanyPath } from '../../../utils/paths';
import { Logos, Logo, Inner, LogoLink } from './partials';

type Props = {
  managementCompanies: Object,
  organization: Object,
  size: string
};

type State = {
  hover: boolean
};

export class BankManagers extends React.Component<Props, State> {
  state = {
    hover: false
  };

  static defaultProps = {
    size: 'small'
  };

  handleMouseEnter = () => {
    this.setState({ hover: true });
  };

  handleMouseLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    const { managementCompanies, organization, size } = this.props;

    const withOrganization = managementCompanies.models.filter(item => Boolean(item.organization));
    if (!withOrganization.length) return null;

    return (
      <Inner>
        <Section
          size={size}
          title={`Con ${organization.displayName} también puedes invertir en los mejores fondos internacionales`}
          subtitle={`El banco ofrece una selección de productos de las principales gestoras mundiales`}
        >
          <Logos onMouseLeave={this.handleMouseLeave} onMouseEnter={this.handleMouseEnter}>
            {withOrganization
              .filter(item => Boolean(item.organization))
              .map((managementCompany, index) => {
                const [firstProductTypeId = 'fund'] = managementCompany.stats.types.split(',');
                const productType = getProductTypeById(firstProductTypeId);
                return (
                  <LogoLink
                    key={index}
                    as={Link}
                    title={`${getProductTypeLabel(productType, 'plural')} gestionados por ${managementCompany.name}`}
                    to={getManagementCompanyPath(productType, managementCompany)}
                  >
                    <Logo
                      key={index}
                      pos={index}
                      src={`https://www.finect.com/image/${managementCompany.organization.icon}`}
                      hover={this.state.hover}
                    />
                  </LogoLink>
                );
              })}
          </Logos>
        </Section>
      </Inner>
    );
  }
}
