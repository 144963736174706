// @flow
import { getLoginBaseURL } from '@finect/front-utils/getLoginBaseURL';
// https://github.com/Finect/front-login/wiki/Actions#like

const defaultCopy = 'Para realizar esta acción accede a tu cuenta de Finect';

type EntityType = 'product' | 'article' | 'profile';
type ActionType = 'subscribe' | 'like';
type ActionNext = string;

const loginBaseURL = getLoginBaseURL();

const copies = {
  subscribe: {
    product: 'Para suscribirte a un producto accede a tu cuenta de Finect',
    profile: defaultCopy,
    article: 'Para suscribirte a un artículo accede a tu cuenta de Finect'
  },
  comment: {
    article: 'Para comentar un artículo accede a tu cuenta de Finect'
  },
  like: {
    product: defaultCopy,
    profile: defaultCopy,
    article: defaultCopy
  }
};

/**
 * Devuelve una acción como base64 compatible con login
 *
 * @param {EntityType} entityType Entidad
 * @param {ActionType} actionType Tipo de acción
 * @param {any} id Identificador de la entidad
 */
export const getAction = (entityType: EntityType, actionType: ActionType, id: any, next?: ?ActionNext) => {
  const blob = {
    copy: copies[actionType] && copies[actionType][entityType] ? copies[actionType][entityType] : defaultCopy,
    action: actionType,
    target: {
      type: entityType,
      id
    }
  };

  return next ? { ...blob, ...{ next } } : blob;
};

/**
 * Recibe una acción y la devuelve como Base64
 *
 * @param {Object} action Objeto de la acción
 */
export const getBlobAction = (action: Object) => Buffer.from(JSON.stringify(action)).toString('base64');

export const getLoginURL = (entityType: EntityType, actionType: ActionType, id: any, next?: ?ActionNext) => {
  const defaultNext = window ? window.location.href : null;
  const action = getAction(entityType, actionType, id, next || defaultNext);

  return `${loginBaseURL}/login?action=${getBlobAction(action)}`;
};

/**
 * Recibe una acción de login y redirecciona al usuario a login.finect.com para completar la acción
 *
 * @param {EntityType} entityType Entidad
 * @param {ActionType} actionType Tipo de acción
 * @param {any} id Identificador de la entidad
 */
export const loginAction = (entityType: EntityType, actionType: ActionType, id: any, next?: ActionNext) => {
  window.location = getLoginURL(entityType, actionType, id, next || null);
};

export const goToLoginWithAction = (action: Object) => {
  const url = `${loginBaseURL}/login?action=${getBlobAction(action)}`;

  window.location = url;
};
