// @flow
import React from 'react';
import { FundTimeseriesChart } from '@finect/tabular-components/Modules';
import { getProductTypeByAPISubtype, getFundClassName, getProductPath } from '@finect/front-resources/Products';
import { Paragraph } from '@finect/tabular-core/Paragraph';

type Props = {
  contentPiece: Object,
  layout: 'main' | 'side'
};

export const Product = ({ contentPiece, layout }: Props) => {
  if (!contentPiece.model) return null;

  const subtype = contentPiece.model.subtype;
  const productType = getProductTypeByAPISubtype(subtype);

  if (!productType) {
    return <Paragraph>La familia de producto no es válida.</Paragraph>;
  }

  if (!['iic'].includes(productType.category)) {
    return (
      <Paragraph>{`La familia de producto ${productType.singular} no es compatible con este componente.`}</Paragraph>
    );
  }

  const fund = {
    ...contentPiece.model.entity,
    subtype
  };

  return <FundTimeseriesChart fund={fund} bottomMargin isCompact={layout === 'side'} />;
};

Product.getContentPieceTitle = (piece: Object): string => {
  const { model } = piece.contentPiece;

  if (model) {
    return getFundClassName(model.entity);
  }

  return '';
};

Product.getContentPieceTitleLink = (piece: Object): string | null => {
  const { model } = piece.contentPiece;

  if (model) {
    return getProductPath({ subtype: model.subtype, entity: model.entity });
  }

  return null;
};
