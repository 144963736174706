// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from '@finect/tabular-icons/arrowRight';
import { isPreRelease } from '@finect/front-utils/isPreRelease';
import { getImageUrl } from '@finect/tabular-components/Image';
import { DateLabel } from '@finect/tabular-components/DateLabel';
import { getArticlePath } from '../../../utils/articles';
import { Inner, Title, MainBlock, Image, ViewMoreAction, DateBlock } from './partials';

export const BankSmallArticle = ({ article }: { article: Object }) => {
  const { title, image } = article;

  const path = getArticlePath(article);
  const date = article.revision.updated || article.revision.created;

  return (
    <Inner>
      <Image imageSrc={getImageUrl(image, { width: 88, height: 88 })} />

      <MainBlock>
        <Title to={path} as={Link}>
          {title}
        </Title>

        {isPreRelease && (
          <DateBlock>
            <span style={{ color: 'red' }}>BETA</span> actualizado{' '}
            <DateLabel date={new Date(date)} format="timeSince" />
          </DateBlock>
        )}

        <ViewMoreAction to={path} as={Link}>
          Leer análisis
          <ArrowRight size={'16'} />
        </ViewMoreAction>
      </MainBlock>
    </Inner>
  );
};
