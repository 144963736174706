// @flow
import React from 'react';
import { Icon } from '@finect/tabular-components/Icon';
import { getAuthorBadges, isUserAdviser } from '@finect/front-core/Profiles';
import { AdviserBadge } from '@finect/tabular-components/AdviserBadge';
import { Follow } from '@finect/tabular-components/Follow';
import { Settings } from '@finect/tabular-icons/settings';
import { AppLink } from '@finect/tabular-core/AppLink';
import { Edit2 } from '@finect/tabular-icons/edit-2';
import { Mail } from '@finect/tabular-icons/mail';
import { connect } from 'react-redux';
import { OrganizationBadge } from '@finect/tabular-components/Advisers';
import { getBaseURL } from '@finect/front-utils/env';

import {
  Inner,
  IconBlock,
  InfoBlock,
  NameBlock,
  Name,
  HeadlineBlock,
  ProfileExtra,
  ProfileActions,
  BadgesContent,
  ConfigButton,
  WriteButton,
  MessageButton
} from './partials';

const mapStateToProps = state => ({
  onlineMembers: state.conversations.onlineMembers
});

export const UserProfileHeading = ({
  profile,
  user,
  subscriptionsProfiles,
  onFollow,
  onlineMembers
}: {
  profile: Object,
  user: Object,
  subscriptionsProfiles: Array<string>,
  onFollow: Function,
  onlineMembers: Array<string>
}) => {
  const badges = isUserAdviser(profile) ? getAuthorBadges(profile) : [];

  const handleFollowAction = () => {
    onFollow('profile', 'subscribe', profile.id, !subscriptionsProfiles.includes(profile.id), profile);
  };

  const { organizations = [] } = profile;

  return (
    <Inner>
      <IconBlock>
        <Icon
          isOnline={onlineMembers.includes(profile.id)}
          size={7}
          alt={profile.displayName}
          image={profile.icon ? `/image/${profile.icon}` : null}
          margin="0 18px 0 0"
          verified={false}
        />
      </IconBlock>

      <InfoBlock>
        <div>
          <NameBlock>
            <Name large>{profile.displayName}</Name>
            {badges.map((badge, index) => (
              <AdviserBadge key={index} title={badge} />
            ))}
          </NameBlock>

          <HeadlineBlock>{profile.tagline}</HeadlineBlock>

          <BadgesContent>
            {organizations.map(org => (
              <OrganizationBadge type="organization" organization={org} key={org.id} large />
            ))}

            {profile.certifications.map(cert => (
              <OrganizationBadge
                key={cert.id}
                type="certificate"
                organization={{
                  ...cert,
                  displayName: `${cert.title.startsWith('Certificado') ? '' : 'Certificado '}${cert.title}`
                }}
                large
              />
            ))}
          </BadgesContent>
        </div>

        <ProfileExtra>
          <div></div>
          <ProfileActions>
            {user.isLoggedIn && user.id === profile.id ? (
              <>
                <WriteButton as="a" href={`/usuario/${profile.alias}/articulos/nuevo`} secondary variant="medium">
                  <Edit2 size="20" />
                  {'Escribir artículo'}
                </WriteButton>

                <ConfigButton
                  as="a"
                  href="/usuario/configuracion"
                  title="Ir a configuración de mi perfil de usuario"
                  variant="medium"
                >
                  <Settings size="20" strokeWidth="2" />
                </ConfigButton>
              </>
            ) : (
              <>
                <MessageButton
                  variant="medium"
                  as={AppLink}
                  secondary
                  rel="nofollow"
                  href={`${getBaseURL()}/mensajes/nuevo?members=${profile.id}`}
                >
                  <Mail size="20" />
                  {'Enviar mensaje'}
                </MessageButton>

                <Follow follow={handleFollowAction} following={subscriptionsProfiles.includes(profile.id)} />
              </>
            )}
          </ProfileActions>
        </ProfileExtra>
      </InfoBlock>
    </Inner>
  );
};

export default connect(mapStateToProps, null)(UserProfileHeading);
