// @flow
import { articleWithSafeHTML } from '../../utils/models';

export function terms(
  state: Object = {
    terms: {}
  },
  action: Object
) {
  switch (action.type) {
    // Legal
    case 'SET_TERMS': {
      return {
        ...state,
        ...{
          terms: articleWithSafeHTML(action.terms)
        }
      };
    }
    default:
      return state;
  }
}
