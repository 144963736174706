// @flow
import React from 'react';
import { Button } from '@finect/tabular-core/Button';
import { Strong } from '@finect/tabular-core/Strong';
import { HelpCircle } from '@finect/tabular-icons/helpCircle';
import { Inner, CTABlock, Content } from './partials';

export const BankQuestionsCTA = ({
  organization,
  bottomMargin = false
}: {
  organization: Object,
  bottomMargin: boolean
}) => {
  const { displayName } = organization || {};

  return (
    <Inner bottomMargin={bottomMargin}>
      <Content>
        <div>
          <HelpCircle strokeWidth="1px" />
        </div>

        <div>
          <h3>Resuelve tus dudas</h3>
          <p>
            ¿Tienes alguna pregunta sobre <Strong>{displayName}</Strong>? Escribe tu pregunta y recibe ayuda de expertos
            y asesores financieros de nuestra comunidad
          </p>
        </div>
      </Content>

      <CTABlock>
        <Button
          as="a"
          width="auto"
          secondary
          variant="medium"
          href={`https://www.finect.com/preguntas/nueva-pregunta?group=${organization.id}`}
          rel="nofollow"
        >
          Pregunta en Finect
        </Button>
      </CTABlock>
    </Inner>
  );
};
