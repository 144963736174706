// @flow
import styled, { css } from 'styled-components';

const commonStyles = css`
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: ${({ size }) => size || '84px'};
  height: ${({ size }) => size || '84px'};
  border-radius: 2px;
  margin: ${({ margin }) => margin || '0 0 0 15px'};
`;

export const SquareImage = styled.div`
  ${commonStyles};

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SquareIcon = styled.div`
  ${commonStyles};
  background-color: ${({ theme }) => theme.colors.uiLL};
  color: ${({ theme }) => theme.colors.primary};

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
