// @flow
import React from 'react';
import { Jumbo } from '@finect/tabular-components/Jumbo';
import { Section } from '@finect/tabular-components/Section';

export const EVOJumbo = () => (
  <Section bottomMargin>
    <Jumbo
      id="evobanco-jumbo"
      contentPosition="left"
      textAlign="left"
      color="#f4137b"
      logoJumbo="https://www.finect.com/image/bd50364f18e035c3c8b93cac37da8821de167710c11"
      cta="Comenzar ahora"
      coverImage="https://www.finect.com/image/4d0e8c2db3ed1272d402ebae13aa9ab9ec41dc1b145"
      link="https://www.evobanco.com/productos-inteligentes/inversion-rentable/fondo-inteligente/"
    />
  </Section>
);
