// @flow
// Se usa en las listas de escaparates y de bancos
export const fieldsByProductType = [
  // Planes por defecto
  {
    productType: 'plan',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'finectRating', textAlign: 'left' },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'srri' },
      { id: 'performance', period: 'M12', type: 'accumulated' },
      { id: 'performance', period: 'M36', type: 'annualized' },
      { id: 'maxDrawdown', period: 'M36' },
      { id: 'feature', feature: 'value-for-money' },
      { id: 'fee', fee: 'MGR' },
      { id: 'likeAction' }
    ]
  },
  // Fondos por defecto
  {
    productType: 'fund',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'finectRating', textAlign: 'left' },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'srri' },
      { id: 'performance', period: 'M12', type: 'accumulated' },
      { id: 'performance', period: 'M36', type: 'annualized' },
      { id: 'maxDrawdown', period: 'M36' },
      { id: 'feature', feature: 'value-for-money' },
      { id: 'fee', fee: 'OGC' },
      // { id: 'buyButton', variant: 'textButton', withLabel: false, topMargin: true },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'etf',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'commission', textAlign: 'left' },
      { id: 'performance', period: 'M36', type: 'annualized' },
      { id: 'minInvestment', model: 'content', textAlign: 'left' },
      { id: 'feature', feature: 'value-for-money' },
      { id: 'feature', feature: 'cost' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'sicav',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'commission', textAlign: 'left' },
      { id: 'performance', period: 'M36', type: 'annualized' },
      { id: 'minInvestment', model: 'content', textAlign: 'left' },
      { id: 'feature', feature: 'value-for-money' },
      { id: 'feature', feature: 'cost' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'stock',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'commission', textAlign: 'left' },
      { id: 'performance', period: 'M36', type: 'annualized' },
      { id: 'minInvestment', model: 'content', textAlign: 'left' },
      { id: 'feature', feature: 'cost' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'managedaccount',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'commission', textAlign: 'left' },
      // { id: 'metadata', metadata: 'roboadvisor', textAlign: 'left' },
      { id: 'minInvestment', model: 'content', textAlign: 'left' },
      { id: 'feature', feature: 'cost' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'roboadvisor',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'commission', textAlign: 'left' },
      { id: 'minInvestment', model: 'content', textAlign: 'left' },
      { id: 'metadata', metadata: 'minimum_subscription_amount', textAlign: 'left' },
      { id: 'feature', feature: 'cost' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'deposit',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'apr' },
      { id: 'minInvestment' },
      { id: 'termMonthsCount' },
      { id: 'originCountry' },
      // { id: 'buyButton', variant: 'textButton' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'overnight-money',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'apr' },
      { id: 'minInvestment' },
      { id: 'termMonthsCount' },
      { id: 'originCountry' },
      // { id: 'buyButton', variant: 'textButton' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'savingsaccount',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'minInvestment', model: 'content', textAlign: 'left' },
      { id: 'metadata', metadata: 'term', textAlign: 'left' },
      { id: 'metadata', metadata: 'apr' },
      { id: 'likeAction' }
    ]
  },

  // Seguros de ahorro
  {
    productType: 'pias',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'minInvestment', model: 'content', textAlign: 'left' },
      { id: 'commission', textAlign: 'left' },
      { id: 'metadata', metadata: 'minimum_subscription_amount', textAlign: 'left' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'unitlink',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'commission', textAlign: 'left' },
      { id: 'minInvestment', model: 'content', textAlign: 'left' },
      { id: 'feature', feature: 'cost' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'sialp',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'commission', textAlign: 'left' },
      { id: 'minInvestment', model: 'content', textAlign: 'left' },
      { id: 'feature', feature: 'cost' },
      { id: 'likeAction' }
    ]
  },
  // --> fin seguros de ahorro
  {
    productType: 'service',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'commission', textAlign: 'left' },
      { id: 'minInvestment', model: 'content', textAlign: 'left' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'course',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'metadata', metadata: 'taught_by' },
      { id: 'cost' },
      { id: 'metadata', metadata: 'level' },
      // { id: 'buyButton', variant: 'textButton' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'book',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'distributors', hidden: true },
      { id: 'metadata', metadata: 'author', textAlign: 'left' },
      { id: 'cost' },
      { id: 'likeAction' }
    ]
  },

  {
    productType: 'card',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'metadata', metadata: 'card_type', textAlign: 'left' },
      { id: 'metadata', metadata: 'card_company', textAlign: 'left' },
      { id: 'metadata', metadata: 'emission_comission' },
      { id: 'likeAction' }
    ]
  },

  // Cripto
  {
    productType: 'crypto',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'metadata', metadata: 'market' },
      { id: 'metadata', metadata: 'product' },
      { id: 'metadata', metadata: 'ranking' },
      { id: 'metadata', metadata: 'performance_2021' },
      { id: 'metadata', metadata: 'performance_2020' },
      { id: 'metadata', metadata: 'buy_in' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'nft',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'likeAction' },
      { id: 'metadata', metadata: 'platform' },
      { id: 'metadata', metadata: 'price' },
      { id: 'metadata', metadata: 'artist' }
    ]
  },
  {
    productType: 'token',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'metadata', metadata: 'expected_return' },
      { id: 'metadata', metadata: 'liquidity' },
      { id: 'metadata', metadata: 'sector' },
      { id: 'likeAction' }
    ]
  },
  {
    productType: 'wallet',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'metadata', metadata: 'complexity' },
      { id: 'metadata', metadata: 'format' },
      { id: 'metadata', metadata: 'protection' },
      { id: 'likeAction' }
    ]
  },

  {
    productType: 'crowdfunding',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'metadata', metadata: 'type' },
      { id: 'metadata', metadata: 'liquidity' },
      { id: 'metadata', metadata: 'expected_return' },
      { id: 'metadata', metadata: 'country' },
      { id: 'minInvestment', model: 'content' },
      { id: 'likeAction' }
    ]
  },
  // ISR
  {
    productType: 'fund',
    tag: '6292',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'finectRating', textAlign: 'left' },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'performance', period: 'M12', type: 'accumulated' },
      { id: 'performance', period: 'M36', type: 'annualized' },
      { id: 'maxDrawdown', period: 'M36' },
      { id: 'feature', feature: 'value-for-money' },
      { id: 'fee', fee: 'OGC' },
      { id: 'ESGRating', display: 'flex' },
      // { id: 'buyButton', variant: 'textButton', withLabel: false, topMargin: true },
      { id: 'likeAction' }
    ]
  },
  // Megatendencias
  {
    productType: 'fund',
    tag: '12051',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'finectRating', textAlign: 'left' },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'performance', period: 'M12', type: 'accumulated' },
      { id: 'performance', period: 'M36', type: 'annualized' },
      { id: 'metadata', metadata: 'topic' },
      { id: 'maxDrawdown', period: 'M36' },
      { id: 'feature', feature: 'value-for-money' },
      { id: 'fee', fee: 'OGC' },
      // { id: 'buyButton', variant: 'textButton', withLabel: false, topMargin: true },
      { id: 'likeAction' }
    ]
  },
  // Indexados
  {
    productType: 'fund',
    tag: '9476',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'finectRating', textAlign: 'left' },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'metadata', metadata: 'index' },
      // { id: 'metadata', metadata: 'region' },
      { id: 'performance', period: 'M12', type: 'accumulated' },
      { id: 'performance', period: 'M36', type: 'annualized' },
      { id: 'trackingError', period: 'M36' },
      { id: 'maxDrawdown', period: 'M36' },
      { id: 'feature', feature: 'value-for-money' },
      { id: 'fee', fee: 'OGC', textAlign: 'right' },
      // { id: 'buyButton', variant: 'textButton', withLabel: false, topMargin: true },
      { id: 'likeAction' }
    ]
  },
  // Emergentes
  {
    productType: 'fund',
    tag: '4695',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'finectRating', textAlign: 'left' },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'performance', period: 'M12', type: 'accumulated' },
      { id: 'performance', period: 'M36', type: 'annualized' },
      { id: 'maxDrawdown', period: 'M36' },
      { id: 'category', variant: 'link' },
      { id: 'feature', feature: 'value-for-money' },
      { id: 'fee', fee: 'OGC', textAlign: 'right' },
      // { id: 'buyButton', variant: 'textButton', withLabel: false, topMargin: true },
      { id: 'likeAction' }
    ]
  },
  // Inmobiliario
  {
    productType: 'fund',
    tag: '188',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'finectRating', textAlign: 'left' },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'category', variant: 'link' },
      { id: 'performance', period: 'M12', type: 'accumulated' },
      { id: 'performance', period: 'M36', type: 'annualized' },
      { id: 'performance', period: 'M60', type: 'annualized' },
      { id: 'maxDrawdown', period: 'M36' },
      { id: 'fee', fee: 'OGC', textAlign: 'right' },
      // { id: 'buyButton', variant: 'textButton', withLabel: false, topMargin: true },
      { id: 'likeAction' }
    ]
  },
  // Infraestructuras
  {
    productType: 'fund',
    tag: '850',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'finectRating', textAlign: 'left' },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'performance', period: 'M12', type: 'accumulated' },
      { id: 'performance', period: 'M36', type: 'annualized' },
      { id: 'maxDrawdown', period: 'M36' },
      { id: 'feature', feature: 'value-for-money' },
      { id: 'fee', fee: 'OGC', textAlign: 'right' },
      // { id: 'buyButton', variant: 'textButton', withLabel: false, topMargin: true },
      { id: 'likeAction' }
    ]
  },
  // criptomonedas
  {
    productType: 'fund',
    tag: '32925',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'finectRating', textAlign: 'left' },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'minInvestment', model: 'content', textAlign: 'left' },
      { id: 'yearlyPerformance', year: '2020' },
      { id: 'yearlyPerformance', year: '2021' },
      { id: 'maxDrawdown', period: 'M12' },
      { id: 'fee', fee: 'OGC', textAlign: 'right' },
      // { id: 'buyButton', variant: 'textButton', withLabel: false, topMargin: true },
      { id: 'likeAction' }
    ]
  },
  // tecnología
  {
    productType: 'fund',
    tag: '18',
    fields: [
      { id: 'name', textAlign: 'left', withManagerIcon: true, withSPA: false },
      { id: 'finectRating', textAlign: 'left' },
      { id: 'distributors', textAlign: 'left', hidden: true },
      { id: 'performance', period: 'M12', type: 'accumulated' },
      { id: 'performance', period: 'M36', type: 'annualized' },
      { id: 'maxDrawdown', period: 'M36' },
      { id: 'metadata', metadata: 'topic' },
      { id: 'feature', feature: 'value-for-money' },
      { id: 'fee', fee: 'OGC' },
      // { id: 'buyButton', variant: 'textButton', withLabel: false, topMargin: true },
      { id: 'likeAction' }
    ]
  }
];
