// @flow
import React from 'react';
import { AppLink } from '@finect/tabular-core/AppLink';
import { Image as TabularImage } from '@finect/tabular-components/Image';
import { WrapperHTMLPieces } from './../../partials';

type Props = {
  content: Object
};

/**
 * Muestra una imagen
 */
export const Image = ({ content }: Props) => {
  const withLink = Boolean(content.url.destination);
  const isLinkToFinect = ['https://www.finect.com', 'http://www.finect.com', '/'].some(
    test => content.url.destination && content.url.destination.startsWith(test)
  );

  // TODO: Se está usando AppLink para elementos que no son enlaces
  return (
    <WrapperHTMLPieces>
      <AppLink
        {...(withLink
          ? { href: content.url.destination, rel: !isLinkToFinect ? 'nofollow noopener noreferer' : null }
          : {})}
      >
        <TabularImage src={content.url.source} />
      </AppLink>
    </WrapperHTMLPieces>
  );
};
