// @flow
// import { routes, regex, basePath, slugs, inputs } from './iic';
import { contentsRoutes } from './contents';
import { routes as productsRoutes } from './products';

const homePath = '/';

export { homePath };

export const contents = {
  routes: contentsRoutes
};

export const products = {
  routes: productsRoutes
};

export { BANKS_TAG_ID, BANKS_HIGHLIGHTS_TAG_ID } from './banks';
