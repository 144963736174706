// @flow
import React from 'react';
import { withSubscriptions } from '@finect/front-core/User';
import type { WithSubscriptionsProps } from '@finect/front-core/User';
import { finectAPI } from '@finect/front-utils/finectAPI';
import { getUrlParameter } from '@finect/front-utils/getUrlParameter';
import { base64Encode } from '@finect/front-utils/base64';
import { LoadMore } from '@finect/tabular-components/Loading';
import { ArrowDown } from '@finect/tabular-icons/arrowDown';
import { ContentListCard } from '../../../../../../components/ContentListCard';
import { models } from './../../../../../../utils/models';

type Props = {
  contentPiece: Object,
  likes: Object,
  type: string,
  group: Object,
  param: Object
} & WithSubscriptionsProps;

type State = {
  more: Object,
  loading: boolean
};

export class NewsFeed extends React.Component<Props, State> {
  state = {
    more: {
      articles: [],
      paging: null
    },
    loading: false
  };

  toggleViewMore = () => {
    const { more } = this.state;
    const { param, contentPiece } = this.props;

    const next =
      more.paging?.links.find(link => link.rel === 'next') ||
      contentPiece.model.paging?.links.find(link => link.rel === 'next') ||
      null;

    const offset = next ? getUrlParameter('offset', next.href) : null;
    const limit = next ? getUrlParameter('limit', next.href) : null;

    this.setState({ loading: true });

    finectAPI({
      version: 'v4',
      method: 'GET',
      path: `contents/articles?filter=blog+eq+'${param.id}'${offset ? `&offset=${offset}` : ''}${
        limit ? `&limit=${limit}` : ''
      }`,
      onSuccess: ({ data, paging }: Object) =>
        this.setState({
          more: {
            articles: [
              ...more.articles,
              ...models.articles(
                data.articles.map(article => ({
                  ...article,
                  body: {
                    ...article.body,
                    text: base64Encode(article.body.text || '')
                  }
                })),
                data.authors
              )
            ],
            paging
          },
          loading: false
        }),
      onError: () => {
        // eslint-disable-next-line no-console
        console.log('error');
      }
    });
  };

  render() {
    const { contentPiece, subscriptionsActions, subscriptionsProps, likes, group, param } = this.props;
    const { more, loading } = this.state;

    if (!contentPiece.model || !contentPiece.model.articles.length) return null;

    const contents =
      param.alt && !more.articles.length
        ? contentPiece.model.articles.slice(0, param.alt)
        : contentPiece.model.articles;

    const articles = [...contents, ...more.articles];

    const isViewMore =
      articles.length !== contentPiece.model.paging?.totalItems &&
      (more.paging?.links.find(link => link.rel === 'next') ||
        contentPiece.model.paging?.links.find(link => link.rel === 'next'));

    return (
      <>
        {articles.map((article, index) => (
          <ContentListCard
            key={index}
            type="article"
            entity={article}
            userSubscriptions={subscriptionsProps.subscriptions}
            userLikes={likes}
            onSubscribe={subscriptionsActions.onAction}
            withAuthor={false}
            profileAuthor={group}
          />
        ))}

        {isViewMore && (
          <LoadMore onClick={() => this.toggleViewMore()} loading={loading}>
            Ver más
            <ArrowDown />
          </LoadMore>
        )}
      </>
    );
  }
}

export default withSubscriptions(NewsFeed, ['articles', 'questions']);
