// @flow
import React from 'react';
import { DateLabel } from '@finect/tabular-components/DateLabel';
import { Link } from 'react-router-dom';
import {
  getProductName,
  getProductPath,
  getProductReviewPath,
  getProductTypeById
} from '@finect/front-resources/Products';

import { AppLink } from '@finect/tabular-core/AppLink';
import { Rating } from '@finect/tabular-components/Rating';
import { EntityCard } from '@finect/tabular-components/EntityCard';
import { Icon } from '@finect/tabular-components/Icon';
import { Flex } from '../components/Flex';
import { Title6 } from '../components/Headings';
import { Toolbar } from '../components/Toolbar';
import { Author, ArticleContent, ArticleInfo, Small } from '../partials';

export const ReviewListCard = ({
  review,
  onSubscribe,
  userLikes,
  withAuthor = true
}: {
  review: Object,
  onSubscribe: Function,
  userLikes: Object,
  withAuthor?: boolean
}) => {
  const { id, title } = review;

  const likedReviews = userLikes.articles;

  const liked = likedReviews && likedReviews.includes(id);

  const date = review.revision.updated;
  const link = getProductReviewPath({ subtype: review.product.subtype, entity: { ...review.product } }, review);

  const author =
    review.author && withAuthor
      ? {
        type: review.author.subtype === 'company' ? 'company' : 'user',
        displayName: review.author.displayName,
        icon: review.author.icon,
        url:
            review.author.subtype === 'company' ? `/grupos/${review.author.alias}` : `/usuario/${review.author.alias}`
      }
      : null;

  const comments = review.comments.length;
  const likes = review.socialStats.likes;

  return (
    <ArticleContent>
      <ArticleInfo>
        <div>
          <EntityCard type="product" entity={review.product}>
            <Small withLeftMargin={false}>
              Opinión sobre{' '}
              <AppLink
                as={Link}
                to={getProductPath({
                  subtype: review.product.subtype,
                  entity: { ...review.product }
                })}
              >
                {getProductName(review.product, getProductTypeById(review.product.subtype))}
              </AppLink>
            </Small>
          </EntityCard>
          <Title6 as="h3">
            <a href={link}>{title}</a>
          </Title6>
          <Flex>
            {author && withAuthor && (
              <Author>
                <a href={author.url}>
                  <Icon
                    withBorder
                    size={0}
                    alt={author.displayName}
                    image={`/image/${author.icon}`}
                    margin="0 8px 0 0"
                  />
                </a>
                <div>
                  <a href={author.url}>{author.displayName}</a>
                </div>{' '}
              </Author>
            )}
            <div>
              <Rating size="small" value={review.score} isSelectable={false} starSize="16px" type="infoStar" />
            </div>
            <Small>
              <DateLabel date={new Date(date)} format="LLL" />
            </Small>
          </Flex>
        </div>
        <Toolbar
          inline={true}
          type={'review'}
          title={title}
          url={link}
          liked={liked}
          likes={likes}
          postId={id}
          onLike={reviewId => onSubscribe('review', 'like', reviewId, !liked, review)}
          comments={comments}
        />
      </ArticleInfo>
    </ArticleContent>
  );
};
