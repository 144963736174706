// @flow
import type { Dispatch } from 'redux';
import { finectAPI } from '@finect/front-utils/finectAPI';
import { isNumber as isNumberUtil } from '@finect/front-utils/isNumber';
import { getUrlParameter } from '@finect/front-utils/getUrlParameter';
import { getMultimedia } from '../content/actions';
import { getHighlights } from '../home/highlights';
import { setError } from './../error/actions';
import { models } from './../../utils/models';

function setArticles(contents: Array<Object>, paging: Object) {
  return {
    type: 'SET_ARTICLES',
    contents,
    paging
  };
}
function setArticlesFetchingStatus(fetching: boolean) {
  return {
    type: 'SET_ARTICLES_FETCHING_STATUS',
    fetching
  };
}

function setTagNextArticles(contents: Array<Object>, paging: Object) {
  return {
    type: 'SET_TAG_NEXT_ARTICLES',
    contents,
    paging
  };
}
function setTagNextArticlesFetchingStatus(fetching: boolean) {
  return {
    type: 'SET_TAG_NEXT_ARTICLES_FETCHING_STATUS',
    fetching
  };
}
export function clearTagNextArticles() {
  return {
    type: 'CLEAR_TAG_NEXT_ARTICLES'
  };
}

function setArticlesFeed(contents: Array<Object>, paging: Object) {
  return {
    type: 'SET_ARTICLES_FEED',
    contents,
    paging
  };
}
function setArticlesFeedFetchingStatus(fetching: boolean, fetched: boolean = false) {
  return {
    type: 'SET_ARTICLES_FEED_FETCHING_STATUS',
    fetching,
    fetched
  };
}
export function clearArticlesFeed() {
  return {
    type: 'CLEAR_ARTICLES_FEED'
  };
}

function getArticles(tag: number = 34487, limit?: number) {
  return function (dispatch: Dispatch) {
    dispatch(setArticlesFetchingStatus(true));
    return finectAPI({
      version: 'v4',
      path: `contents/tags/${tag}/organizations/articles?limit=${limit || 10}&orderby=-revision/updated`,
      method: 'GET',
      onSuccess: ({ data, paging }: { data: Object, paging: Object }) =>
        dispatch(setArticles(models.articles(data.articles, data.authors), paging)),
      onError: () =>
        Promise.all([dispatch(setError({ code: 404, message: 'Error al cargar los contenidos de la tag' }))])
    });
  };
}

export function getArticlesNextPage(tag: number = 34487, limit?: number, next: Object) {
  return function (dispatch: Dispatch) {
    dispatch(setArticlesFetchingStatus(true));

    if (next && next.href) {
      const offset = getUrlParameter('offset', next.href);
      return finectAPI({
        version: 'v4',
        path: `contents/tags/${tag}/organizations/articles?limit=${
          limit || 10
        }&offset=${offset}&orderby=-revision/updated`,
        method: 'GET',
        onSuccess: ({ data, paging }: { data: Object, paging: Object }) =>
          dispatch(setArticles(models.articles(data.articles, data.authors), paging)),
        onError: () =>
          Promise.all([dispatch(setError({ code: 404, message: 'Error al cargar los contenidos de la tag' }))])
      });
    }
  };
}

export function getTagNextArticles(tag: number | string = 34487, limit?: number, next?: Object) {
  const isNumber = isNumberUtil(tag);
  return function (dispatch: Dispatch) {
    dispatch(setTagNextArticlesFetchingStatus(true));

    const offset = next ? getUrlParameter('offset', next.href) : 0;
    return finectAPI({
      version: 'v4',
      path: isNumber
        ? `contents/articles?filter=tag+eq+${tag}+and+type+eq+'article'&limit=${
          limit || 10
        }&offset=${offset}&orderby=-revision/updated`
        : `contents/profiles/${tag}/articles?filter=type+eq+'article'&limit=${
          limit || 10
        }&offset=${offset}&orderby=-revision/updated`,
      method: 'GET',
      onSuccess: ({ data, paging }: { data: Object, paging: Object }) =>
        dispatch(setTagNextArticles(models.articles(data.articles, data.authors), paging)),
      onError: () => Promise.all([dispatch(setError({ code: 404, message: 'Error al cargar más contenidos' }))])
    });
  };
}

export function getContentPro(cookie: string, tag: number) {
  return function (dispatch: Dispatch) {
    return Promise.all([
      dispatch(getHighlights(cookie)),
      dispatch(getArticles(tag)),
      dispatch(getMultimedia('video', cookie, 6, 34487))
    ]);
  };
}

export function getArticlesFeed(filter: string = '', limit: number = 20, offset: number = 0) {
  return function (dispatch: Dispatch) {
    dispatch(setArticlesFeedFetchingStatus(true));

    return finectAPI({
      version: 'v4',
      path: `contents/articles?${filter}&limit=${limit}&offset=${offset}&orderby=-revision/created`,
      method: 'GET',
      onSuccess: ({ data, paging }: { data: Object, paging: Object }) =>
        dispatch(setArticlesFeed(models.articles(data.articles, data.authors), paging)),
      onError: () => {
        Promise.all([dispatch(setError({ code: 404, message: 'Error al cargar los contenidos' }))]);
      }
    });
  };
}
