// @flow
import styled from 'styled-components';

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    margin: 0 0 24px;
    line-height: 36px;
  }
`;
