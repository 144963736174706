// @flow
import React from 'react';
import { Section } from '@finect/tabular-components/Section';
import { QuestionsTable } from '../../QuestionsTable';

type Props = {
  questions: Array<Object>,
  organization: Object,
  me: Object,
  size: string
};

export const BankQuestions = ({ questions, organization, size = 'large' }: Props) => (
  <Section
    size={size}
    topMargin
    bottomMargin
    title={`Preguntas sobre los productos que vende ${organization.displayName}`}
    headerLink={{
      href: `/preguntas/nueva-pregunta?group=${organization.id}`,
      text: 'Pregunta en Finect',
      rel: 'nofollow'
    }}
  >
    <QuestionsTable questions={questions} withLastDate={false} />
  </Section>
);
