// @flow
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const pulse = keyframes`
  0%,
  60%,
  100% {
    opacity: 1;
    transform: scale(1);
  }

  30% {
    opacity: 0.1;
    transform: scale(0.01);
  }
`;

const SpinnerStyled = styled.div`
  display: inline-block;
  position: relative;
  width: 54px;
  height: 18px;

  & > div:nth-child(1) {
    animation-delay: -200ms;
  }

  & > div:nth-child(2) {
    animation-delay: -100ms;
  }

  & > div:nth-child(3) {
    animation-delay: 0ms;
  }
`;

const pulseRule = css`
  ${pulse} 1s ease infinite;
`;

const Dot = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin: 2px;
  background-color: ${({ theme }) => theme.mainColor};
  animation: ${pulseRule};
`;

export const Spinner = () => (
  <SpinnerStyled>
    <Dot />
    <Dot />
    <Dot />
  </SpinnerStyled>
);
