// @flow
import styled from 'styled-components';
import { getFont } from '@finect/tabular-utils/getFont';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';

export const DownloadItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.terciaryL};
  background-color: ${({ theme }) => theme.colors.terciaryLL};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    border-bottom: 0;
  }

  > a {
    ${getFont('0')}
    font-weight: 400;

    ${maxMediaQueries.phablet`
      ${getFont('-1')}
    `}
  }

  > svg {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    margin-right: 12px;
    color: ${({ theme }) => theme.colors.primary};

    ${maxMediaQueries.phablet`
      width: 30px;
      height: 30px;
    `}
  }
`;
