// @flow
import styled from 'styled-components';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';
import { grid } from '@finect/tabular-utils/grid';
import { AppLink } from '@finect/tabular-core/AppLink';

export const ContentPieceInner = styled.div``;

export const ContentPieceBlock = styled.div`
  margin: 20px 0 36px;
`;

export const WrapperHTMLPieces = styled.div`
  width: 100%;
  ${getFont(1)};

  ${maxMediaQueries.phone`
    ${getFont(0, 'compact')};
  `};

  img,
  embed {
    max-width: 100%;
    height: auto;
  }

  small,
  .text-small {
    font-size: 0.85em;
  }

  > div {
    script + iframe {
      border: none !important;
      border-radius: 4px;
      box-shadow: 0 4px 16px 0 rgb(46 46 51 / 0.2);
      margin-bottom: 24px !important;
    }

    > p a,
    a,
    > a {
      color: ${({ theme }) => theme.linkColor};
    }

    > h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div {
      strong,
      b {
        font-weight: 600;
      }
    }

    figure {
      margin-left: 0;
      margin-right: 0;

      img {
        width: 100%;
      }
    }

    > img,
    > a img,
    > p img {
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;
      border-radius: 2px;
      margin: 15px 0;
    }

    > p,
    > img,
    > center {
      &:not(:last-child) {
        margin-bottom: 30px;

        ${maxMediaQueries.phone`
        margin-bottom: 24px;
      `};
      }
    }

    > .h1,
    > .title,
    > h1 {
      ${getFont(5)};
    }

    hr {
      border: 1px solid ${({ theme }) => theme.colors.uiM};
      margin: 30px auto;
    }

    > .h2,
    h2 {
      ${getFont(3)};
      font-weight: 600;
    }

    > h3 {
      ${getFont(2)};
      font-weight: 600;
    }

    h2 .subtle,
    > h3 .subtle {
      ${getFont(1)};
      font-weight: 500;
      line-height: 1.333em;
    }

    > h4 {
      ${getFont(1)};
    }

    > .card h4 {
      ${getFont(0)};
      font-weight: 500;
    }

    > h5 {
      ${getFont(0)};
    }

    > h1,
    h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > iframe,
    > p > iframe {
      width: 100%;
    }

    > iframe,
    > p > iframe {
      display: block;
    }

    > iframe:not(:last-child),
    > p:not(:last-child) > iframe {
      margin-bottom: 30px;
    }

    h2,
    > h3,
    > h4,
    > h6 {
      margin-bottom: 15px !important;
    }

    > u {
      text-decoration: none;
    }

    > ul,
    > ol {
      width: 100%;
      max-width: 666px;
      margin: 0 auto;
      font-size: 1.125rem;
      line-height: 1.875rem;

      @media (max-width: 499px) {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      > li {
        margin-left: 30px;
      }
    }

    > ul:not(:last-child),
    > ol:not(:last-child) {
      margin-bottom: 30px;
    }

    > ul {
      > li {
        list-style: disc;
      }
    }

    > ol {
      > li {
        list-style: decimal;
      }
    }

    > ul,
    > ol,
    > p ul,
    > p ol {
      b,
      strong {
        font-weight: 600;
      }
    }

    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td {
      padding: 8px;
      border: 1px solid black;
      margin: 0;
      vertical-align: baseline;
      text-align: left;
    }

    table {
      min-width: ${grid(6)}px;
      margin: 0 auto;
      margin-bottom: 15px;
      border-collapse: collapse;
      border-spacing: 0;

      ${maxMediaQueries.phone`
        min-width: ${grid(3)}px;;
      `};
    }

    caption,
    th,
    td {
      vertical-align: middle;
      font-weight: 400;
    }

    .embedded-content {
      table,
      caption,
      tbody,
      tfoot,
      thead,
      tr,
      th,
      td {
        padding: 8px;
        border: initial;
        margin: 0;
        vertical-align: baseline;
        text-align: left;
      }

      table {
        min-width: ${grid(6)}px;
        margin: 0 auto;
        margin-bottom: 15px;
        border-collapse: collapse;
        border-spacing: 0;

        ${maxMediaQueries.phone`
          min-width: initial;
        `};
      }

      caption,
      th,
      td {
        vertical-align: middle;
        font-weight: 400;
      }
    }
  }
`;

export const WrapperHTMLDisclaimers = styled.div`
  p {
    margin: 0;
  }

  img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 4px 0;
  }
`;

export const ViewMoreDisclaimers = styled(AppLink)`
  display: flex;
  align-items: center;
`;

export const ContentMessage = styled.div`
  padding: 12px;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.accent1LL};
  font-size: 16px;
`;
