// @flow
import styled, { css } from 'styled-components';
import { getFont } from '@finect/tabular-utils/getFont';
import { textEllipsis } from '@finect/tabular-utils/textEllipsis';

const followedStyles = css`
  background-color: ${({ theme }) => theme.colors.accent1D};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent1D};
  }
`;

export const Topic = styled.a`
  display: inline-block;
  position: relative;
  padding: 1px 6px;
  border-radius: 4px;
  margin: 3px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.accent1LL};
  ${getFont(-2, 'compact')}
  font-variant: small-caps;
  color: ${({ theme }) => theme.colors.accent1};
  text-transform: lowercase;
  transition: all 0.15 ease;
  ${textEllipsis};

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent1};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
  }

  ${({ followed }) => followed && followedStyles};

  ${props =>
    props.small &&
    css`
      padding: 1px 5px 2px;
      border-radius: 3px;
      margin: 2px;
      ${getFont(-2, 'compact')}
    `}
`;
