/* eslint-disable id-length */
/* eslint-disable no-console */
import {
  rehydrateMarksMiddleware,
  initReduxStateMiddleware,
  initializedStoreMiddleware,
  tagManagerMiddleware,
  renderClientApp
} from '@finect/front-core/ReactApp';

import { configureStore } from './store';
import './imported';
import BrowserApp from './index-browser';

const settings = {
  initMiddlewares: [
    rehydrateMarksMiddleware,
    tagManagerMiddleware,
    initReduxStateMiddleware,
    initializedStoreMiddleware
  ],
  configureStore,
  BrowserApp
};

try {
  renderClientApp(settings);
} catch (e) {
  console.error('error', e);
}
