// @flow
import styled from 'styled-components';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';

export const Content = styled.div`
  display: flex;

  > div:first-child {
    margin-right: 16px;

    svg {
      vertical-align: middle;
    }
  }

  ${maxMediaQueries.phablet`
    display: block

    svg {
      margin-bottom: 12px;
    }

    p {
      margin-bottom: 24px;
    }
  `};
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 18px;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.ui};
  margin-bottom: 14px;
  background-color: ${({ theme }) => theme.colors.primaryLL};
  text-align: left;

  ${({ bottomMargin = false }) => (bottomMargin ? 'margin-bottom: 72px;' : '')};

  /* stylelint-disable */
  svg {
    width: 56px;
    height: 56px;
  }
  /* stylelint-enable */

  > div:first-child {
    padding-right: 30px;
  }

  h3 {
    padding-bottom: 3px;
    ${getFont(1, 'xcompact')};
    font-weight: 700;
  }

  ${maxMediaQueries.tablet`
    display: block;

    p {
      margin-bottom: 9px;
    }

    h3 {
      ${getFont(1, 'xcompact')};
    }
  `};

  ${maxMediaQueries.phone`
    display: block;

    > div:first-child {
      padding-right: 0;
      padding-bottom: 15px;
    }

    > div:last-child button {
      min-width: 180px;
    }
  `};

  transition: border-color 0.15s ease;

  @media (max-width: 900px) {
    max-width: none;
  }

  ${maxMediaQueries.tablet`
    padding: 15px;
  `};

  ${maxMediaQueries.phone`
    > div:last-child {
      button {
        width: 100%;
        margin-left: 0;
      }
    }
  `};
`;

export const CTABlock = styled.div`
  flex-shrink: 0;
`;
