// @flow
/**
 * Familias que aparecen en la home
 */
export const HOME_FAMILIES = [
  {
    id: 'fund',
    name: 'Fondos de inversión',
    tag: {
      id: '4425',
      alias: 'fondos-de-inversion',
      name: 'Fondos de inversión'
    }
  },
  {
    id: 'etf',
    name: 'ETFs',
    tag: {
      id: '15133',
      alias: 'etfs',
      name: 'ETFs'
    }
  },
  {
    id: 'deposit',
    name: 'Depósitos',
    tag: {
      id: '983',
      alias: 'depositos',
      name: 'Depósitos'
    }
  }
];
