// @flow
import type { Dispatch } from 'redux';
import { finectAPI } from '@finect/front-utils/finectAPI';
import { CONTEXT_DISTRIBUTOR_ID, CONTEXT_PRODUCT_ID, CONTEXT_STOREFRONT_ID } from './constants';

const allContext = [CONTEXT_DISTRIBUTOR_ID, CONTEXT_STOREFRONT_ID];

export function setContext(id: string, model: Object, queryUrl: string = '') {
  return {
    type: 'SET_CONTEXT',
    id,
    model,
    queryUrl
  };
}

export function clearContext() {
  return {
    type: 'CLEAR_CONTEXT'
  };
}

function getModelContext(idContext: string, alias: string) {
  const pathContexts = {
    [CONTEXT_DISTRIBUTOR_ID]: `contents/profiles/${alias}`,
    [CONTEXT_PRODUCT_ID]: `products/${alias}`,
    [CONTEXT_STOREFRONT_ID]: `contents/tags/${alias}`
  };

  return (dispatch: Dispatch) =>
    finectAPI({
      version: 'v4',
      path: pathContexts[idContext],
      method: 'GET',
      onSuccess: ({ data }) => dispatch(setContext(idContext, data)),
      // eslint-disable-next-line no-empty-function
      onError: () => {}
    });
}

export function getContext(params: Object) {
  return (dispatch: Dispatch, getState: Function) => {
    const validIdsContext = Object.keys(params).filter(key => allContext.includes(key));

    const validContext = validIdsContext.map(id => ({ id, alias: params[id] }));

    const stateContext = getState().context.context;

    const needContext = validContext.filter(
      val => !stateContext.find(stc => stc.id === val.id && stc.model.alias === val.alias)
    );

    if (needContext.length) {
      const promises = needContext.map(needC => dispatch(getModelContext(needC.id, needC.alias)));

      return Promise.all(promises);
    }
  };
}
