// @flow
export type OptionsType = 'basic' | 'free';

export const options = {
  basic: {
    title: 'Navegar sin cookies',
    highlight: 'Sin publicidad',
    price: '1€',
    period: 'mes',
    button: 'Comprar'
  },
  free: {
    title: 'Navegar gratis con cookies',
    highlight: 'Disfruta al 100% de Finect',
    price: '0€',
    period: null,
    button: 'Aceptar cookies'
  }
};
