// @flow
import styled from 'styled-components';
import { getFont } from '@finect/tabular-utils/getFont';

/* stylelint-disable */
/* eslint-disable */
export const Icon = styled.span`
  padding: 9px;
  border-right: 1px solid ${({ theme }) => theme.colors.uiM};
  margin-right: 12px;
  transition: all 0.25s ease;

  :hover,
  :focus {
    border-color: ${({ theme }) => theme.colors.accent1L};
  }

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

export const Inner = styled.button`
  display: block;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  border: 1px solid ${({ theme }) => theme.colors.ui};
  border-radius: 4px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 0.05);
  margin-bottom: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.terciaryD};
  text-align: center;
  text-align: left;
  transition: all 0.25s ease;

  :hover,
  :focus {
    border-color: ${({ theme }) => theme.colors.accent1L};
    box-shadow: 0 1px 2px rgb(23 113 251 / 0.2);
    color: ${({ theme }) => theme.colors.accent1};

    ${/* sc-prop */ Icon} {
      border-color: ${({ theme }) => theme.colors.accent1L};
    }
  }
`;

/* stylelint-enable */
/* eslint-enable */

export const OptionsInner = styled.div`
  display: grid;
  grid-template-rows: 1fr 0.8fr 0.6fr auto;
  padding: 2rem;
  border: 2px solid ${({ theme }) => theme.colors.ui};
  border-radius: 4px;

  > h4 {
    ${getFont(2)}
    font-weight: 600;
    margin-bottom: 20px;
  }

  > span:before {
    content: ' ';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
    border: 2px solid ${({ theme }) => theme.colors.primary};

    // inner shadow simulating an inner border
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.terciaryLL};
  }

  > div {
    ${getFont(3)}
    font-weight: 700;
  }

  > div > span {
    ${getFont(0)}
    font-weight: 400;
    color: ${({ theme }) => theme.colors.terciaryD};
  }
`;

export const Option = styled.button`
  width: 100%;
  text-align: center;

  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 2px;
  font-weight: 500;
  color: #fff;
`;
