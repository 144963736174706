// @flow
import React from 'react';
import { ArticleListCard } from './ArticleListCard';
import { ReviewListCard } from './ReviewListCard';
import { QuestionListCard } from './QuestionListCard';
import { AnswerListCard } from './AnswerListCard';

type Props = {
  entity: Object,
  type: 'article' | 'review' | 'question' | 'answer',
  onSubscribe: Function,
  userSubscriptions: Object,
  userLikes: Object,
  withAuthor?: boolean,
  withText?: boolean,
  profileAuthor?: Object
};

export class ContentListCard extends React.Component<Props> {
  render() {
    const {
      entity,
      type,
      onSubscribe,
      userSubscriptions,
      userLikes,
      withAuthor = true,
      withText = false,
      profileAuthor = null
    } = this.props;

    switch (type) {
      case 'article':
        return (
          <ArticleListCard
            article={entity}
            userSubscriptions={userSubscriptions}
            userLikes={userLikes}
            onSubscribe={onSubscribe}
            withAuthor={withAuthor}
            withText={withText}
          />
        );

      case 'review':
        return (
          <ReviewListCard review={entity} userLikes={userLikes} onSubscribe={onSubscribe} withAuthor={withAuthor} />
        );

      case 'answer':
        return <AnswerListCard answer={entity} profileAuthor={profileAuthor} />;

      case 'question':
        return (
          <QuestionListCard
            question={entity}
            userLikes={userLikes}
            onSubscribe={onSubscribe}
            userSubscriptions={userSubscriptions}
          />
        );

      default:
        return null;
    }
  }
}
