// @flow
import React from 'react';
import { Disclaimer } from '@finect/tabular-components/Disclaimer';

export const BankDisclaimer = () => (
  <Disclaimer
    text="Los contenidos de esta sección están patrocinados y pueden ser catalogados como material de marketing.
  No constituyen una recomendación ni propuesta de inversión. Toda inversión contiene riesgos y
  rentabilidades pasadas no son garantía de rentabilidades futuras."
  />
);
