import styled, { css } from 'styled-components';

const solvedStyles = css`
  border-radius: 18px;
  margin-left: 3px;
  background-color: ${({ theme }) => theme.colors.positive};
  color: ${({ solved }) => (solved ? '#fff' : 'currentColor')};
  transition: background-color 0.25s ease;
`;

export const Count = styled.div`
  min-width: 21px;
  height: 21px;
  padding: 0 6px;
  border-radius: 21px;
  margin-right: 3px;
  background-color: ${({ theme, solved }) => (solved ? theme.colors.positive : theme.colors.uiL)};
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 21px;
  text-align: center;
  ${({ solved }) => solved && solvedStyles};
`;
