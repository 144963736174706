/* eslint-disable no-console */
// @flow
import React, { Fragment } from 'react';
import { PillModal } from '@finect/tabular-components/Pills';
import { Inner, ButtonLined } from './partials';

type Props = {
  organization: Object
};

type State = {
  response: boolean | null
};

// TODO: Creo que ya no se usa
export class BankOnboardingCTA extends React.Component<Props, State> {
  state = {
    response: null
  };

  handleResponse = (value: boolean) => {
    this.setState({ response: value });
  };

  render() {
    const { organization } = this.props;

    const pillUrl = this.state.response
      ? `https://www.finect.com/pills/es_ES/finect/default/interes-productos-bancarios/steps/timeHorizonRange?coreBankingRelationship=${this.props.organization.id}`
      : 'https://www.finect.com/pills/es_ES/finect/default/interes-productos-bancarios/steps/timeHorizonRange';

    return (
      <Fragment>
        {this.state.response !== null ? (
          <PillModal
            growOnResize={false}
            pill={pillUrl}
            onResize={event => console.log(`He crecido a esta altura: ${event.height}`)}
            onClose={() => {
              this.setState({ response: null });
            }}
            onResultsPage={() => {
              this.setState({ response: null });
              // this.setState({ pill: null, displayBanner: false });
            }}
          />
        ) : null}

        <Inner>
          <h5>¿Tienes cuenta en {organization.displayName}?</h5>

          <ButtonLined
            variant="medium"
            width="auto"
            onClick={() => {
              this.handleResponse(true);
            }}
          >
            Sí
          </ButtonLined>

          <ButtonLined
            variant="medium"
            width="auto"
            onClick={() => {
              this.handleResponse(false);
            }}
          >
            No
          </ButtonLined>
        </Inner>
      </Fragment>
    );
  }
}
