import { organization } from './organization';

const categoryReducePerformance = (periods, periodCode, type = 'accumulated') => {
  const match = periods.find(stat => stat.period === periodCode && stat.type === type);

  return match ? match.value : null;
};
const baseCategory = rest => ({
  id: rest.id,
  alias: rest.alias ? rest.alias : rest.id,
  name: rest.name,
  provider: rest.provider
});
const baseCategories = rests => (rests ? rests.map(rest => baseCategory(rest)) : []);

const category = (rest) => {
  const periods = rest.stats.performance ? rest.stats.performance : [];

  return {
    id: rest.id,
    alias: rest.alias,
    name: rest.name,
    broad: rest.broad ? rest.broad.name : null,
    description: rest.description,
    provider: rest.provider,
    stats: rest.stats,
    performance: {
      M12: categoryReducePerformance(periods, 'M12'),
      M36: categoryReducePerformance(periods, 'M36'),
      M36A: categoryReducePerformance(periods, 'M36', 'annualized')
    },
    managementCompanies: rest.managementCompanies.map(item => ({
      id: item.id,
      alias: item.alias ? item.alias : item.id,
      name: item.name,
      type: item.type,
      country: item.county
    }))
  };
};

const categories = rests => rests.map(rest => category(rest));

const managementCompany = rest => rest;
const managementCompanies = rests => rests.map(rest => managementCompany(rest));

const fullManagementCompany = rest => ({
  id: rest.id,
  alias: rest.alias,
  name: rest.name,
  stats: rest.stats,
  type: rest.type,
  country: rest.country,
  categories: baseCategories(rest.categories),
  organization: rest.organization ? organization(rest.organization) : null
});

const fullManagementCompanies = rests => rests.map(rest => fullManagementCompany(rest));

const getCategoryByProvider = (categoriesScope, provider) =>
  categoriesScope.find(element => element.provider === provider);

const iicClassReduceFee = (fees, type) => {
  const match = fees.find(fee => fee.type === type);
  return match ? match : null;
};

const iicClassFee = rest => ({
  type: rest.type,
  name: rest.name,
  value: rest.value,
  unit: rest.unit
});
const iicClassFees = rests => rests.map(rest => iicClassFee(rest));

const iicClass = (rest) => {
  const fees = iicClassFees(rest.fees);

  const fundClass = {
    ...rest,
    ...{
      fees: {
        mgr: iicClassReduceFee(fees, 'MGR'),
        red: iicClassReduceFee(fees, 'RED'),
        cus: iicClassReduceFee(fees, 'CUS'),
        suc: iicClassReduceFee(fees, 'SUC'),
        flo: iicClassReduceFee(fees, 'FLO'),
        ter: iicClassReduceFee(fees, 'TER'),
        ogc: iicClassReduceFee(fees, 'OGC')
      }
    }
  };

  return fundClass;
};

const iicClasses = rests => rests.map(rest => iicClass(rest));

const plan = rest =>
  (!rest
    ? null
    : {
      ...rest,
      ...{ subtype: rest.subtype || 'plan' },
      categories: rest.category,
      category: getCategoryByProvider(rest.category, 'MS'),
      classes: iicClasses(rest.classes),
      managementCompany: managementCompany(rest.managementCompany)
    });

const fund = rest =>
  (!rest
    ? null
    : {
      ...rest,
      ...{ subtype: rest.subtype || 'fund' },
      categories: rest.category,
      category: getCategoryByProvider(rest.category, 'MS'),
      classes: iicClasses(rest.classes),
      managementCompany: managementCompany(rest.managementCompany)
    });

const genericProduct = (subtype, rest) => ({
  ...rest,
  ...{ subtype: rest.subtype || subtype },
  categories: rest.category,
  category: getCategoryByProvider(rest.category, 'MS'),
  classes: iicClasses(rest.classes),
  managementCompany: managementCompany(rest.managementCompany)
});

const funds = rests => rests.map(rest => fund(rest));
const plans = rests => rests.map(rest => plan(rest));
const genericIICCollection = (subtype, rests) => rests.map(rest => genericProduct(subtype, rest));

// Se utiliza en las actions cuando recibe los datos de la API para añadir el atributo subtype a un modelo
const collection = (productType, rests) => {
  switch (productType.api) {
    case 'funds':
      return funds(rests);
    case 'plans':
      return plans(rests);

    case 'etfs':
      return genericIICCollection('etf', rests);

    case 'sicavs':
      return genericIICCollection('sicav', rests);

    default:
      return [];
  }
};

const product = (productType, rests) => {
  switch (productType.api) {
    case 'funds':
      return fund(rests);
    case 'plans':
      return plan(rests);

    case 'etfs':
      return genericProduct('etf', rests);

    case 'sicavs':
      return genericProduct('sicav', rests);

    default:
      return [];
  }
};

export const investmentProducts = {
  collection,
  product,
  fund,
  funds,
  plan,
  plans,
  category,
  categories,
  managementCompany,
  managementCompanies,
  fullManagementCompany,
  fullManagementCompanies,
  getCategoryByProvider
};
