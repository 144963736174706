import styled from 'styled-components';
import { getFont, getLineHeight } from '@finect/tabular-utils/getFont';
import { minMediaQueries } from '@finect/tabular-utils/mediaQueries';

export const HeadContent = styled.div`
  display: none;
  width: 100%;
  padding: 8px 16px;
  font-weight: 700;
  ${getFont(1)}
  color: ${({ theme }) => theme.colors.terciary};

  ${minMediaQueries.phablet`
    display: flex;
  `};
`;

export const HeadQuestion = styled.div`
  width: 55%;
  padding: 0 12px 0 0;
  color: ${({ theme }) => theme.colors.font};
  ${getFont(0)}
`;

export const HeadTag = styled.div`
  width: 35%;
  padding: 0 12px;
`;
export const HeadAnswers = styled.div`
  width: 15%;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.font};
  ${getFont(0)}

  ${minMediaQueries.phablet`
    padding: 0 12px;
  `};
`;

export const QuestionContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 18px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.terciaryL};

  ${minMediaQueries.phablet`
    flex-direction: row;
  `};
`;

export const QuestionTitle = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  padding: 8px 0;

  ${minMediaQueries.phablet`
    width: 55%;
    padding: 0 12px;
  `};
`;

export const ProductBlock = styled.div`
  ${getFont(-1)}
  color: ${({ theme }) => theme.colors.terciary};

  :first-child {
    margin-top: 12px;
  }

  a {
    color: ${({ theme }) => theme.colors.terciaryD};
  }

  svg {
    width: ${`${getLineHeight(-2)}rem`};
    height: ${`${getLineHeight(-2)}rem`};
    margin-right: 6px;
    vertical-align: middle;
  }
`;

export const QuestionTags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  ${minMediaQueries.phablet`
    width: 30%;
    padding: 0 12px;
  `};

  ${minMediaQueries.miniDesktop`
    width: 35%;
  `};
`;

export const QuestionAnswers = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  margin-left: auto;

  ${minMediaQueries.phablet`
    width: 20%;
  `};

  ${minMediaQueries.miniDesktop`
    width: 15%;
  `};
`;

export const AnswersContent = styled.span`
  ${getFont('-1')}
  width: 100%;
  padding-top: 8px;
  line-height: 28px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.terciary};

  ${minMediaQueries.phablet`
    padding: 0 12px;
  `};
`;

export const BadgeContent = styled.div`
  ${getFont(-1)}
`;

export const AnswersHint = styled.div`
  font-size: 95%;
`;
