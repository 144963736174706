// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { closeOverlay, getLoggedUser, getSubscriptions } from '@finect/front-core/User';
import { OverBox } from './OverBox';

const mapStateToProps = state => ({
  overlay: state.user.loginAction
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeOverlay,
      getLoggedUser,
      getSubscriptions
    },
    dispatch
  )
});

type Props = {
  overlay: Object,
  actions: Object
};

const Overlay = ({ overlay, actions }: Props) => {
  if (!(overlay.hasOwnProperty('title') || overlay.hasOwnProperty('copy'))) {
    return null;
  }

  const blob = Buffer.from(JSON.stringify(overlay)).toString('base64');

  if (!blob) return null;

  const processMessage = (event: Object) => {
    if (event.data.status === 'loggedIn') {
      actions.closeOverlay();
      actions.getLoggedUser();

      // TODO: Para poder solicitar entidades concretas hay que acceder primero al store
      actions.getSubscriptions();
    }
  };

  return <OverBox blob={blob} closeOverlay={actions.closeOverlay} processMessage={processMessage} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
