// @flow
import { H5, H4, H6 } from '@finect/tabular-core/Headings';
import styled from 'styled-components';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';

const CourseName = styled(H4)`
  display: block;

  > img {
    display: none;
  }

  ${maxMediaQueries.phablet`
    display: flex;
    align-items: center;

    > a {
      margin-left: 12px;
    }

    > img {
      display: block;
      width: 80px;
      height: auto;
      border-radius: ${({ theme }) => theme.borderRadius};
    }
  `}
`;

const CourseInfo = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 24px;
`;

const CourseOwner = styled(H6)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.terciary};
`;

const CourseOriginalPrice = styled.span`
  margin-right: 8px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.terciary};
  ${getFont('-1', 'compact')};
  text-decoration: line-through;
`;

const CoursePrice = styled(H5)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.terciaryD};

  &:not(:last-child) {
    padding-bottom: 0;
  }
`;

const CourseDescription = styled.p`
  ${getFont(0, 'compact')};
  color: ${({ theme }) => theme.colors.terciary};

  &:after {
    display: block;
    width: 100%;
    height: 1px;
    border-radius: 6px;
    margin: 8px auto 0;
    content: '';
    background-color: ${({ theme }) => theme.colors.terciaryM};
  }
`;

const CourseImage = styled.img`
  height: 100%;
`;

const CourseImageContainer = styled.div`
  display: block;

  ${maxMediaQueries.phablet`
    display: none;
  `}
`;

const CourseContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  height: 300px;
  border: 1px solid ${({ theme }) => theme.colors.terciaryL};
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.colors.terciaryLL};

  ${maxMediaQueries.phablet`
    height: auto;
  `}
`;

const CourseAction = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CourseFeatures = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  margin: 8px 0;

  ${maxMediaQueries.phablet`
    margin: 16px 0;
  `}
`;

export {
  CourseName,
  CourseInfo,
  CourseOwner,
  CoursePrice,
  CourseDescription,
  CourseImage,
  CourseImageContainer,
  CourseContainer,
  CourseAction,
  CourseFeatures,
  CourseOriginalPrice
};
