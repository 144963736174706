// @flow
import React, { Fragment } from 'react';
import { getProductTypeById, getProductTypeLabel } from '@finect/front-resources/Products';
import { useDispatch } from 'react-redux';
import { getBankProductSubtypes } from '../utils';
import { BankProductsByType } from '../BankProductsByType';
import { setProductRequest } from '../../../store/products/actions';
import { ContentPieces, getContentPiecesByTitle } from '../../CMS';
import { BannerBlock } from './partials';

type Props = {
  page: Object,
  products: Array<Object>,
  organization: Object,
  sectionSize: string,
  contextProps: Object,
  handleEntityFollow: Function,
  subscriptions: Object
};

export const BankProductsCarousels = ({
  page,
  contextProps,
  handleEntityFollow,
  subscriptions,
  sectionSize,
  products,
  organization
}: Props) => {
  const dispatch = useDispatch();

  const handleProductRequest = (product: Object) => {
    dispatch(setProductRequest(product));
  };

  const productTypes = getBankProductSubtypes(products.map(({ subtype }) => subtype));

  const productsByType = productTypes.map(subtype => ({
    productType: getProductTypeById(subtype),
    products: products.filter(item => item.subtype === subtype)
  }));

  const contentPieces = getContentPiecesByTitle(page, 'BANK_MAIN_BANNER');

  return (
    <>
      {productsByType.map((item, index) => {
        const displayProducts = item.products.slice(0, 10);

        const plural = getProductTypeLabel(item.productType, 'plural', 'lowercase');
        const shortPlural = getProductTypeLabel(item.productType, 'shortPlural', 'lowercase');

        const text =
          displayProducts.length + 1 < item.products.length
            ? `Ver ${item.products.length - displayProducts.length} ${shortPlural} más`
            : 'Ver todos';

        const title = `Ver ${plural} que comercializa ${organization.displayName}`;

        return (
          <Fragment key={index}>
            <BankProductsByType
              title={item.productType.plural}
              variants={{
                carousel: 'large',
                grid: 'large'
              }}
              link={{
                type: 'client',
                href: `/bancos/${organization.model.alias}/productos/${item.productType.slug}`,
                text,
                title
              }}
              size={sectionSize}
              view="carousel"
              organization={organization.model}
              productType={item.productType}
              products={displayProducts}
              // $FlowFixMe
              subscriptions={subscriptions.products}
              onProductRequest={handleProductRequest}
              onFollow={(product: string, status: boolean) => {
                handleEntityFollow('product', 'subscribe', product, status);
              }}
              context={contextProps.context}
            />

            {/* data-fi-event="horizontal-banner" */}
            {index === 0 && contentPieces.length ? (
              <BannerBlock>
                <ContentPieces contentPieces={contentPieces} />
              </BannerBlock>
            ) : null}
          </Fragment>
        );
      })}
    </>
  );
};
