// @flow
import React from 'react';
import { Section } from '@finect/tabular-components/Section';
import { Tiles } from '@finect/tabular-components/Tiles';
import { ImageCard } from '@finect/tabular-components/Cards';
import { breakPointsSet3 } from '@finect/tabular-components/Carousel';
import { shuffle } from './../../utils';

type Props = {
  companies: Object,
  type?: string
};

export const Ecosystem = ({ companies, type }: Props) => {
  let shuffled = JSON.parse(JSON.stringify(companies.items));
  if (type === 'groups') {
    shuffled = shuffle(companies);
  }

  const title =
    type === 'groups'
      ? `Empresas que puedes encontrar en Finect`
      : `Te ayudamos a analizar los productos de inversión más vendidos en los bancos`;

  const link =
    type === 'groups'
      ? {
        text: 'Ver más',
        href: '/grupos'
      }
      : null;

  return (
    <Section
      title={title}
      subtitle={type === 'groups' ? `` : `Encuentra, compara y contrata.`}
      bottomMargin
      link={link}
    >
      <Tiles breakpoints={breakPointsSet3}>
        {shuffled.map((group: Object, index) => (
          <ImageCard
            data-fi-event="home-ecosystem"
            key={index}
            title={group.displayName}
            image={type === 'groups' ? group.image : group.image}
            link={{
              href: group.link,
              type: type === 'groups' ? null : 'client'
            }}
          />
        ))}
      </Tiles>
    </Section>
  );
};
