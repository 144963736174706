/* eslint-disable no-empty-function */
// @flow
import React from 'react';
import { ProductsTableWithUser } from '@finect/tabular-components/Products';
import { withSubscriptions } from '@finect/front-core/User';
import type { WithSubscriptionsProps } from '@finect/front-core/User';
import { withComparison } from '../../../HOC';
import type { WithComparisonProps } from '../../../HOC';
import { fieldsByProductType } from '../../../configs/fieldsByProductType';

type Props = {
  productType: Object,
  products: Array<Object>,
  onOrderChange: Function,
  order: Object,
  onProductRequest: Function,
  context: Array<Object>,
  distributorsWhiteList?: Array<string>
} & WithComparisonProps &
  WithSubscriptionsProps;

class BankProductsTable extends React.Component<Props> {
  render() {
    const {
      productType,
      products,
      onOrderChange,
      order,
      comparisonActions,
      selectedProducts,
      selectedProductsType,
      onProductRequest = () => {},
      handleEntityFollow = () => {},
      subscriptions = { products: [] },
      context = [],
      distributorsWhiteList = []
    } = this.props;

    const fieldsDefinition: Object = fieldsByProductType.find(item => item.productType === productType.id);

    const fields = fieldsDefinition.fields.filter(
      field => !(distributorsWhiteList.length && field.id === 'distributors')
    );

    return (
      <ProductsTableWithUser
        productType={productType}
        withCustomization
        size="medium"
        selectable={{ initial: true }}
        onProductSelect={comparisonActions.setDrawerProductSelected}
        selectedProducts={selectedProducts}
        selectedProductsType={selectedProductsType}
        products={products}
        fields={fields}
        onOrderChange={onOrderChange}
        order={order}
        context={context}
        productsSubscriptions={subscriptions.products}
        actions={{
          onProductRequest,
          onProductSubscription: handleEntityFollow
        }}
      />
    );
  }
}

export default withSubscriptions(
  withComparison(BankProductsTable, {
    withDrawer: false,
    modelType: 'product'
  }),
  ['products']
);
