/* eslint-disable no-inline-comments */
// @flow
import imported from 'react-imported-component';

export { BankQuestions, BankQuestionsCTA } from './BankQuestions';
export { BankThemeProvider } from './BankThemeProvider';
export { BankOnboardingCTA } from './BankOnboardingCTA';
export { BankManagers } from './BankManagers';
export { BankIntro } from './BankIntro';
export { BankProductsByType } from './BankProductsByType';
export { BankProductsHeader } from './BankProductsHeader';
export { BankProductFamilies } from './BankProductFamilies';
export { BankSmallArticle } from './BankSmallArticle';
export { BankPageContainer } from './BankSkeletons';
export { BankProductsCarousels } from './BankProductsCarousels';

export { getBankProductSubtypes } from './utils';

export const BankArticles = imported(() => import(/* webpackChunkName: "Banks-BankArticles" */ './BankArticles'));
export const BankHeader = imported(() => import(/* webpackChunkName: "Banks-BankHeader" */ './BankHeader'));
