// @flow
import React from 'react';
import { AppLink } from '@finect/tabular-core/AppLink';

import { withAuthorizations } from '@finect/front-core/Authorizations';
import { isUserAdviser } from '@finect/front-core/Profiles';
import { AuthorizationsTitle, AuthorizationsLink, NavInner } from './partials';
import AuthorizationsAdvisers from './AuthorizationsAdvisers';

type Props = {
  authorizationsProps: Object,
  user: Object,
  bottomMargin?: boolean
};

export const AuthorizationsNav = ({ authorizationsProps, user, bottomMargin = false }: Props) => {
  const { userACL } = authorizationsProps;

  const isAdviser = isUserAdviser(user);
  const ready = !userACL.fetching && userACL.fetched;

  if (!ready) {
    return null;
  }

  const advisersCount = userACL.requests.length + userACL.authorizations.length;

  const title = advisersCount && !isAdviser ? 'Asesores autorizados' : 'No has autorizado a ningún asesor';
  const link = advisersCount && !isAdviser ? null : <AppLink href="/asesores">Busca un asesor que te ayude</AppLink>;

  return (
    <NavInner bottomMargin={bottomMargin}>
      <div>
        <AuthorizationsTitle>{title}</AuthorizationsTitle>

        {advisersCount && !isAdviser ? (
          <AuthorizationsAdvisers authorizationsProps={authorizationsProps} user={user} />
        ) : null}

        <AuthorizationsLink>{link}</AuthorizationsLink>
      </div>
    </NavInner>
  );
};

export default withAuthorizations(AuthorizationsNav);
