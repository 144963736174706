// @flow
// $FlowFixMe
import React, { Fragment } from 'react';
import { MessageCircle } from '@finect/tabular-icons/messageCircle';
import { Bookmark, Bookmarked } from '@finect/tabular-icons/bookmark';
import { Heart } from '@finect/tabular-icons/heart';
import { CornerUpLeft } from '@finect/tabular-icons/cornerUpLeft';
import { Share as ShareIcon } from '@finect/tabular-icons/share';
import { Share } from '@finect/tabular-components/Share';
import { Action } from './Action';
import { ActionLabel } from './partials';
import { getTitleShare } from './../../utils/articles';

// const random = (max: number = 100) => Math.floor(Math.random() * max);

type Props = {
  inline: boolean,
  type: string,
  // likes: number,
  // comments: number,
  liked?: boolean,
  solved?: boolean,
  saved?: boolean,
  onLike: Function,
  onSave: Function,
  title: string,
  url: string
};

export const ToolbarItems = ({
  title,
  url,
  inline,
  type,
  liked,
  solved,
  saved,
  // likes,
  // comments,
  onLike,
  onSave
}: Props) => (
  <Fragment>
    <Action inline={inline} color="secondary" done={liked} onClick={onLike}>
      {/* <Count>{likes}</Count> */}
      <Heart />
      <ActionLabel>Me gusta</ActionLabel>
    </Action>
    {type === 'question' ? (
      <Action inline={inline} color="primary" solved={solved}>
        {/* <Count solved={solved}>{random(10)}</Count> */}
        <CornerUpLeft style={{ position: 'relative', top: '-1px' }} />
        <ActionLabel>Responder</ActionLabel>
      </Action>
    ) :
      type === 'article' && (
        <Action inline={inline} color="primary" as="a" href={`${url}#comentarios`}>
          {/* <Count>{comments}</Count> */}
          <MessageCircle />
          <ActionLabel>Comentar</ActionLabel>
        </Action>
      )
    }
    {type !== 'article' && (
      <Action inline={inline} done={saved} onClick={onSave}>
        {saved ? <Bookmarked /> : <Bookmark />}
        <ActionLabel>{saved ? 'Guardado' : 'Guardar'}</ActionLabel>
      </Action>
    )}

    <Share title={getTitleShare(title)} url={url} via="Finect" positionX="top" arrowPosition={4}>
      <Action inline={inline}>
        <ShareIcon style={{ position: 'relative', top: '-1px' }} />
        <ActionLabel style={{ verticalAlign: 'super' }}>Compartir</ActionLabel>
      </Action>
    </Share>
  </Fragment>
);
