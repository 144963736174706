// @flow
import styled from 'styled-components';
import { H1 } from '@finect/tabular-core/Headings';
import { ButtonLined } from '@finect/tabular-core/ButtonLined';
import { Button } from '@finect/tabular-core/Button';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';

export const Inner = styled.div`
  display: flex;
  flex: auto;
  padding: 16px 0 0;

  ${maxMediaQueries.phablet`
    flex-flow: column;
    justify-content: center;
    align-items: center;
  `}
`;

export const IconBlock = styled.div`
  ${maxMediaQueries.phablet`
    > span {
      margin: 0;
    }
  `}
`;

export const InfoBlock = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0;

  ${maxMediaQueries.phablet`
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `}
`;

export const Name = styled(H1)`
  margin-right: 12px;

  &:not(:last-child) {
    padding-bottom: 0;
  }

  ${maxMediaQueries.phablet`
    ${getFont(3, 'compact')}
  `}
`;

export const NameBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 16px;
  vertical-align: center;

  ${maxMediaQueries.phablet`
    padding-top: 16px;
    flex-flow: column;
  `}
`;

export const HeadlineBlock = styled.div``;

export const ProfileExtra = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ProfileActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button,
  a:not(:first-of-type) {
    margin-left: 6px;
  }
`;

export const BadgesContent = styled.div`
  margin: 16px 0;
`;

// TODO: Todos estos botones tienen que estar estandarizados en components

export const ConfigButton = styled(Button)`
  display: flex;
  padding: 6px 8px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.uiD};

  &:not([disabled]):hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.uiDD};
  }
`;

export const WriteButton = styled(ButtonLined)`
  display: flex;
  align-items: center;
  padding: 6px 12px;

  &:not([disabled]):hover {
    border-color: ${({ theme, secondary }) => (secondary ? theme.colors.secondaryM : theme.colors.primaryM)};
    background-color: ${({ theme, secondary }) => (secondary ? theme.colors.secondaryM : theme.colors.primaryM)};
  }

  svg {
    margin-right: 6px;
  }
`;

export const MessageButton = styled(WriteButton)`
  border: 0;

  &:not([disabled]):hover {
    background-color: transparent;
    color: ${({ theme, secondary }) => (secondary ? theme.colors.secondaryD : theme.colors.primaryD)};
  }
`;

export const FollowButton = styled(WriteButton)`
  background-color: ${({ theme, followed }) => (followed ? theme.colors.primary : theme.colors.secondary)};
`;
