// @flow
import { getProductTypesByCategory } from '../utils/productTypes';

const contentProducts = getProductTypesByCategory('content');
const iicProducts = getProductTypesByCategory('iic');

// Write Review
const contentWriteReviewRegex = `/:typeSlug(${contentProducts
  .map(productType => productType.slug)
  .join('|')})/:alias/reviews/nueva-review`;

const iicWriteReviewRegex = `/:typeSlug(${iicProducts
  .map(productType => productType.slug)
  .join('|')})/:isin-:alias/reviews/nueva-review`;

// Review
const contentReviewRegex = `/:typeSlug(${contentProducts
  .map(productType => productType.slug)
  .join('|')})/:alias/reviews/:idReview([a-fA-F0-9]{32})`;

const iicReviewRegex = `/:typeSlug(${iicProducts
  .map(productType => productType.slug)
  .join('|')})/:isin-:alias/reviews/:idReview([a-fA-F0-9]{32})`;

export const contentsRoutes = {
  iicWriteReview: iicWriteReviewRegex,
  iicReview: iicReviewRegex,

  contentWriteReview: contentWriteReviewRegex,
  contentReview: contentReviewRegex
};
