// @flow
import type { Dispatch } from 'redux';
import { finectAPI } from '@finect/front-utils/finectAPI';

export function setSettingFetchingStatus(setting: string, fetching: boolean, fetched?: boolean) {
  return {
    type: 'SET_SETTING_FETCHING_STATUS',
    setting,
    fetching,
    fetched
  };
}

export function setSetting(setting: string, value: any) {
  return {
    type: 'SET_SETTING',
    setting,
    value
  };
}

export function fetchSetting(id: string) {
  return (dispatch: Dispatch) => {
    dispatch(setSettingFetchingStatus(id, true, true));
    return finectAPI({
      version: 'v4',
      path: `resources/setting/${id}`,
      method: 'GET',
      onSuccess: ({ data }: { data: Object }) => {
        dispatch(setSetting(id, data.value));
        return { value: data.value /*, fetching: false, fetched: true */ };
      },
      onError: () => {
        dispatch(setSetting(id, null));
        return { value: {} /*, fetching: false, fetched: true */ };
      }
    });
  };
}
