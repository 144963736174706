const stats = rest => ({
  subscribers: rest.subscribers,
  reviews: rest.reviews,
  posts: rest.posts
});

const organization = rest => ({
  ...rest,
  stats: stats(rest.stats)
});

export { organization };
