// @flow
import styled from 'styled-components';
import { Flex } from '@finect/tabular-core/Flex';
import { minMediaQueries, maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { H1 } from '@finect/tabular-core/Headings';

export const Header = styled.header`
  margin: 18px 0 0;

  ${({ noMargin = false }) => (noMargin ? 'margin: 0;' : '')}
  ${({ bottomMargin }) => (bottomMargin ? 'margin-bottom: 24px;' : '')}
  ${({ topMargin }) => (topMargin ? 'margin-top: 24px;' : '')}
`;

export const Page = styled(Flex)`
  align-items: flex-start;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: space-between;

  ${minMediaQueries.phablet`
    align-items: flex-start;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  `};
`;

export const PageTitle = styled(H1)`
  display: block;
  flex-grow: 1;
  padding-bottom: 0;
  margin-bottom: 14px;

  &:not(:last-child) {
    padding-bottom: 0;
  }

  ${minMediaQueries.tablet`
    flex-wrap: nowrap;
    margin-bottom: 14px;
  `};
`;

export const PageSubtitle = styled.div`
  display: block;
  flex-grow: 1;
  padding-bottom: 0;
  margin-bottom: 0;
  ${maxMediaQueries.tablet`
    max-width:480px;
  `};
`;

export const Actions = styled.div`
  flex-shrink: 0;
  margin-right: auto;

  ${minMediaQueries.phablet`
    margin-left: auto;
    margin-right: 0;
  `};

  ${maxMediaQueries.phablet`
    // margin-top: 18px;
  `};
`;
