// flag: unificar con canonicals, productTypes y utilidades
import { slugs } from '../configs/iic';

// Home de productos
const getProductsMainPath = () => `/${slugs.products}`;

// Comparador
const getComparisonPath = productType =>
  (productType ? `/${productType.slug}/${slugs.comparison}` : `/${slugs.superComparison}`);

// Home para una tipología de producto
const getHomePath = productType => `/${productType.slug}`;

// Categorías
const getCategoriesPath = (productType, macrocategory = 'todos') =>
  `/${productType.slug}/${slugs.categories}/${macrocategory}`;

const getCategoryPath = (productType, category) =>
  `/${productType.slug}/${slugs.category}/${category.id}-${category.alias}`;

// Gestoras
const getManagementCompaniesPath = productType => `/${productType.slug}/${slugs.managementCompanies}`;

const getManagementCompanyPath = (productType, managementCompany) =>
  `/${productType.slug}/${slugs.managementCompanies}/${managementCompany.id}-${managementCompany.alias}`;

// Ficha
const getSheetPath = (productType, product) => `/${productType.slug}/${product.isin}-${product.alias}`;

const getSectionPathByProductType = productType => `/${productType.slug}`;

export {
  getProductsMainPath,
  getComparisonPath,
  getHomePath,
  getCategoriesPath,
  getCategoryPath,
  getManagementCompanyPath,
  getManagementCompaniesPath,
  getSheetPath,
  getSectionPathByProductType
};
