// @flow
import React, { Fragment } from 'react';
// import { Tag } from '@finect/tabular-components/Tags';
import { AppLink } from '@finect/tabular-core/AppLink';
import { slugify } from '@finect/front-utils/slugify';
import { DateLabel } from '@finect/tabular-components/DateLabel';
import { TrendingUp } from '@finect/tabular-icons/trendingUp';
import { getProductTypeById } from '@finect/front-resources/Products';
import { Strong } from '@finect/tabular-core/Strong';
import {
  HeadContent,
  HeadQuestion,
  // HeadTag,
  HeadAnswers,
  QuestionContent,
  QuestionTitle,
  // QuestionTags,
  QuestionAnswers,
  AnswersContent,
  BadgeContent,
  ProductBlock,
  AnswersHint
} from './partials';

type Props = {
  questions: Array<Object>,
  withLastDate?: boolean
};

export const QuestionsTable = ({ questions, withLastDate = false }: Props) => (
  <Fragment>
    <HeadContent>
      <HeadQuestion>Pregunta</HeadQuestion>
      {/* <HeadTag>Temas</HeadTag> */}
      <HeadAnswers>Respuestas</HeadAnswers>
    </HeadContent>

    {questions.map((question) => {
      const products = (question.products || [])
        .map(({ name, type, url }) => {
          const productType = getProductTypeById(type);
          return productType
            ? {
              name,
              url: `/${productType.slug}/${url}`
            }
            : null;
        })
        .filter(product => Boolean(product));

      const url = `/preguntas/${question.id}/${slugify(question.title)}`;

      return (
        <QuestionContent key={question.id}>
          <QuestionTitle>
            <AppLink href={url}>{question.title}</AppLink>

            <div>
              {products.slice(0, 2).map((product, index) => (
                <ProductBlock key={index}>
                  <AppLink href={product.url}>
                    <TrendingUp />
                    {product.name}
                  </AppLink>
                </ProductBlock>
              ))}
            </div>
          </QuestionTitle>

          {/* <QuestionTags>
            {question.tags.map(tag => (
              <AppLink key={tag.id} href={`/temas/${tag.description}`}>
                <Tag small>{tag.name}</Tag>
              </AppLink>
            ))}
          </QuestionTags> */}

          <QuestionAnswers>
            <AnswersContent>
              <BadgeContent as={AppLink} href={url}>
                <Strong>{question.stats.answers}</Strong> respuesta{question.stats.answers === 1 ? '' : 's'}
              </BadgeContent>

              {withLastDate && question.last_answer ? (
                <AnswersHint>
                  <span>La última fue </span>
                  <DateLabel date={new Date(question.last_answer)} format="timeSince" />
                </AnswersHint>
              ) : null}
            </AnswersContent>
          </QuestionAnswers>
        </QuestionContent>
      );
    })}
  </Fragment>
);
