// @flow
import styled from 'styled-components';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';

export const CardAvatar = styled.div`
  display: inline-block;
  position: relative;
  overflow: hidden;
  padding: 8px;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.borderRadius};
  vertical-align: top;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;

  h3 {
    color: ${({ theme }) => theme.colors.primary};
    ${getFont(0)};
  }

  span {
    color: ${({ theme }) => theme.colors.fontLL};
    ${getFont('-1', 'compact')};
  }
`;

export const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  position: relative;
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 0.12), 0 2px 2px 0 rgb(0 0 0 / 0.12);
  margin-bottom: 24px;
  background: ${({ theme }) => theme.colors.terciaryLL};
  text-decoration: none;
  transition: all 0.15s ease-in;

  ${maxMediaQueries.tablet`
    grid-template-columns: 1fr 1fr 1fr;
  `}

  ${maxMediaQueries.phablet`
    grid-template-columns: 1fr 1fr;
  `}
`;
