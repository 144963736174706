// @flow
import React from 'react';
import { Inner, Icon } from '../partials';
import type { StrategiesType } from './data';
import { strategies } from './data';

type Props = {
  strategy: StrategiesType,
  callbackUrl?: string
};

/**
 * Botón de login
 *
 * @param {string} strategy Estrategia de login
 */
export const LoginButton = ({ strategy, callbackUrl }: Props) => {
  const strategyData = strategies[strategy];

  return (
    <Inner as="a" href={`${strategyData.link}${callbackUrl ? `?next=${callbackUrl}` : ''}`} rel="nofollow">
      <Icon>{strategyData.icon}</Icon>
      {strategyData.longLiteral}
    </Inner>
  );
};
