// @flow
import React from 'react';
import { Jumbo } from '@finect/tabular-components/Jumbo';
import { Section } from '@finect/tabular-components/Section';
import JumboAdvisersImg from './../../../assets/jumbo-advisers.jpg';

export const AdvisersStorefrontJumbo = () => (
  <Section bottomMargin>
    <Jumbo
      id="advisers"
      contentPosition="left"
      textAlign="left"
      color="#fff"
      title="Asesórate"
      subtitle="Ponte en manos de un asesor financiero para conseguir tus objetivos vitales."
      cta="Buscar asesor"
      coverImage={JumboAdvisersImg}
      link="/asesores"
    />
  </Section>
);
