// @flow
import React from 'react';
import { Pill } from '@finect/tabular-components/Pills';

export const LeadPill = () => (
  <Pill
    height={600}
    growOnResize={false}
    pill="https://www.finect.com/pills/es_ES/finect/default/advisers-leads/steps/advisoryObjective"
  />
);
