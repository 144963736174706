// @flow
import type { Dispatch } from 'react-redux';
import { finectAPI } from '@finect/front-utils/finectAPI';
import { BANKS_HIGHLIGHTS_TAG_ID } from '../../configs';
import {
  BANKS_SET_ORGANIZATION,
  BANKS_SET_ORGANIZATION_FETCHING_STATUS,
  BANKS_SET_ARTICLE,
  BANKS_SET_ARTICLE_FETCHING_STATUS,
  BANKS_SET_QUESTIONS,
  BANKS_SET_QUESTIONS_FETCHING_STATUS,
  BANKS_SET_MANAGEMENT_COMPANIES,
  BANKS_SET_MANAGEMENT_COMPANIES_FETCHING_STATUS,
  BANKS_CLEAR,
  BANKS_SET_PAGE,
  BANKS_SET_PAGE_FETCHING_STATUS
} from '../constants';

import { getOrganizationBankWithCMS } from '../cms/utils';
import { getBanks } from '../content/actions';

import type { BanksGetQuestionsActionType, BanksGetManagementCompaniesActionType } from './types';

function setPage(page: Object) {
  return {
    type: BANKS_SET_PAGE,
    page
  };
}

// eslint-disable-next-line no-unused-vars
function setPageFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: BANKS_SET_PAGE_FETCHING_STATUS,
    fetching,
    fetched
  };
}

export function setBankOrganization(organization: Object) {
  return {
    type: BANKS_SET_ORGANIZATION,
    organization
  };
}

export function setBankOrganizationFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: BANKS_SET_ORGANIZATION_FETCHING_STATUS,
    fetching,
    fetched
  };
}

export function fetchBankOrganization(alias: string, force: boolean = true) {
  return (dispatch: Dispatch, getState: Function) => {
    const fetched = getState().banks.organization.fetched;

    return force || !fetched
      ? Promise.all([dispatch(setBankOrganizationFetchingStatus(true, true))]).then(() =>
        finectAPI({
          version: 'v4',
          path: `contents/profiles/${alias}`,
          method: 'GET',
          onSuccess: ({ data }) => dispatch(setBankOrganization(data)),
          onError: () => dispatch(setBankOrganizationFetchingStatus(false, true))
        }))
      : Promise.all([]);
  };
}

// Article
export function setBankArticle(article: Object) {
  return {
    type: BANKS_SET_ARTICLE,
    article
  };
}

export function setBankArticleFetchingStatus(fetching: boolean, fetched: boolean) {
  return {
    type: BANKS_SET_ARTICLE_FETCHING_STATUS,
    fetching,
    fetched
  };
}

export function fetchBankArticle(cookie: string, alias: string, force: boolean = true) {
  return (dispatch: Dispatch, getState: Function) => {
    const fetched = getState().banks.article.fetched;

    if (force || !fetched) {
      return Promise.all([dispatch(setBankArticleFetchingStatus(true, true))]).then(() =>
        finectAPI({
          credentials: cookie,
          version: 'v4',
          path: `contents/pages/${alias}`,
          method: 'GET',
          onSuccess: ({ data }) => dispatch(setBankArticle(data)),
          onError: () => dispatch(setBankArticleFetchingStatus(false, true))
        }));
    }

    return Promise.all([]);
  };
}

// Questions
export function setBankQuestions(request: BanksGetQuestionsActionType, questions: Array<Object>) {
  return {
    type: BANKS_SET_QUESTIONS,
    request,
    questions
  };
}

export function fetchBankQuestionsFetchingStatus(
  request: BanksGetQuestionsActionType,
  fetching: boolean,
  fetched: boolean
) {
  return {
    type: BANKS_SET_QUESTIONS_FETCHING_STATUS,
    request,
    fetching,
    fetched
  };
}

export function fetchBankQuestions(request: BanksGetQuestionsActionType, force: boolean = true) {
  const { organization, limit = 5 } = request;

  const requestId: BanksGetQuestionsActionType = { ...request, ...{ organization, limit } };

  return (dispatch: Dispatch, getState: Function) => {
    const fetched = getState().banks.questions.fetched;

    return force || !fetched
      ? Promise.all([dispatch(fetchBankQuestionsFetchingStatus(requestId, true, true))]).then(() =>
        finectAPI({
          version: 'v3',
          path: `questions?group=${organization}&limit=${limit}`,
          method: 'GET',
          onSuccess: ({ list }: { list: Object }) => Promise.all([dispatch(setBankQuestions(requestId, list))])
        }))
      : Promise.all([]);
  };
}

// Management Companies
export function setBankManagementCompanies(
  request: BanksGetManagementCompaniesActionType,
  managementCompanies: Array<Object>
) {
  return {
    type: BANKS_SET_MANAGEMENT_COMPANIES,
    request,
    managementCompanies
  };
}

export function fetchBankManagementCompaniesFetchingStatus(
  request: BanksGetManagementCompaniesActionType,
  fetching: boolean,
  fetched: boolean
) {
  return {
    type: BANKS_SET_MANAGEMENT_COMPANIES_FETCHING_STATUS,
    request,
    fetching,
    fetched
  };
}

export function clearBank() {
  return {
    type: BANKS_CLEAR
  };
}

export function clearBankScenes() {
  return function (dispatch: Dispatch) {
    return Promise.all([dispatch(clearBank())]);
  };
}

export function fetchBankManagementCompanies(request: BanksGetManagementCompaniesActionType, force: boolean = true) {
  const { organization, limit } = request;

  return (dispatch: Dispatch, getState: Function) => {
    const fetched = getState().banks.managementCompanies.fetched;

    if (force || !fetched) {
      return Promise.all([dispatch(fetchBankManagementCompaniesFetchingStatus(request, true, true))]).then(() =>
        finectAPI({
          version: 'v4',
          path: `contents/distributors/${organization}/managementcompanies?limit=${limit}`,
          method: 'GET',
          onSuccess: ({ data }: { data: Object }) => Promise.all([dispatch(setBankManagementCompanies(request, data))])
        }));
    }

    return Promise.all([]);
  };
}

function fetchBankWithCMS(cookies, organizationAlias) {
  return function (dispatch: Dispatch) {
    return getOrganizationBankWithCMS(cookies, organizationAlias)
      .then(({ page, organization }) => {
        const promises = [dispatch(setPage(page)), dispatch(setBankOrganization(organization))];

        return Promise.all(promises);
      })
      .catch(() => Promise.all([dispatch(setPage(null)), dispatch(setBankOrganization(null))]));
  };
}

// SSR - loadInitialData
export function fetchBankArticleScene(cookies: string, organization: string, article: string) {
  return function (dispatch: Dispatch) {
    return Promise.all([
      dispatch(fetchBankWithCMS(cookies, organization)),
      dispatch(fetchBankArticle(cookies, article, true))
    ]);
  };
}

export function fetchBankHomeScene(
  cookies: string,
  force: boolean,
  organization: string,
  questionsRequest: BanksGetQuestionsActionType
) {
  return function (dispatch: Dispatch) {
    return Promise.all([
      dispatch(fetchBankWithCMS(cookies, organization)),
      dispatch(getBanks(BANKS_HIGHLIGHTS_TAG_ID)),
      dispatch(fetchBankQuestions(questionsRequest, true)),
      dispatch(fetchBankManagementCompanies({ organization, limit: 50 }))
    ]);
  };
}

export function fetchBankProductsScene(cookies: string, organization: string) {
  return function (dispatch: Dispatch) {
    Promise.resolve(dispatch(fetchBankWithCMS(cookies, organization)));
  };
}
