// @flow
import { base64Encode } from '@finect/front-utils/base64';

export function profile(
  state: Object = {
    profile: {
      model: null,
      fetching: false,
      fetched: false,
      adviser: {
        locations: [],
        specialitations: [],
        savings: []
      }
    },
    articles: {
      items: [],
      fetching: false,
      fetched: false,
      paging: {
        links: [],
        totalItems: 0,
        limit: 10
      }
    },
    reviews: {
      items: [],
      fetching: false,
      fetched: false
    },
    answers: {
      items: [],
      fetching: false,
      fetched: false
    },

    // todo: revisar y eliminar
    page: {
      fetching: false,
      fetched: false
    }
  },
  action: Object
) {
  switch (action.type) {
    case 'SET_PROFILE_FETCHING_STATUS': {
      const newState = { ...state };
      newState.profile = {
        ...state.profile,
        fetching: action.fetching,
        fetched: action.fetched
      };
      return { ...state, ...newState };
    }
    case 'SET_PROFILE': {
      const newState = { ...state };
      newState.profile = {
        model: action.profile,
        fetching: false,
        fetched: true
      };
      return { ...state, ...newState };
    }

    case 'SET_ARTICLES_PROFILE_FETCHING_STATUS': {
      const newState = { ...state };
      newState.articles = {
        ...state.articles,
        fetching: action.fetching
      };
      return { ...state, ...newState };
    }

    case 'SET_ARTICLES_PROFILE': {
      const newState = { ...state };
      newState.articles = {
        ...state.articles,
        items: [
          ...action.articles.map(item => ({
            ...item,
            body: {
              ...item.body,
              text: base64Encode(item.body.text)
            }
          }))
        ],
        fetching: false,
        fetched: true,
        paging: action.paging
      };
      return { ...state, ...newState };
    }

    case 'SET_REVIEWS_PROFILE_FETCHING_STATUS': {
      const newState = { ...state };
      newState.reviews = {
        ...state.reviews,
        fetching: action.fetching
      };
      return { ...state, ...newState };
    }

    case 'SET_REVIEWS_PROFILE': {
      const newState = { ...state };
      newState.reviews = {
        ...state.reviews,
        items: [...action.reviews],
        fetching: false,
        fetched: true
      };
      return { ...state, ...newState };
    }

    case 'SET_ANSWERS_PROFILE_FETCHING_STATUS': {
      const newState = { ...state };
      newState.answers = {
        ...state.answers,
        fetching: action.fetching
      };
      return { ...state, ...newState };
    }

    case 'SET_ANSWERS_PROFILE': {
      const newState = { ...state };
      newState.answers = {
        ...state.answers,
        items: [...action.answers],
        fetching: false,
        fetched: true
      };
      return { ...state, ...newState };
    }

    case 'SET_PAGE': {
      const newState = { ...state };
      newState.page = {
        ...state.page,
        ...action.page
      };
      return { ...state, ...newState };
    }

    case 'SET_PAGE_FETCHING_STATUS': {
      const newState = { ...state };
      newState.page = {
        ...state.page,
        fetched: action.fetched,
        fetching: action.fetching
      };
      return { ...state, ...newState };
    }

    case 'CLEAR_PAGE': {
      const newState = { ...state };
      newState.page = {
        fetching: false,
        fetched: false
      };
      return { ...state, ...newState };
    }

    case 'SET_PRODUCTS_GROUP_HIGHLIGHTED': {
      const newState = { ...state };

      newState.page = {
        ...state.page,
        productsGroupHighlighted: action.products
      };

      return { ...state, ...newState };
    }

    case 'CLEAR_PROFILE': {
      const newState = { ...state };
      newState.page = null;
      newState.profile = {
        model: null,
        fetched: false,
        fetching: false
      };

      return { ...state, ...newState };
    }

    default:
      return state;
  }
}
