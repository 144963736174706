// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '@finect/tabular-components/Modal';
import { Button } from '@finect/tabular-core/Button';
import { Flex } from '@finect/tabular-core/Flex';
import { isPreRelease } from '@finect/front-utils/isPreRelease';
import { connect } from 'react-redux';
import { ChevronRight } from '@finect/tabular-icons/chevronRight';
import { Box } from '../Box';
import { FeedNavigation, FeedItem, Title, MenuSection } from './partials';

type Props = {
  currentSelection: string,
  tags: Array<Object>,
  user: Object,
  withPublish?: boolean | null,
  onlyContents?: boolean | null,
  omninav: {
    state: 'opened' | 'closed'
  }
};

type State = {
  showBox: boolean
};

export type LinkPropsType = {
  id?: string,
  text: string,
  href: string,
  type?: 'client',
  title?: string,
  props?: Object,
  preRelease?: boolean,
  chevronRight?: boolean
};

export type ItemsType = {
  sections: Array<{
    title?: string,
    core: boolean,
    contents?: boolean,
    links: Array<LinkPropsType>
  }>
};

const getItems = ({ tags = [] }: { tags: Array<Object> }): ItemsType => {
  const tagsItems = tags.length
    ? [
      {
        title: 'Siguiendo',
        core: false,
        links: tags.map(tag => ({
          href: `/temas/${tag.entity.alias}`,
          text: tag.entity.name
        }))
      }
    ]
    : [];

  const items: ItemsType = {
    sections: [
      {
        core: true,
        links: [
          {
            id: 'dashboard',
            href: '/tus-finanzas',
            text: 'Tus finanzas',
            type: 'client'
          }
        ]
      },
      {
        core: true,
        links: [
          {
            id: 'favorites',
            href: '/tus-favoritos',
            text: 'Tus favoritos',
            type: 'client'
          }
        ]
      },
      {
        core: true,
        links: [
          {
            id: 'tus-temas',
            href: '/tus-temas',
            text: 'Tus temas',
            type: 'client'
          }
        ]
      },
      {
        core: true,
        links: [
          {
            id: 'comparisons',
            href: '/tus-comparaciones',
            text: 'Tus comparaciones',
            type: 'client',
            preRelease: true
          },
          {
            id: 'authorizations',
            href: '/tus-autorizaciones',
            text: 'Tus autorizaciones',
            type: 'client'
          }
        ]
      },
      // TODO: NO SE ESTÁN MOSTRANDO - se van a migrar a React
      // {
      //   title: 'Contenidos',
      //   core: true,
      //   links: [
      //     // {
      //     //   id: 'topics',
      //     //   href: '/tus-temas',
      //     //   text: 'Tus temas',
      //     //   type: 'client'
      //     // },
      //     // {
      //     //   id: 'favoritos',
      //     //   href: '/articulos-financieros/mis-favoritos',
      //     //   text: 'Tus favoritos'
      //     // },
      //     // {
      //     //   id: 'comentados',
      //     //   href: '/articulos-financieros/mis-comentados',
      //     //   text: 'Tus comentados'
      //     // },
      //     // {
      //     //   id: 'questions',
      //     //   href: '/preguntas/mis-preguntas',
      //     //   text: 'Tus preguntas'
      //     // }
      //   ]
      // }
      {
        title: 'Información',
        core: true,
        contents: true,
        links: [
          {
            id: 'en-un-minuto',
            href: '/articulos-financieros/en-un-minuto',
            text: 'En un minuto',
            type: 'client'
          }
        ]
      },
      {
        title: 'Análisis',
        core: true,
        contents: true,
        links: [
          {
            id: 'a-fondo',
            href: '/articulos-financieros/a-fondo',
            text: 'A fondo',
            type: 'client'
          },
          {
            id: 'videos',
            href: '/videos',
            text: 'Vídeos',
            type: 'client'
          },
          {
            id: 'podcasts',
            href: '/podcasts',
            text: 'Podcasts',
            type: 'client'
          },
          {
            id: 'reviews',
            href: '/asesores/reviews',
            text: '[BETA] Reviews',
            type: 'client',
            props: {
              rel: 'nofollow'
            },
            preRelease: true
          }
        ]
      },
      {
        core: true,
        contents: true,
        links: [
          {
            id: 'noticias',
            href: '/articulos-financieros/noticias',
            text: 'Ver todos',
            type: 'client',
            chevronRight: true
          }
        ]
      }
    ]
  };

  return {
    sections: [...items.sections, ...tagsItems]
  };
};

const mapStateToProps = state => ({
  omninav: state.omninav
});

class DashboardMenu extends React.Component<Props, State> {
  state = {
    showBox: false
  };

  handleClose = () => {
    this.setState({ showBox: false });
  };

  render() {
    const { tags, omninav } = this.props;
    const items = getItems({ tags });

    return (
      <>
        <Modal show={this.state.showBox} heightSize="auto" onClose={this.handleClose}>
          <Box user={this.props.user} onClose={this.handleClose} />
        </Modal>

        <FeedNavigation omninavState={omninav.state}>
          <div>
            {items.sections
              .filter(item => (this.props.onlyContents === true ? item.contents : !item.contents))
              .map((item, index) => (
                <MenuSection key={index} isCore={item.core}>
                  {item.title ? <Title>{item.title}</Title> : null}

                  <ul>
                    {item.links
                      .filter(({ preRelease = false }) => (preRelease && isPreRelease) || !preRelease)
                      .map((link, linkIndex) => (
                        <FeedItem key={linkIndex} selected={this.props.currentSelection === link?.id}>
                          {link?.type === 'client' ? (
                            <Link to={link.href}>
                              {link.chevronRight ? (
                                <Flex align="center">
                                  {link.text}
                                  <ChevronRight size="18px" />
                                </Flex>
                              ) :
                                link.text
                              }
                            </Link>
                          ) : (
                            <a href={link.href} {...link.props}>
                              {link.chevronRight ? (
                                <Flex align="center">
                                  {link.text}
                                  <ChevronRight size="18px" />
                                </Flex>
                              ) :
                                link.text
                              }
                            </a>
                          )}
                        </FeedItem>
                      ))}
                  </ul>
                </MenuSection>
              ))}
          </div>

          {this.props.withPublish === false ? null : (
            <Button
              secondary
              small={true}
              variant="small"
              onClick={() => {
                this.setState({ showBox: true });
              }}
              width="120px"
            >
              Publicar
            </Button>
          )}
        </FeedNavigation>
      </>
    );
  }
}

export default connect(mapStateToProps, null)(DashboardMenu);
