// @flow
import React from 'react';
import { AppLink } from '@finect/tabular-core/AppLink';
import { Icon } from '@finect/tabular-components/Icon';
import { CardAvatar, Inner } from './partials';

type Props = {
  contentPiece: Object
};

export const Person = ({ contentPiece }: Props) => {
  const persons = contentPiece.model;

  if (!persons || !persons.length) return null;

  return (
    <Inner>
      {persons
        .filter(item => item.subtype !== 'company')
        .map(person => (
          <CardAvatar
            {...(person.features.includes('profile') ? { as: AppLink, href: person.web } : {})}
            key={person.id}
          >
            <Icon
              size={4}
              alt={person.displayName}
              image={person.icon ? person.icon : null}
              withBorder
              withRoundedCorners
              margin="0 auto"
            />
            <h3>{person.displayName}</h3>
            <span>{`(@${person.alias})`}</span>
          </CardAvatar>
        ))}
    </Inner>
  );
};
