// @flow
import React from 'react';
import { Section } from '@finect/tabular-components/Section';
import { ContentProductsCarousel, ContentProductsGrid } from '@finect/tabular-components/Products';
import { BankProductsTable } from '../BankProductsTable';

type CardsVariantsType = 'large' | 'medium' | 'cta';
type ViewModeType = 'carousel' | 'grid' | 'table';

type Props = {
  productType: Object,
  products: Array<Object>,
  onProductRequest: Function,
  link?: Object | null,
  organization: Object,
  subscriptions: Array<string>,
  view: ViewModeType,
  variants?: {
    carousel?: CardsVariantsType,
    grid?: CardsVariantsType
  },
  onFollow?: Function,
  size: string,
  title?: string,
  withTitle?: boolean,
  context: Array<Object>
};

const defaultCardsVariants = {
  carousel: 'medium',
  grid: 'medium'
};

type State = {
  order: {
    type: 'asc' | 'desc' | 'none',
    field: string | null,
    ordering: 'parent' | 'child'
  } | null
};

export class BankProductsByType extends React.Component<Props, State> {
  handleFollow = (product: string, status: boolean) => {
    this.props.onFollow && this.props.onFollow(product, status);
  };

  handleOrderChange = (order: Object) => {
    this.setState({ order });
  };

  static defaultProps = {
    size: 'small'
  };

  state = {
    order: {
      type: 'none',
      field: null,
      ordering: 'child'
    }
  };

  render() {
    const {
      productType,
      products,
      view,
      subscriptions = [],
      size,
      link,
      title = productType ? productType.plural : '',
      withTitle = true,
      onProductRequest,
      context,
      organization
    } = this.props;

    const { order } = this.state;

    const variants = { ...defaultCardsVariants, ...this.props.variants || {} };

    const distributorsWhiteList = organization.facts.includes('banks-exclusive-distributor') ? [organization.id] : [];

    const aditionalProps = {
      subscriptions,
      onFollow: this.props.onFollow ? this.handleFollow : null,
      context,
      distributorsWhiteList
      // onAction: onProductRequest
    };

    const sectionProps = link ? { link } : {};

    return (
      <Section
        id={productType ? productType.slug : ''}
        title={withTitle ? title : ''}
        bottomMargin
        size={size}
        {...sectionProps}
      >
        {view === 'table' ? (
          <BankProductsTable
            productType={productType}
            distributorsWhiteList={distributorsWhiteList}
            products={products}
            onOrderChange={this.handleOrderChange}
            order={order}
            onProductRequest={onProductRequest}
            context={context}
          />
        ) : null}
        {view === 'carousel' ? (
          <ContentProductsCarousel withSPA variant={variants.carousel} products={products} {...aditionalProps} />
        ) : null}

        {view === 'grid' ? (
          <ContentProductsGrid
            withSPA
            products={products}
            variant={variants.grid}
            justify="center"
            fixedWidth="310px"
            {...aditionalProps}
          />
        ) : null}
      </Section>
    );
  }
}
