// @flow
import React from 'react';
import { PIECE_COMPONENTS } from '../../../scenes/Profiles/Group/components/pieces';
import { Inner } from './partials';

export const ContentPiece = ({ contentPiece }: Object) => {
  const definition = PIECE_COMPONENTS.find(item => item.id === contentPiece.type);

  const { component: Component } = definition || {};

  if (!Component) {
    return null;
  }

  return <Component {...contentPiece} />;
};

type Props = {
  contentPieces: Array<Object>,
  bottomMargin?: boolean
};

export const ContentPieces = ({ contentPieces, bottomMargin = false }: Props) => {
  if (!contentPieces.length) {
    return null;
  }

  return (
    <Inner bottomMargin={bottomMargin}>
      {contentPieces.map((contentPiece, key) => (
        <ContentPiece key={key} contentPiece={contentPiece} />
      ))}
    </Inner>
  );
};
