// @flow
import {
  FreeText,
  DefaultComponent,
  Courses,
  TableProducts,
  Product,
  NewsFeed,
  Post,
  Downloads,
  Image,
  Person,
  LeadPill
} from './PiecesComponents';

export const PIECE_COMPONENTS: Array<Object> = [
  {
    id: 'FREE_TEXT',
    component: FreeText
  },
  {
    id: 'NEWSFEED',
    component: NewsFeed
  },
  {
    id: 'VIDEO',
    component: FreeText
  },
  {
    id: 'IMAGE',
    component: Image
  },
  {
    id: 'TABLE',
    component: TableProducts
  },
  {
    id: 'DOWNLOAD',
    component: Downloads
  },
  {
    id: 'DISCLAIMER',
    component: FreeText
  },
  {
    id: 'CHART',
    component: Product
  },
  {
    id: 'POST',
    component: Post
  },
  {
    id: 'PERSON',
    component: Person
  },
  {
    id: 'AUDIO',
    component: FreeText
  },
  {
    id: 'ADVISERS',
    component: Person
  },
  {
    id: 'LEADPILL',
    component: LeadPill
  },

  // To deprecate
  {
    id: 'ACCOUNTS',
    component: DefaultComponent
  },
  // not implemented
  {
    id: 'LOGIN',
    component: DefaultComponent
  },
  {
    id: 'COURSES',
    component: Courses
  }
];
