// @flow
import React from 'react';
import { ContentListCard } from '../../../../../../components/ContentListCard';

type Props = {
  contentPiece: Object
};

export const Post = ({ contentPiece }: Props) => {
  if (!contentPiece.model) return null;

  return (
    <ContentListCard
      type={'article'}
      entity={contentPiece.model}
      userSubscriptions={[]}
      userLikes={[]}
      withAuthor={false}
    />
  );
};
