// @flow
import React from 'react';
import { Provider } from 'react-redux';
import ScrollToTop from '@finect/front-core/ScrollToTop';
import { AppWithRouter } from './App';
import AppRouter from './AppRouter';
import { OmniNavListener } from './components';

type Props = {
  initializedStore: Object
};

const BrowserApp = ({ initializedStore }: Props) => (
  <Provider store={initializedStore}>
    <AppWithRouter>
      <ScrollToTop />
      <OmniNavListener />
      <AppRouter />
    </AppWithRouter>
  </Provider>
);

export default BrowserApp;
