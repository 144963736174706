// @flow
import { getProductTypeById } from '@finect/front-resources/Products';

export const MAX_PRODUCTS = 5;

export function comparison(
  state: Object = {
    analysis: '',
    analysisError: null,
    loadingAnalysis: false,
    products: [],
    funds: [],
    dialog: {
      open: false,
      index: 0
    },
    comparisonsDialog: {
      open: false
    },
    userComparisons: {
      models: [],
      fetching: false,
      fetched: false
    },
    drawerComparison: {
      selectedProducts: [],
      productsType: null
    }
  },
  action: Object
) {
  switch (action.type) {
    case 'COMPARISON_ADD_FUND': {
      return {
        ...state,
        funds: [...state.funds, action.fund]
      };
    }

    case 'COMPARISON_SET_FUNDS': {
      return {
        ...state,
        funds: [...action.funds]
      };
    }

    case 'COMPARISON_CLEAR_FUNDS': {
      const newState = { ...state };
      newState.funds = [];
      return { ...state, ...newState };
    }

    case 'COMPARISON_OPEN_DIALOG': {
      const newState = { ...state };
      newState.dialog.open = true;
      newState.dialog.index = 2;
      return { ...state, ...newState };
    }

    case 'COMPARISON_CLOSE_DIALOG': {
      const newState = { ...state };
      newState.dialog.open = false;

      return { ...state, ...newState };
    }

    case 'COMPARISON_REMOVE_PRODUCT': {
      const newState = { ...state };
      newState.funds = state.funds.filter(product => product.id !== action.product.id);

      return { ...state, ...newState };
    }

    case 'COMPARISON_SET_USER_COMPARISONS_FETCHING_STATUS': {
      const userComparisons = { ...state.userComparisons };

      userComparisons.fetching = Boolean(action.fetching);
      userComparisons.fetched = Boolean(action.fetched);

      return {
        ...state,
        ...{
          userComparisons
        }
      };
    }

    case 'COMPARISON_SET_USER_COMPARISONS': {
      const newUserComparisons = { ...state.userComparisons };

      newUserComparisons.models = [...action.sessions];
      newUserComparisons.fetching = false;
      newUserComparisons.fetched = true;

      return {
        ...state,
        ...{
          userComparisons: newUserComparisons
        }
      };
    }

    case 'COMPARISON_SET_USER_COMPARISONS_DIALOG': {
      const comparisonsDialog = { ...state.comparisonsDialog };

      comparisonsDialog.open = action.open;

      return {
        ...state,
        ...{
          comparisonsDialog
        }
      };
    }

    // Products

    case 'COMPARISON_SET_PRODUCTS': {
      return {
        ...state,
        products: [...action.products]
      };
    }

    // Set drawer products comparison

    case 'DRAWER_COMPARISON_SET_PRODUCT': {
      const newDrawerComparison = state.drawerComparison;

      const newSelectedComparison = newDrawerComparison.selectedProducts.find(
        item => item.entity.id === action.product.entity.id
      )
        ? newDrawerComparison.selectedProducts.filter(item => item.entity.id !== action.product.entity.id)
        : [...newDrawerComparison.selectedProducts, ...[action.product]];

      const newProductsTypeComparison = newSelectedComparison.length
        ? getProductTypeById(action.product.subtype)
        : null;

      if (!(newSelectedComparison.length <= MAX_PRODUCTS)) {
        return {
          ...state
        };
      }

      return {
        ...state,
        drawerComparison: {
          selectedProducts: newSelectedComparison,
          productsType: newProductsTypeComparison
        }
      };
    }

    case 'DRAWER_COMPARISON_SET_EMPTY': {
      return {
        ...state,
        drawerComparison: {
          selectedProducts: [],
          productsType: null
        }
      };
    }

    case 'SET_ANALYSIS': {
      const newState = { ...state };
      newState.analysis = action.analysis;
      newState.analysisError = null;
      return { ...state, ...newState };
    }

    case 'SET_ANALYSIS_ERROR': {
      const newState = { ...state };
      newState.analysisError = action.analysisError;
      return { ...state, ...newState };
    }

    case 'SET_LOADING_ANALYSIS': {
      const newState = { ...state };
      newState.loadingAnalysis = action.loadingAnalysis;
      if (action.loadingAnalysis) {
        newState.analysisError = null;
      }
      return { ...state, ...newState };
    }

    default:
      return state;
  }
}
