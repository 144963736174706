export function products(
  state = {
    product: {
      model: null,
      fetching: false,
      fetched: false
    },

    productPage: {
      fetching: false,
      fetched: false
    },

    pages: {
      fetching: false,
      fetched: false,
      items: []
    },
    products: {},
    storeFrontArticles: [],
    storeFrontQuestions: [],
    organizations: {
      managers: [],
      distributors: []
    },
    productRequest: {
      product: null,
      commercialRelationship: null,
      tag: null
    },
    storefronts: [],
    generics: []
  },
  action
) {
  switch (action.type) {
    case 'SET_PRODUCT_FETCHING_STATUS': {
      return {
        ...state,
        ...{
          product: {
            ...state.product,
            ...{
              fetching: action.fetching,
              fetched: action.fetched
            }
          }
        }
      };
    }

    case 'SET_PRODUCT': {
      const newState = { ...state };
      newState.product = {
        model: action.product,
        fetching: false,
        fetched: true
      };

      return { ...state, ...newState };
    }

    case 'CLEAR_PRODUCT': {
      const newState = { ...state };
      newState.product = {};

      return { ...state, ...newState };
    }

    case 'SET_PRODUCT_PAGES': {
      const newState = { ...state };
      newState.pages = {
        fetching: false,
        fetched: true,
        items: action.pages
      };

      return { ...state, ...newState };
    }

    case 'SET_PRODUCT_PAGE': {
      const newState = { ...state };
      newState.productPage = {
        ...action.page,
        fetching: false,
        fetched: true
      };

      return { ...state, ...newState };
    }

    case 'SET_PRODUCT_PAGE_FETCHING_STATUS': {
      const newState = { ...state };
      newState.productPage = {
        fetching: action.fetching,
        fetched: action.fetched
      };

      return { ...state, ...newState };
    }

    case 'CLEAR_PRODUCT_PAGE': {
      const newState = { ...state };
      newState.productPage = {
        fetching: false,
        fetched: false
      };

      return { ...state, ...newState };
    }

    case 'SET_PRODUCTS_FETCHING_STATUS': {
      const newState = { ...state };

      if (!newState.products[action.id]) {
        newState.products[action.id] = {
          page: 1,
          items: [],
          paging: {},
          request: {},
          fetching: false,
          fetched: false
        };
      }

      const data = { ...newState.products[action.id] };
      data.fetching = action.fetching;
      data.fetched = action.fetched;
      const newObject = {
        [action.id]: data
      };

      newState.products = { ...newState.products, ...newObject };
      return { ...state, ...newState };
    }

    case 'SET_PRODUCTS': {
      const newState = { ...state };

      if (!newState.products[action.id]) {
        newState.products[action.id] = {
          page: 1,
          items: [],
          paging: {},
          request: {},
          fetching: false,
          fetched: false
        };
      }

      const data = { ...newState.products[action.id] };
      data.items = [...data.items, ...action.items];
      data.paging = action.paging;
      data.fetching = false;
      data.fetched = true;

      const newObject = {
        [action.id]: data
      };
      newState.products = { ...newState.products, ...newObject };

      return { ...state, ...newState };
    }

    case 'SET_STOREFRONT_ARTICLES': {
      const newState = { ...state };
      newState.storeFrontArticles = [...action.articles];
      return { ...state, ...newState };
    }
    case 'SET_STOREFRONT_QUESTIONS': {
      const newState = { ...state };
      newState.storeFrontQuestions = action.questions;
      return { ...state, ...newState };
    }
    case 'SET_ORGANIZATIONS': {
      const newState = { ...state };
      newState.organizations[action.organizationsType] = action.organizations;
      return { ...state, ...newState };
    }

    case 'SET_PRODUCT_REQUEST': {
      const newState = { ...state };
      newState.productRequest = action.params;
      return { ...state, ...newState };
    }

    case 'SET_STOREFRONTS': {
      return { ...state, storefronts: action.storefronts };
    }

    case 'SET_GENERIC_PRODUCTS': {
      const newState = { ...state };
      newState.generics = action.products;

      return { ...state, ...newState };
    }

    default:
      return state;
  }
}
