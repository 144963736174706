// @flow

/**
 * Sirve para identificar todos los bancos. Se usa en:
 *  - Página de Onboarding
 */
export const BANKS_TAG_ID = 852;

/**
 * Bancos destacados. Se usa en:
 *  - omninav
 *  - Bancos destacados en la home (Invierte con tu banco)
 */
export const BANKS_HIGHLIGHTS_TAG_ID = 34491;
