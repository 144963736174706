// @flow
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { usePrevious } from '@finect/front-core/hooks';
import { clearBankScenes } from '../../../../store/banks/actions';

export const BanksSPA = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const organization = match.params.organization;

  const prevOrganization = usePrevious(organization);

  // navigation from a bank to another bank
  useEffect(() => {
    if (prevOrganization && prevOrganization !== organization) {
      dispatch(clearBankScenes());
    }
  }, [organization]);

  // unmount
  useEffect(
    () => () => {
      dispatch(clearBankScenes());
    },
    []
  );

  return null;
};
