// @flow
// $FlowFixMe
import React, { Fragment } from 'react';
import { Share } from '@finect/tabular-components/Share';
import { MessageCircle } from '@finect/tabular-icons/messageCircle';
import { Bookmark, Bookmarked } from '@finect/tabular-icons/bookmark';
import { Heart } from '@finect/tabular-icons/heart';
import { CornerUpLeft } from '@finect/tabular-icons/cornerUpLeft';
import { Share as ShareIcon } from '@finect/tabular-icons/share';
import { Action } from './Action';
import { Count } from './Count';
import { getTitleShare } from './../../../../utils/articles';

type Props = {
  inline: boolean,
  postId?: string,
  title: string,
  url: string,
  type: string,
  liked?: boolean,
  likes: number,
  solved?: boolean,
  saved?: boolean,
  onLike?: Function,
  onSave?: Function,
  answers?: number,
  comments?: number
};

export const ToolbarItems = ({
  inline,
  postId,
  type,
  title,
  url,
  liked,
  likes,
  solved,
  saved,
  onLike,
  onSave,
  answers,
  comments
}: Props) => {
  const handleLike = () => {
    if (onLike) {
      onLike(postId);
    }
    return null;
  };

  const handleSave = () => {
    if (onSave) {
      onSave(postId);
    }
    return null;
  };
  return (
    <Fragment>
      {onLike ? (
        <Action inline={inline} color="secondary" done={liked} onClick={handleLike}>
          <Count>{likes}</Count>
          <Heart />
          <span>Me gusta</span>
        </Action>
      ) : null}
      {type === 'question' ? (
        <Action inline={inline} color="primary" solved={solved} as="a" href={`${url}#responder`}>
          <Count solved={solved}>{answers}</Count>
          <CornerUpLeft style={{ position: 'relative', top: '-1px' }} />
          <span>Responder</span>
        </Action>
      ) :
        type === 'article' && (
          <Action inline={inline} color="primary" as="a" href={`${url}#comentar`}>
            {comments ? <Count>{comments}</Count> : null}
            <MessageCircle />
            <span>Comentar</span>
          </Action>
        )
      }
      {onSave ? (
        <Action inline={inline} done={saved} onClick={handleSave}>
          {saved ? <Bookmarked /> : <Bookmark />}
          <span>{saved ? 'Guardado' : 'Guardar'}</span>
        </Action>
      ) : null}
      <Share
        title={getTitleShare(title)}
        url={url.includes('https://') ? url : `https://www.finect.com${url}`}
        via="Finect"
        positionX="top"
        arrowPosition={4}
      >
        <Action inline={inline}>
          <ShareIcon style={{ position: 'relative', top: '-1px' }} />
          <span style={{ verticalAlign: 'super' }}>Compartir</span>
        </Action>
      </Share>
    </Fragment>
  );
};
