// @flow
const domain = 'www.finect.com';
const baseURL = `https://${domain}`;

export const getHomeCanonical = (productType: Object) => `${baseURL}/${productType.slug}`;
export const getCompanyCanonical = (productType: Object, company: Object) =>
  `${baseURL}/${productType.slug}/gestoras/${company.id}-${company.alias}`;

export const getManagementCompaniesCanonical = (productType: Object) => `${baseURL}/${productType.slug}/gestoras`;
export const getCategoriesCanonical = (productType: Object, category?: string | null) =>
  `${baseURL}/${productType.slug}/categoria${category ? `/${category}` : ''}`;

export const getStorefrontCanonical = (tag: Object) => `${baseURL}/invertir/${tag.alias}`;

export const getBankCanonical = (bank: Object) => `${baseURL}/bancos/${bank.alias}`;

export const getBankProductCanonical = (bank: Object, productType: Object) =>
  `${baseURL}/bancos/${bank.alias}/productos/${productType.slug}`;
