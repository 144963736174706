// @flow
import styled from 'styled-components';
import { Button } from '@finect/tabular-core/Button';
import { getFont } from '@finect/tabular-utils/getFont';

export const ButtonLined = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.uiD};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.font};

  transition: background-color 0.15s;

  &:not([disabled]):hover {
    background-color: ${({ theme }) => theme.colors.uiL};
    color: ${({ theme }) => theme.colors.terciaryD};
  }

  + button {
    margin-left: 8px;
  }
`;

export const Inner = styled.div`
  margin-bottom: 56px;

  h5 {
    margin-bottom: 8px;
    font-weight: 500;
    ${getFont(1)};
  }
`;
