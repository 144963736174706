// @flow
// $FlowFixMe
import React, { Fragment } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { X } from '@finect/tabular-icons/x';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { grid } from '@finect/tabular-utils/grid';
import { Spinner } from '@finect/tabular-components/Spinner';
import { disableBodyScroll, enableBodyScroll } from '@finect/tabular-utils/bodyScrollLock';

const show = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const animation = css`
  animation: ${show} 0.2s ease;
`;

const OverBkg = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0 0 0 / 0.4);
  ${animation};
`;

const CloseOverlay = styled.div`
  position: absolute;
  z-index: 999;
  top: 42px;
  right: 42px;
  cursor: pointer;

  ${maxMediaQueries.phone`
    top: 15px;
    right: 15px;
  `};
`;

const IframeBox = styled.div`
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  width: ${grid(8)}px;
  max-width: 100%;
  height: 736px;
  max-height: 100%;
  padding: 24px;
  transform: translateX(-50%) translateY(-50%);
  ${animation};

  ${maxMediaQueries.phone`
    width: 100%;
    height: 100%;
    padding: 0;
  `};
`;

const OverIframe = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: #fff;

  ${maxMediaQueries.phone`
    border-radius: 0;
  `};
`;

const Iframe = styled.iframe`
  display: block;
  width: 100%;
  height: 100%;
  ${({ loaded }) => (!loaded ? 'visibility: hidden;' : animation)};
  border: 0;
`;

const Loading = styled.div`
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

type Props = {
  blob: Object,
  closeOverlay: Function,
  processMessage: Function
};

type State = {
  loaded: boolean
};

export class OverBox extends React.Component<Props, State> {
  target: HTMLElement | null;

  state = {
    loaded: false
  };

  componentWillUnmount() {
    enableBodyScroll(this.target);
    window.removeEventListener('message', this.props.processMessage);
  }

  componentDidMount() {
    disableBodyScroll(this.target);
    window.addEventListener('message', this.props.processMessage);
  }

  loaded = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { blob, closeOverlay } = this.props;
    const { loaded } = this.state;
    const actionHash = `https://${process.env.FINECT_BASEURL || 'www.finect.com'}/login?source=iframe&action=${blob}`;

    return (
      <Fragment>
        <OverBkg onClick={closeOverlay} />
        <IframeBox>
          <CloseOverlay onClick={closeOverlay}>
            <X />
          </CloseOverlay>
          {!loaded && (
            <Loading>
              <Spinner />
            </Loading>
          )}
          <OverIframe ref={el => (this.target = el)}>
            <Iframe loaded={loaded} src={actionHash} onLoad={this.loaded} />
          </OverIframe>
        </IframeBox>
      </Fragment>
    );
  }
}
