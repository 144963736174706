// @flow
import { isArray } from '@finect/front-utils/isArray';

// TODO: Its in @finect/tabular-manager/commons, but /commons is not exported
// always without accents
export const ALIAS_KEYWORDS = [
  'portada',
  'articulos',
  'blog',
  'fondos',
  'planes',
  'etfs',
  'eventos',
  'webinars',
  'contacto',
  'novedades',
  'hipotecas',
  'videos',
  'podcasts',
  'podcast',
  'glosario',
  'asesores'
];

export const getPageTabs = (profile: Object, page: Object) => {
  const tabs =
    (page &&
      page.tabs
        .filter(tab => !tab.hidden)
        .sort((a_, b_) => a_.order - b_.order)
        .map((tab, tabIndex) => ({
          title: tab.name,
          rel: ALIAS_KEYWORDS.includes(tab.alias.toLowerCase()) ? null : 'nofollow',
          path: tabIndex ? `/grupos/${profile.alias}/${tab.alias.toLowerCase()}` : `/grupos/${profile.alias}`,
          alias: tab.alias.toLowerCase()
        }))) ||
    [];

  return tabs;
};

export const getContentPiecesByTitle = (page: Object, title: string) => {
  const contentPieces = (page.tabs || [])
    .flatMap(({ blocks = [] }) => blocks)
    .flatMap(({ pieces = [] }) => pieces)
    .filter(item => item.title === title);

  return contentPieces;
};

type FilterType = {
  title?: string,
  hidden?: boolean,
  type?: string | Array<string>
};

const DEFAULT_FILTER = {};

export const getContentPieces = (page: Object, filterArg: FilterType = {}) => {
  const filter = {
    ...DEFAULT_FILTER,
    ...filterArg
  };

  const contentPieces = (page.tabs || [])
    .flatMap(({ blocks = [] }) => blocks)
    .flatMap(({ pieces = [] }) => pieces)
    .filter((item) => {
      if (typeof filter.hidden === 'boolean' && filter.hidden !== item.hidden) {
        return false;
      }

      if (typeof filter.title === 'string' && filter !== item.title) {
        return false;
      }

      // $FlowFixMe
      if (isArray(filter.type) && filter.type.includes(item.type)) {
        return false;
      }

      if (['TABLE'].includes(filter.type) && item.type !== filter.type) {
        return false;
      }

      return true;
    });

  return contentPieces;
};
