// TODO: Eliminar
import {
  productTypes,
  getProductTypesByCategory,
  getProductTypeByAPISubtype,
  getProductTypeById,
  getProductTitle,
  getProductName,
  getProductAction
} from '@finect/front-resources/Products';

const getProductMetadescription = (product, productType) =>
  `Rentabilidad, cartera, review y preguntas sobre el ${productType.shortSingular} ${product.name} (${product.isin})`;

export {
  productTypes,
  getProductTypeById,
  getProductTypeByAPISubtype,
  getProductTypesByCategory,
  getProductMetadescription,
  getProductTitle,
  getProductName,
  getProductAction
};
