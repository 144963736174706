// @flow
import { finectAPI } from '@finect/front-utils/finectAPI';
import { base64Encode } from '@finect/front-utils/base64';
import { getURLWithQueryString } from '@finect/front-utils/getURLWithQueryString';
import { models } from '../../../utils/models';
import { BANKS_TAGS } from '../../../data/tags';

const isBank = (organization: Object) => Object.keys(BANKS_TAGS).includes(organization.alias);
const getBankTag = (organization: Object) => BANKS_TAGS[organization.alias];

function getFreeText(cookie: string, organization: Object, contentPiece: Object) {
  return Promise.resolve({
    body: base64Encode(contentPiece.content?.body || ''),
    embedCode: base64Encode(contentPiece.content?.embedCode || '')
  });
}

/**
 * Devuelve el contenido para el content piece de tipo NEWSFEED
 *
 */
export function getNewsFeed(cookie: string, org: Object, contentPiece: Object) {
  const organization = { ...org, isBank: isBank(org) };
  const tag = getBankTag(organization);
  const tagLimit = 10;
  const param = contentPiece.param.id;
  const limit = contentPiece.param.alt ? contentPiece.param.alt : null;
  const orderby = '-revision/updated';

  const variants = {
    organization: {
      path: `contents/organizations/${organization.alias}/pages?filter=blog+eq+'${param}'&orderby=${orderby}`,
      map: (response: Object) => ({
        items: models.safeArticles(response.data, [organization]),
        paging: {
          limit: 9999,
          totalItems: 9999,
          links: []
        }
      })
    },
    blog: {
      path: `contents/articles?filter=blog+eq+'${param}'&orderby=${orderby}${
        limit ? `&limit=${limit >= 3 ? limit : 3}` : ''
      }`,
      map: response => ({
        items: models.safeArticles(response.data.articles, response.data.authors),
        paging: response.paging
      })
    },
    tag: {
      path: `contents/articles?filter=tag+eq+${tag}&orderby=${orderby}${
        limit ? `&limit=${tagLimit >= 3 ? tagLimit : 3}` : ''
      }`,
      map: response => ({
        items: models.articles(response.data.articles, response.data.authors),
        paging: response.paging
      })
    }
  };

  const variant = organization.hasOwnProperty('alias') ? 'organization' : 'blog';
  const variantObject = variants[variant];

  const promises = [
    finectAPI({
      version: 'v4',
      credentials: cookie,
      path: variantObject.path,
      method: 'GET',
      onSuccess: (response: Object) => variantObject.map(response),
      onError: () => null
    }),
    // if tag (bank), get tag articles too
    ...tag
      ? [
        finectAPI({
          version: 'v4',
          credentials: cookie,
          path: variants.tag.path,
          method: 'GET',
          onSuccess: (response: Object) => variants.tag.map(response),
          onError: () => null
        })
      ]
      : []
  ];

  return Promise.all(promises).then((responses) => {
    const [response, tagResponse] = responses;

    const { items: tagItems = [] } = tagResponse || {};
    const { items, paging } = response;

    const allItems = [...tagItems, ...items];

    // sort allItems by revision.updated
    allItems.sort((itemA, itemB) => {
      const aDate = new Date(itemA.revision.updated);
      const bDate = new Date(itemB.revision.updated);

      return bDate - aDate;
    });

    // paging only from blog or organization
    return {
      items: allItems,
      paging
    };
  });
}

/**
 * Tablas de productos
 *
 * Se usa en grupos y en bancos
 */
export function getTablePieceContent(cookie: string, organization: Object, contentPiece: Object) {
  const tag = 400000 + Number(contentPiece.param.id);

  const query = {
    classes: 'all',
    filter: `tag eq ${tag}`,
    limit: 50,
    offset: 0,
    orderby: 'evergreen',
    expand:
      'classes,commercializations,stats/performance,stats/alpha,stats/beta,stats/standardDeviation,stats/maxDrawdown'
  };

  return finectAPI({
    version: 'v4',
    credentials: cookie,
    path: getURLWithQueryString('products', query, { encode: false }),
    method: 'GET',
    onSuccess: ({ data, paging }: { data: Array<Object>, paging: Object }) => {
      const newData = data.map(item => ({ ...item, entity: { ...item.entity, subtype: item.subtype } }));
      return { items: newData, paging };
    }
  });
}

export const CONTENT_PIECE_DEFINITION = {
  NULL: () => Promise.resolve(null),
  NEWSFEED: getNewsFeed,
  FREE_TEXT: getFreeText,
  TABLE: getTablePieceContent
};
