// @flow
import React, { Component, Fragment } from 'react';
import { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ComparisonDrawer, getEntities } from '@finect/tabular-components/Products';
import { getPathComparison } from '@finect/front-resources/Products';
import { withRouter } from 'react-router-dom';
import { setDrawerProductSelected, setDrawerEmpty } from '../store/comparison/actions';

export type WithComparisonProps = {
  theme: Object,
  selectedProducts: Array<Object>,
  selectedProductsType: Object | null,
  comparisonActions: Object,
  context: Array<Object>,
  history: Object
};

type OptionsType = {
  withDrawer?: boolean,
  modelType?: 'portfolio' | 'product',
  position?: 'fixed' | 'initial',
  marginBottom?: boolean
};

const defaultOptions: OptionsType = {
  withDrawer: true,
  modelType: 'product',
  position: 'fixed',
  marginBottom: false
};

export function withComparison(WrappedComponent: any, additionalOptions: OptionsType = {}) {
  const options = { ...defaultOptions, ...additionalOptions };
  const { withDrawer, modelType, position, marginBottom } = options;
  class withComparisonComponent extends Component<WithComparisonProps> {
    componentWillUnmount() {
      this.props.comparisonActions.setDrawerEmpty();
    }

    handleSelectRestore = () => {
      this.props.comparisonActions.setDrawerEmpty();
    };

    handleToComparison = () => {
      const { selectedProducts, selectedProductsType, history, context, theme } = this.props;
      const models = getEntities(selectedProducts, modelType);
      const products = models.map(model => model.product).map(item => item && item.entity);

      const comparisonPath = `${getPathComparison(theme, selectedProductsType, products, context)}`;

      return history.push(comparisonPath);
    };

    render() {
      const { selectedProducts, selectedProductsType } = this.props;

      return (
        <Fragment>
          <WrappedComponent {...this.props} />
          {withDrawer || selectedProducts.length ? (
            <ComparisonDrawer
              products={selectedProducts}
              selectedProductsType={selectedProductsType}
              onClear={this.handleSelectRestore}
              onAction={this.handleToComparison}
              position={position}
              marginBottom={marginBottom}
            />
          ) : null}
        </Fragment>
      );
    }
  }

  if (WrappedComponent.loadInitialData) {
    // $FlowFixMe
    withComparisonComponent.loadInitialData = function (store, cookie, match, params) {
      return Promise.all([WrappedComponent.loadInitialData(store, cookie, match, params)]);
    };
  }

  const mapDispatchToProps = dispatch => ({
    comparisonActions: bindActionCreators(
      {
        setDrawerProductSelected,
        setDrawerEmpty
      },
      dispatch
    )
  });

  const mapStateToProps = state => ({
    selectedProducts: state.comparison.drawerComparison.selectedProducts,
    selectedProductsType: state.comparison.drawerComparison.productsType,
    context: state.context.context
  });

  return connect(mapStateToProps, mapDispatchToProps)(withRouter(withTheme(withComparisonComponent)));
}
