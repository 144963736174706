// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setOmninavState } from '../../store/omninav/actions';

type Props = {
  actions: Object
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setOmninavState
    },
    dispatch
  )
});

class OmniNavListener extends React.Component<Props> {
  handleOmniNavChange = (event: SyntheticUIEvent<>) => {
    this.props.actions.setOmninavState(
      // $FlowFixMe
      event.detail === 'opened' ? 'opened' : 'closed'
    );
  };

  componentWillUnmount() {
    window && window.addEventListener('OmniNavStateChanged', this.handleOmniNavChange);
  }

  componentDidMount() {
    window && window.addEventListener('OmniNavStateChanged', this.handleOmniNavChange);
  }

  render() {
    return null;
  }
}

export default connect(null, mapDispatchToProps)(OmniNavListener);
