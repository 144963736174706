// @flow
import styled from 'styled-components';
import { minMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { grid } from '@finect/tabular-utils/grid';

/* stylelint-disable */
export const VideoInner = styled.div`
  width: 100%;
  margin: 0 auto;

  ${minMediaQueries.phablet`
    max-width: ${grid(6)}px;
  `};
`;
/* stylelint-enabled */
