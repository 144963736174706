/* eslint-disable */
import { base64Decode } from '@finect/front-utils/base64';

export const xml_special_to_escaped_one_map = {
  '&': '&amp;',
  '"': '&quot;',
  '<': '&lt;',
  '>': '&gt;'
};

export const escaped_one_to_xml_special_map = {
  '&amp;': '&',
  '&quot;': '"',
  '&lt;': '<',
  '&gt;': '>'
};

export function encodeXml(string) {
  return string.replace(/([\&"<>])/g, (str, item) => xml_special_to_escaped_one_map[item]);
}

export function decodeXml(string) {
  string = string.replace(/(&amp;)/g, (str, item) => escaped_one_to_xml_special_map[item]);
  return string.replace(/(&quot;|&lt;|&gt;|&amp;)/g, (str, item) => escaped_one_to_xml_special_map[item]);
}

export function setDangerousHtml(html, el) {
  if (el === null) return;
  const range = document.createRange();
  range.selectNodeContents(el);
  range.deleteContents();
  el.appendChild(range.createContextualFragment(html));
}

export const cleanEmptyParagraphs = (html: string) => {
  let withoutEmptyParagraphs = html.replace(/(<p><\/p>)+/gi, '');
  withoutEmptyParagraphs = withoutEmptyParagraphs.replace(/(<p> <\/p>)+/gi, '');
  withoutEmptyParagraphs = withoutEmptyParagraphs.replace(/(<p>&nbsp;<\/p>)+/gi, '');

  return withoutEmptyParagraphs;
};

export const getArticleHtml = str => {
  return decodeXml(base64Decode(str));
};

export const nl2br = (str, is_xhtml) => {
  var breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
  return (str + '').replace(/(<br>\n)/g, '<br>').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
};

export function linkify(inputText) {
  return inputText.replace(
    /(<a href=["'])?((https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)))(["']>(.*)<\/a>)?/gi,
    function () {
      return '<a href="' + arguments[2] + '">' + (arguments[7] || arguments[2]) + '</a>';
    }
  );
}

export const addNoFollowsToExternal = html => {
  var whiteList = ['([^/]+.)?finect.com'];
  var str =
    '(<as*(?!.*\brel=)[^>]*)(href="https?://)((?!(?:' + whiteList.join('|') + '))[^"]+)"((?!.*\brel=)[^>]*)(?:[^>]*)>';

  return html.replace(new RegExp(str, 'igm'), '$1$2$3"$4 rel="nofollow">');
};

export const getFirstImage = html => {
  const regExpSrc = '<img.+src=(?:"|\')(.+?)(?:"|\')(?:.+?)>';

  let firstImg = html.match(regExpSrc);
  if (firstImg) {
    firstImg = firstImg[1];
    firstImg.includes('http://') || firstImg.includes('https://')
      ? firstImg
      : (firstImg = `https://www.finect.com${firstImg}`);
    return firstImg;
  }
  return null;
};

/* eslint-enable */
