// @flow
const slugs = {
  products: 'productos',
  managementCompanies: 'gestoras',
  categories: 'categoria',
  category: 'categorias',
  comparison: 'comparador',
  superComparison: 'supercomparador'
};

const dataProviders = {
  categories: 'MS'
};

// export { routes, regex, base as basePath, slugs, inputs, dataProviders, providersCatalog };

export { slugs, dataProviders };
