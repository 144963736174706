// @flow
import { base64Encode } from '@finect/front-utils/base64';

type StoreType = {
  articles: Object,
  tagNextArticles: Object,
  articlesFeed: Object
};

export function articles(
  state: StoreType = {
    articles: {
      items: [],
      fetching: false,
      fetched: false,
      paging: {
        links: [],
        totalItems: 0,
        limit: 10
      }
    },
    tagNextArticles: {
      items: [],
      fetching: false,
      fetched: false,
      paging: {
        links: [],
        totalItems: 0,
        limit: 10
      }
    },
    articlesFeed: {
      items: [],
      fetching: false,
      fetched: false,
      paging: {
        links: [],
        totalItems: 0,
        limit: 10
      }
    }
  },
  action: Object
) {
  switch (action.type) {
    case 'SET_ARTICLES': {
      const newState = { ...state };
      newState.articles = {
        ...state.articles,
        items: [
          ...state.articles.items,
          ...action.contents.map(item =>
            (item.type === 'article'
              ? {
                ...item,
                body: {
                  ...item.body,
                  text: base64Encode(item.body.text)
                }
              }
              : item))
        ],
        fetching: false,
        fetched: true,
        paging: action.paging
      };
      return { ...state, ...newState };
    }

    case 'SET_TAG_NEXT_ARTICLES': {
      const newState = { ...state };
      newState.tagNextArticles = {
        ...state.tagNextArticles,
        items: [
          ...state.tagNextArticles.items,
          ...action.contents.map(item =>
            (item.type === 'article'
              ? {
                ...item,
                body: {
                  ...item.body,
                  text: base64Encode(item.body.text)
                }
              }
              : item))
        ],
        fetching: false,
        fetched: true,
        paging: action.paging
      };
      return { ...state, ...newState };
    }

    case 'SET_TAG_NEXT_ARTICLES_FETCHING_STATUS': {
      const newState = { ...state };
      newState.tagNextArticles = {
        ...state.tagNextArticles,
        fetching: action.fetching,
        fetched: action.fetched || state.tagNextArticles.fetched
      };
      return { ...state, ...newState };
    }

    case 'CLEAR_TAG_NEXT_ARTICLES': {
      const newState = { ...state };
      newState.tagNextArticles = {
        ...state.tagNextArticles,
        items: [],
        fetching: false,
        fetched: false,
        paging: {
          links: [],
          totalItems: 0,
          limit: 10
        }
      };
      return { ...state, ...newState };
    }

    case 'SET_ARTICLES_FEED': {
      const newState = { ...state };
      newState.articlesFeed = {
        ...state.articlesFeed,
        items: [...state.articlesFeed.items, ...action.contents],
        fetching: false,
        fetched: true,
        paging: action.paging
      };
      return { ...state, ...newState };
    }

    case 'CLEAR_ARTICLES_FEED': {
      const newState = { ...state };
      newState.articlesFeed = {
        ...state.articlesFeed,
        items: [],
        fetching: false,
        fetched: false,
        paging: {
          links: [],
          totalItems: 0,
          limit: 10
        }
      };
      return { ...state, ...newState };
    }

    case 'SET_ARTICLES_FETCHING_STATUS': {
      const newState = { ...state };
      newState.articles = {
        ...state.articles,
        fetching: action.fetching,
        fetched: action.fetched || state.articles.fetched
      };
      return { ...state, ...newState };
    }

    default:
      return state;
  }
}
