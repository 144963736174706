import styled, { css } from 'styled-components';
import { minMediaQueries, maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';
import { Count } from './Count';

/* eslint-disable */
const blockStyles = css`
  color: ${({ theme }) => theme.colors.fontL};

  ${/* sc-sel */ Count} {
    color: ${({ theme }) => theme.colors.fontLL};
  }

  ${minMediaQueries.tablet`
    position: relative;
    padding-left: 63px;
    margin: 0 0 18px;
    ${getFont(-1)}
    color: ${({ theme }) => theme.colors.fontL};

    span {
      margin-left: 9px;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    ${/* sc-sel */ Count} {
      position: absolute;
      left: 0;
      top: -1.5px;
      width: 51px;
      height: 27px;
      margin-right: 12px;
      ${getFont(-2)}
      line-height: 27px;
    }

    &:hover {
      ${/* sc-sel */ Count} {
        color: currentColor;
      }
    }
  `};
`;
/* eslint-enable */

const doneStyles = css`
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.font)};

  svg {
    fill: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.font)};
    stroke: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.font)};
  }
`;

/* eslint-disable */
export const Action = styled.div`
  display: flex;
  align-items: center;
  margin: 6px 24px 6px 0;
  cursor: pointer;
  ${getFont(-2, 'compact')}
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.fontLL};

  svg {
    flex-shrink: 0;
    width: 18px;
    height: 18px;
  }

  span {
    margin-left: 5px;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ inline }) => !inline && blockStyles};
  ${({ done }) => done && doneStyles};

  &:hover {
    color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.font)};

    ${/* sc-sel */ Count} {
      ${({ theme, color, solved }) =>
        solved ? `background-color: ${color ? theme.colors[color] : theme.colors.font}` : null};
    }
  }

  ${maxMediaQueries.phone`
    margin-right: 18px;

    span {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  `};
`;
/* eslint-enable */
