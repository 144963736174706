// @flow
import React from 'react';
import { H1 } from '@finect/tabular-core/Headings';
import { Inner } from './partials';

export const BankIntro = ({ organization }: { organization: Object }) => (
  <Inner>
    <H1 noPadding>Invertir con {organization.displayName}</H1>
  </Inner>
);
