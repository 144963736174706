//@flow
export function settings(
  state: Object = {
    settings: {}
  },
  action: Object
) {
  switch (action.type) {
    case 'SET_SETTING_FETCHING_STATUS':
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.setting]: {
            ...state.settings[action.setting],
            fetching: action.fetching,
            fetched: action.fetched || state.settings[action.setting]?.fetched || false
          }
        }
      };

    case 'SET_SETTING':
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.setting]: {
            fetching: false,
            fetched: true,
            value: action.value
          }
        }
      };

    default:
      return state;
  }
}
