// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Ads } from '@finect/tabular-components/Ads';
import { Inner, BlockContent } from './partials';

type Props = {
  targeting?: Object,
  margin?: 'left' | 'right'
};

export const LateralAd = ({ targeting = {}, margin }: Props) => {
  if (typeof window === 'undefined') {
    return null;
  }

  // 1286 = x + 36*2
  if (!(window.innerWidth >= 1280)) {
    return null;
  }

  const omninavState: 'opened' | 'closed' = useSelector(state => state.omninav.state);

  return (
    <Inner omninavState={omninavState}>
      <BlockContent margin={margin}>
        <Ads id="Finect_Side2" targeting={targeting} />
      </BlockContent>
    </Inner>
  );
};
