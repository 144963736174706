// @flow
import { getProductTypesByCategory } from '../utils/productTypes';

const regex = getProductTypesByCategory('content')
  .map(({ slug }) => slug)
  .join('|');

export const routes = {
  home: `/:productTypeSlug(${regex})`,
  sheet: `/:productTypeSlug(${regex})/:productSlug`,
  checkout: `/:productTypeSlug(${regex})/:productSlug/comprar`,
  questions: `/:productTypeSlug(${regex})/:productSlug/preguntas`,
  comparison: `/:productTypeSlug(${regex})/comparador`
};
