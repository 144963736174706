// @flow
import React from 'react';
import { withTheme } from 'styled-components';
import { Jumbo } from '@finect/tabular-components/Jumbo';
import { Section } from '@finect/tabular-components/Section';
import JumboAdvisersImg from './../../../assets/jumbo-advisers.jpg';

export const AdvisersStorefrontJumboSmall = ({ theme }: { theme: Object }) => (
  <Section topMargin>
    <Jumbo
      id="storefront-advisers"
      variant="small"
      textAlign="left"
      coverImage={JumboAdvisersImg}
      color="#fff"
      backgroundColor={theme.colors.primaryM}
      title="¿No sabes cómo elegir uno de estos productos?"
      cta="Busca un asesor"
      ctaVariant="secondary"
      link="https://www.finect.com/asesores"
    />
  </Section>
);

export default withTheme(AdvisersStorefrontJumboSmall);
