// @flow
import React from 'react';
import { Ads } from '@finect/tabular-components/Ads';
import { Inner } from './partials';

type Props = {
  bottomMargin?: boolean,
  targeting?: Object
};

export const TopAds = ({ bottomMargin = false, targeting = {} }: Props) => (
  <Inner bottomMargin={bottomMargin}>
    <Ads id="Finect_Top1" targeting={targeting} />
  </Inner>
);
