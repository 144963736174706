// @flow
import styled from 'styled-components';

export const Topics = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 9px;
  margin: -3px;
`;
