//@flow

export const BANKS_TAGS = {
  santander: 2,
  bbva: 3,
  openbank: 4,
  bankinter: 28,
  ing: 519,
  caixabank: 2610,
  'renta-4-banco': 9283,
  evobanco: 12049,
  abanca: null,
  kutxabank: null,
  ibercaja: null,
  'unicaja-banco': null,
  'banco-sabadell': null
};
