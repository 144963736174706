// @flow
import React from 'react';
import { H1 } from '@finect/tabular-core/Headings';
import { ViewToggle } from '@finect/tabular-components/Filter';
import { Inner } from './partials';

export const BankProductsHeader = ({
  title,
  onViewModeChange,
  viewMode
}: {
  title: string,
  onViewModeChange: Function,
  viewMode: 'grid' | 'table'
}) => (
  <Inner>
    <div>
      <H1>{title}</H1>
    </div>

    <div>
      <ViewToggle view={viewMode} onClick={onViewModeChange} />
    </div>
  </Inner>
);
