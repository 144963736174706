// @flow
import React from 'react';
import { Jumbo } from '@finect/tabular-components/Jumbo';
import { Section } from '@finect/tabular-components/Section';

// https://www.finect.com/image/45136e1657e70fdc62d317aef61f5a16156655cbc01 ----> fondo azul
export const QuestionsJumbo = () => (
  <Section bottomMargin>
    <Jumbo
      id="questions"
      contentPosition="left"
      textAlign="left"
      color="#2a2a2a"
      title="No te quedes con dudas"
      subtitle={`Pregunta a nuestra comunidad todas tus dudas sobre inversión y ahorro.`}
      cta="Envía tu pregunta"
      coverImage="https://www.finect.com/image/f20d49c204e2f900b12545324144127e340a5182cc7"
      link="/preguntas/nueva-pregunta"
    />
  </Section>
);
