// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { DateLabel } from '@finect/tabular-components/DateLabel';
import { Flex } from '../components/Flex';
import { Topic, Topics } from '../components/Topic';
import { Title6 } from '../components/Headings';
import { ArticleContent, ArticleInfo, PublishDate, QuestionLabel } from '../partials';

const BlockIntro = styled.div`
  font-size: 8px;
  font-weight: 100;
  text-transform: uppercase;
`;

const Block = styled.div`
  margin-bottom: 5px;
  background-color: ${({ theme }) => theme.colors.uiLL};
`;

export const QuestionListCard = ({
  question
}: {
  question: Object,
  onSubscribe: Function,
  userLikes: Object,
  userSubscriptions: Object
}) => {
  const title = question.title;
  const link = question.link;
  const answers = question.stats.answers;
  const date = question.revision.updated;

  return (
    <>
      <BlockIntro>PREGUNTA</BlockIntro>
      <Block>
        <ArticleContent>
          <ArticleInfo hasImage={Boolean(true)}>
            <div>
              {question.socialStats.tags && question.socialStats.tags.length > 0 && (
                <Topics>
                  {question.socialStats.tags.map(tag => (
                    <Topic key={tag.id} href={`/temas/${tag.alias}`} followed={tag.followed}>
                      {tag.name}
                    </Topic>
                  ))}
                </Topics>
              )}
              <Title6 as="h3">
                <a href={link}>
                  {title}
                  {answers > 0 ? (
                    <QuestionLabel>{` [${answers} respuesta${answers > 1 ? 's' : ''}]`}</QuestionLabel>
                  ) : (
                    <QuestionLabel>{` [sin respuestas]`}</QuestionLabel>
                  )}
                </a>
              </Title6>
              <Flex align="center">
                <PublishDate>
                  <DateLabel date={new Date(date)} format="LLL" />
                </PublishDate>
              </Flex>
            </div>
          </ArticleInfo>
        </ArticleContent>
      </Block>
    </>
  );
};
