// @flow
import styled, { css } from 'styled-components';

const leftStyles = css`
  left: -364px;
  @media (min-width: 1200px) {
    left: -${120 + 36}px;
  }

  @media (min-width: 1538px) {
    left: -${160 + 36}px;
  }

  @media (min-width: 1818px) {
    left: -${300 + 36}px;
  }
`;

const rightStyles = css`
  right: -364px;
  @media (min-width: 1200px) {
    right: -${120 + 36}px;
  }

  @media (min-width: 1538px) {
    right: -${160 + 36}px;
  }

  @media (min-width: 1818px) {
    /* right: -${300 + 36}px; */
  }
`;

export const Block: any = styled.div`
  position: absolute;
  // z-index: 1;
  top: 0;
  width: 120px;
  height: ${({ height }) => `${height}px`};
  ${({ position }) => (position === 'left' ? leftStyles : rightStyles)}

  @media (min-width: 1538px) {
    width: 160px;
  }
`;

export const BlockContent: any = styled.div`
  position: sticky;
  top: 60px;
  height: 600px;
  transition: top 0.25s cubic-bezier(0.49, 0.42, 0.52, 0.98);

  ${({ margin }) => (margin ? `margin-${margin}: 20px;` : '')}

  width: 120px;

  @media (min-width: 1538px) {
    width: 160px;
  }

  @media (min-width: 1818px) {
    width: 300px;
  }
`;

export const Inner: any = styled.div`
  position: relative;

  ${/* sc-sel */ BlockContent} {
    ${({ theme, omninavState, withInsideNavigation, withBottomMargin }) => {
    const top = omninavState === 'closed' ? 30 : 30 + theme.navHeight;

    return `
    top: ${withInsideNavigation ? top + 60 : top}px;
    ${withBottomMargin ? 'margin-bottom: 60px;' : ''}
    `;
  }}
`;
