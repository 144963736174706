// @flow
import type { Dispatch } from 'redux';
import { finectAPI } from '@finect/front-utils/finectAPI';

function setHighlights(highlights: Array<Object>) {
  return {
    type: 'SET_HOME_HIGHLIGHTS',
    highlights
  };
}

export function getHighlights(cookie: string) {
  return function (dispatch: Dispatch) {
    return finectAPI({
      version: 'v4',
      path: 'contents/highlights',
      credentials: cookie,
      method: 'GET',
      onSuccess: ({ data }: { data: Object }) => dispatch(setHighlights(data))
    });
  };
}
