// @flow
import React from 'react';

import { EntityCard } from '@finect/tabular-components/EntityCard';
import { Topic } from '../../components/ContentListCard/components/Topic';
import { ArticleTags } from '../../components';
import { ToolbarItems } from './ToolbarItems';
import { ToolbarContent } from './partials';

type Props = {
  url: string,
  title: string,
  inline: boolean,
  type: string,
  likes: number,
  comments: number,
  followedTags: Array<number | string>,
  onProfileFollow: Function,
  profileSubscriptions: Array<string>,
  tags: Array<Object>,
  liked?: boolean,
  solved?: boolean,
  saved?: boolean,
  onLike: Function,
  onSave: Function
};

export const Actions = ({
  url,
  title,
  inline,
  type,
  liked,
  solved,
  saved,
  likes,
  comments,
  tags = [],
  followedTags,
  onLike,
  onSave
}: Props) => {
  // eslint-disable-next-line id-length
  tags.sort((a: Object, b: Object) => a.name.length - b.name.length);

  return (
    <div>
      <div>
        <ToolbarContent inline={inline}>
          <ToolbarItems
            url={url}
            title={title}
            onSave={onSave}
            onLike={onLike}
            inline={inline}
            type={type}
            liked={liked}
            solved={solved}
            saved={saved}
            likes={likes}
            comments={comments}
          />
        </ToolbarContent>

        <div>
          <ArticleTags>
            {tags
              .filter(tag => !tag.name.includes('#'))
              .map(tag => (
                <EntityCard type="tag" entity={tag} key={tag.id}>
                  <Topic href={`/temas/${tag.alias}`} followed={followedTags.includes(tag.id)}>
                    {tag.name}
                  </Topic>
                </EntityCard>
              ))}
          </ArticleTags>
        </div>
      </div>
    </div>
  );
};
