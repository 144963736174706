// @flow
import styled, { css } from 'styled-components';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';

export const ActionLabel = styled.span`
  margin-left: 9px;
`;

const inlineStyles = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;

  ${maxMediaQueries.phone`
    position: absolute;
    bottom: 9px;
    left: 0;
    right: 0;
    justify-content: space-between;
  `};
`;

const blockStyles = css`
  ${maxMediaQueries.miniDesktop`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.uiM};
    margin-bottom: 30px;
  `};

  ${maxMediaQueries.phone`
    justify-content: space-between;
    margin-bottom: 24px;
  `};
`;

export const ToolbarContent = styled.div`
  ${({ inline }) => (inline ? inlineStyles : blockStyles)};
`;
