export function omninav(
  state = {
    state: 'opened'
  },
  action
) {
  switch (action.type) {
    case 'SET_OMNINAV_STATE': {
      const newState = { ...state };
      newState.state = action.state;
      return { ...state, ...newState };
    }

    default:
      return state;
  }
}
