// @flow
import React from 'react';
import { Section } from '@finect/tabular-components/Section';
import { getProductTypeById } from '@finect/front-resources/Products';
import { Tabs, TabsPane } from '@finect/tabular-components/Tabs';
import { useLocation } from 'react-router-dom';

export const BankProductFamilies = ({
  productTypes,
  organization
}: {
  productTypes: Array<string>,
  organization: Object
}) => {
  const location = useLocation();
  const shortlinks = productTypes.map(subtype => getProductTypeById(subtype));

  const productTabs = shortlinks.map(productType => ({
    to: `/bancos/${organization.alias}/productos/${productType.slug}`,
    title: productType.plural
  }));

  const tabs = [
    { to: `/bancos/${organization.alias}`, title: 'Portada' },
    ...productTabs,
    { to: `/bancos/${organization.alias}/analisis`, title: 'Artículos' }
  ];

  const tabIndex = tabs.findIndex(tab => tab.to === location.pathname);

  return (
    <Section bottomMargin>
      <Tabs initial={tabIndex}>
        {tabs.map((tab, i) => (
          <TabsPane key={i} to={tab.to} title={tab.title}></TabsPane>
        ))}
      </Tabs>
    </Section>
  );
};
