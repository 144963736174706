// @flow
import React from 'react';
import { ChevronRight } from '@finect/tabular-icons/chevronRight';
import { base64Decode } from '@finect/front-utils/base64';
import { HTML } from '@finect/tabular-components/HTML';
import { getWidgetComponent } from '@finect/tabular-components/Articles';
import { WrapperHTMLPieces, WrapperHTMLDisclaimers, ViewMoreDisclaimers } from './../../partials';

type Props = {
  content: Object,
  param: Object,
  type: string,
  disclaimers: Array<Object>,

  // TODO: Buscar dónde se usaba y eliminar
  data?: Object
};

export const FreeText = ({ content, type, param, disclaimers, data }: Props) => {
  if (['FREE_TEXT', 'AUDIO', 'VIDEO'].includes(type)) {
    const body = data ? data.body : content.body;
    const embedCode = data ? data.embedCode : content.embedCode;

    return (
      <>
        {content.body ? (
          <div style={{ marginBottom: '24px' }}>
            <HTML html={base64Decode(body)} wrapper={WrapperHTMLPieces} getWidgetComponent={getWidgetComponent} />
          </div>
        ) : null}

        {content.embedCode ? (
          <HTML html={base64Decode(embedCode)} wrapper={WrapperHTMLPieces} getWidgetComponent={getWidgetComponent} />
        ) : null}
      </>
    );
  }

  if (type === 'DISCLAIMER') {
    const disclaimer = disclaimers.find(disc => disc.type === param.id.toLowerCase());

    const html = disclaimer ? base64Decode(disclaimer.body.text) : '';
    const url = disclaimer ? disclaimer.url : '';

    return (
      <>
        <HTML html={html} wrapper={WrapperHTMLDisclaimers} />
        <ViewMoreDisclaimers href={url} title="Ver más">
          Ver más <ChevronRight size="20" />
        </ViewMoreDisclaimers>
      </>
    );
  }

  return null;
};
