// @flow
import React from 'react';
import { DateLabel } from '@finect/tabular-components/DateLabel';
import { Link } from '@finect/tabular-icons/link';
import { AppLink } from '@finect/tabular-core/AppLink';
import { Video, normalizeVideo } from '@finect/tabular-components/Videos';
import { getArticleRel } from '@finect/tabular-components/Articles';
import { EntityCard } from '@finect/tabular-components/EntityCard';
import { Image } from '@finect/tabular-components/Image';
import { Icon } from '@finect/tabular-components/Icon';
import { Flex } from '../components/Flex';
import { Topic, Topics } from '../components/Topic';
import { Title6 } from '../components/Headings';
import { getArticlePreviewText, getArticlePath } from '../../../utils/articles';

import {
  VideoContainer,
  VideoContent,
  Author,
  ArticleContent,
  ArticleInfo,
  Small,
  External,
  // ExternalImage,
  SquareImage
} from '../partials';

type Props = {
  article: Object,
  onSubscribe: Function,
  userSubscriptions: Object,
  userLikes: Object,
  withAuthor?: boolean,
  withText?: boolean
};

export const ArticleListCard = ({ article, withAuthor = true, userSubscriptions = {}, withText = false }: Props) => {
  const { title } = article;

  const date = article.revision.updated;
  const externalUrl = article.body.url ? article.body.url : '';
  const externalPath = externalUrl && externalUrl.match('^(?:https?://)?(?:[^@\n]+@)?(?:www.)?([^:/\n?]+)');

  if (!article.author) return null;
  const link = getArticlePath(article);

  const image = article.image
    ? article.image
    : article.author.subtype === 'company'
      ? `/image/${article.author.icon}`
      : null;

  const hasProfile =
    (article.author.subtype === 'user' && article.author.features && article.author.features.includes('profile')) ||
    (article.author.subtype === 'company' && article.author.features && article.author.features.blog);

  const author = {
    type: article.author.subtype === 'company' ? 'company' : 'user',
    displayName: article.author.displayName,
    icon: article.author.icon,
    url: article.author.web
  };

  const tags = article.socialStats.tags.map(tag => ({
    ...tag,
    followed: Boolean(userSubscriptions.tags && userSubscriptions.tags.find(t => t === tag.id))
  }));

  const linkProps = externalUrl ? { rel: getArticleRel(article), target: '_blank' } : { rel: getArticleRel(article) };

  const video = normalizeVideo(externalUrl);

  if (['video'].includes(article.type) && video) {
    return (
      <VideoContainer>
        <div>
          {tags && tags.length > 0 && (
            <Topics>
              {tags.map(tag => (
                <EntityCard type="tag" entity={tag} key={tag.id}>
                  <Topic href={`/temas/${tag.alias ? tag.alias : tag.url}`} title={tag.name} followed={tag.followed}>
                    {tag.name}
                  </Topic>
                </EntityCard>
              ))}
            </Topics>
          )}
        </div>

        <div>
          <VideoContent>
            <Video video={video} />
          </VideoContent>
          <div>
            <Title6 as="h3">
              <AppLink black href={link} title={title} rel={getArticleRel(article)}>
                {title}
              </AppLink>
            </Title6>

            {withText && article.body.text && <p>{getArticlePreviewText(article.body.text)}</p>}

            <Flex align="center">
              {author && withAuthor && (
                <Author>
                  {hasProfile ? (
                    <a href={author.url} title={`Ver perfil de ${author.displayName}`}>
                      <Icon
                        withBorder
                        size={1}
                        alt={author.displayName}
                        image={`/image/${author.icon}`}
                        margin="0 8px 0 0"
                      />
                      {author.displayName}
                    </a>
                  ) : (
                    <span>
                      <Icon
                        withBorder
                        size={1}
                        alt={author.displayName}
                        image={`/image/${author.icon}`}
                        margin="0 8px 0 0"
                      />
                      {author.displayName}
                    </span>
                  )}
                </Author>
              )}
              <div>
                <Small withLeftMargin={Boolean(author && withAuthor)}>
                  <DateLabel date={new Date(date)} format="LLL" />
                </Small>
              </div>
            </Flex>
          </div>
        </div>
      </VideoContainer>
    );
  }

  return (
    <ArticleContent>
      <ArticleInfo hasImage={Boolean(image)}>
        <div>
          {tags && tags.length > 0 && (
            <Topics>
              {tags.map(tag => (
                <EntityCard type="tag" entity={tag} key={tag.id}>
                  <Topic href={`/temas/${tag.alias ? tag.alias : tag.url}`} title={tag.name} followed={tag.followed}>
                    {tag.name}
                  </Topic>
                </EntityCard>
              ))}
            </Topics>
          )}

          <Title6 as="h3">
            <a href={article.type !== 'podcast' && externalUrl ? externalUrl : link} title={title} {...linkProps}>
              {title}
            </a>
          </Title6>

          {withText && article.body.text && <p>{getArticlePreviewText(article.body.text)}</p>}

          <Flex align="center">
            {author && withAuthor && (
              <Author>
                {hasProfile ? (
                  <a href={author.url} title={`Ver perfil de ${author.displayName}`}>
                    <Icon
                      withBorder
                      size={1}
                      alt={author.displayName}
                      image={`/image/${author.icon}`}
                      margin="0 8px 0 0"
                    />
                    {author.displayName}
                  </a>
                ) : (
                  <span>
                    <Icon
                      withBorder
                      size={1}
                      alt={author.displayName}
                      image={`/image/${author.icon}`}
                      margin="0 8px 0 0"
                    />
                    {author.displayName}
                  </span>
                )}
              </Author>
            )}
            <div>
              {externalUrl && (
                <small>
                  <External href={externalUrl} rel="nofollow" target="_blank">
                    <Link size="16" strokeWidth="2.2" />
                    <span>{externalPath ? externalPath[0] : ''}</span>
                  </External>
                </small>
              )}
              <Small withLeftMargin={Boolean(author && withAuthor)}>
                <DateLabel date={new Date(date)} format="LLL" />
              </Small>
            </div>
          </Flex>
        </div>
      </ArticleInfo>

      {image ? (
        <SquareImage as="a" href={externalUrl || link} title={title} {...linkProps} size="100px" margin="0 0 12px 15px">
          <Image alt={title} src={image} width={100} height={100} />
        </SquareImage>
      ) : null}
    </ArticleContent>
  );
};
