// @flow
import React from 'react';
import { disableBodyScroll, enableBodyScroll } from '@finect/tabular-utils/bodyScrollLock';
import { SubscribeModalContent } from './SubscribeModalContent';

const SUBSCRIPTION_EXCLUDED_PATHS = ['/servicios/finect-subscription/comprar'];

type Props = {
  user: Object,
  pathname?: string | null,
  onChangeVisibility?: Function
};
type State = {
  show: boolean
};

export class SubscribeModal extends React.Component<Props, State> {
  state = {
    show: true
  };

  componentDidMount() {
    if (SUBSCRIPTION_EXCLUDED_PATHS.includes(this.props.pathname || '')) {
      this.setState({ show: false });
      return;
    }

    this.props.onChangeVisibility && this.props.onChangeVisibility();
    disableBodyScroll(document.body);
  }

  shouldComponentUpdate = (nextProps: Props, nextState: State) => {
    if (nextState.show !== this.state.show) return true;
    if (nextProps.pathname !== this.props.pathname) return true;

    return false;
  };

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.pathname !== this.props.pathname &&
      !SUBSCRIPTION_EXCLUDED_PATHS.includes(this.props.pathname || '')
    ) {
      this.setState({ show: true });
      this.props.onChangeVisibility && this.props.onChangeVisibility();
      disableBodyScroll(document.body);
    }
  }

  componentWillUnmount() {
    enableBodyScroll(document.body);
  }

  render() {
    const { show } = this.state;

    return show ? <SubscribeModalContent user={this.props.user} /> : null;
  }
}

export default React.memo<Props>(SubscribeModal);
