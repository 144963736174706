// @flow
import React from 'react';
import { getLoginBaseURL } from '@finect/front-utils/getLoginBaseURL';

export type StrategiesType = 'email' | 'facebook' | 'linkedin';

export const strategies = {
  facebook: {
    longLiteral: 'Accede con Facebook',
    link: getLoginBaseURL(),
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
        <path
          fill="#4267b2"
          d="M25.5,0h-24C0.7,0,0,0.7,0,1.5v24C0,26.3,0.7,27,1.5,27c0,0,0,0,0,0h12.9V16.6h-3.5v-4.1h3.5v-3 c0-3.5,2.1-5.4,5.2-5.4c1,0,2.1,0.1,3.1,0.2v3.6h-2.1c-1.7,0-2,0.8-2,2v2.6h4.1l-0.5,4.1h-3.5V27h6.9c0.8,0,1.5-0.7,1.5-1.5l0,0v-24 C27,0.7,26.3,0,25.5,0C25.5,0,25.5,0,25.5,0z"
        />
      </svg>
    )
  },
  linkedin: {
    longLiteral: 'Accede con Linkedin',
    link: getLoginBaseURL(),
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
        <path
          fill="#1d79ad"
          d="M25,0H2C0.9,0,0,0.9,0,1.9v23.1C0,26.1,0.9,27,2,27h23c1.1,0,2-0.9,2-1.9V1.9C27,0.9,26.1,0,25,0z"
        />
        <path
          fill="#fff"
          d="M4,10.1h4V23H4V10.1z M6,3.7c1.3,0,2.3,1,2.3,2.3c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3 C3.7,4.8,4.7,3.7,6,3.7"
        />
        <path
          fill="#fff"
          d="M10.5,10.1h3.8v1.8h0.1c0.5-1,1.8-2.1,3.8-2.1c4.1,0,4.8,2.7,4.8,6.1V23h-4v-6.3c0-1.5,0-3.4-2.1-3.4 c-2.1,0-2.4,1.6-2.4,3.3V23h-4V10.1z"
        />
      </svg>
    )
  },
  google: {
    longLiteral: 'Accede con Google',
    link: getLoginBaseURL(),
    icon: (
      <svg width="535px" height="545px" viewBox="0 0 535 545" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Google__G__Logo" transform="translate(0.000000, -1.000000)" fillRule="nonzero">
            <path
              d="M534.5,279.4 C534.5,260.9 533,242.3 529.8,224.1 L273.1,224.1 L273.1,328.9 L420.1,328.9 C414,362.7 394.4,392.6 365.7,411.6 L365.7,479.6 L453.4,479.6 C504.9,432.2 534.5,362.2 534.5,279.4 Z"
              id="Path"
              fill="#4285F4"
            ></path>
            <path
              d="M273.1,545.3 C346.5,545.3 408.4,521.2 453.5,479.6 L365.8,411.6 C341.4,428.2 309.9,437.6 273.2,437.6 C202.2,437.6 142,389.7 120.4,325.3 L29.9,325.3 L29.9,395.4 C76.1,487.3 170.2,545.3 273.1,545.3 Z"
              id="Path"
              fill="#34A853"
            ></path>
            <path
              d="M120.3,325.3 C108.9,291.5 108.9,254.9 120.3,221.1 L120.3,151 L29.9,151 C-8.7,227.9 -8.7,318.5 29.9,395.4 L120.3,325.3 Z"
              id="Path"
              fill="#FBBC04"
            ></path>
            <path
              d="M273.1,108.7 C311.9,108.1 349.4,122.7 377.5,149.5 L377.5,149.5 L455.2,71.8 C406,25.6 340.7,0.2 273.1,1 C170.2,1 76.1,59 29.9,151 L120.3,221.1 C141.8,156.6 202.1,108.7 273.1,108.7 Z"
              id="Path"
              fill="#EA4335"
            ></path>
          </g>
        </g>
      </svg>
    )
  },
  email: {
    longLiteral: 'Accede con tu email',
    link: getLoginBaseURL(),
    icon: (
      <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.4 1H22.6C23.92 1 25 2.08 25 3.4V17.8C25 19.12 23.92 20.2 22.6 20.2H3.4C2.08 20.2 1 19.12 1 17.8V3.4C1 2.08 2.08 1 3.4 1Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25 3.39999L13 11.8L1 3.39999"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  }
};
