export function fund(
  state = {
    homeStatus: '',
    fund: {
      model: {},
      fetching: false,
      fetched: false,
      request: {},
      notFound: false
    },
    funds: [],
    questions: [],
    advisors: [],
    productStorefronts: [],
    macrocategories: [],
    categories: [],
    category: {
      model: null,
      fetching: false,
      fetched: false
    },
    managementCompanies: [],
    managementCompany: {
      model: null,
      fetching: false,
      fetched: false,
      request: {}
    },
    sameManagementCompanyFunds: [],
    filters: {
      category: [],
      company: [],
      srri: [],
      country: [],
      order: null
    },
    fundList: {
      page: 1,
      fetched: false,
      fetching: false,
      items: [],
      paging: {}
    },

    countries: [
      {
        id: "eq+'esp'",
        name: 'Nacional'
      },
      {
        id: "ne+'esp'",
        name: 'Internacional'
      }
    ],

    topGlobalFunds: [],
    topLocalFunds: [],
    mostViewed: {
      page: 1,
      fetching: false,
      items: [],
      paging: {},
      request: {}
    },

    mostCompared: {
      page: 1,
      fetching: false,
      items: [],
      paging: {},
      request: {}
    },
    mostComparedYTD: {
      page: 1,
      fetching: false,
      items: [],
      paging: {},
      request: {}
    },

    // fundsByYTD
    fundsByYTD: {
      page: 1,
      fetching: false,
      items: [],
      paging: {},
      request: {}
    },

    fundsByAssets: {
      page: 1,
      fetching: false,
      items: [],
      paging: {},
      request: {}
    },

    companies: {
      page: 1,
      fetching: false,
      items: [],
      paging: {},
      request: {}
    },
    fundsByManagementCompany: {
      page: 1,
      fetching: false,
      fetched: false,
      items: [],
      paging: {},
      request: {}
    },
    fundsByCategory: {
      page: 1,
      fetching: false,
      fetched: false,
      items: [],
      paging: {},
      request: {}
    },
    distributors: {
      page: 1,
      fetching: false,
      fetched: false,
      items: [],
      paging: {},
      request: {}
    }
  },
  action
) {
  switch (action.type) {
    case 'SET_FUND_HOME_STATUS': {
      const newState = { ...state };
      newState.homeStatus = action.status;
      return { ...state, ...newState };
    }

    case 'SET_ADVISORS': {
      const newState = { ...state };
      newState.advisors = [...action.advisors];
      return { ...state, ...newState };
    }

    case 'SET_FUND_FETCHING_STATUS': {
      const newState = { ...state };
      newState.fund.fetching = action.fetching;
      newState.fund.fetched = action.fetched;
      newState.fund.notFound = action.notFound;

      return { ...state, ...newState };
    }
    case 'SET_FUND': {
      const newState = { ...state };
      newState.fund.model = { ...action.fund };
      newState.fund.fetching = false;
      newState.fund.fetched = true;
      newState.fund.notFound = false;
      return { ...state, ...newState };
    }

    case 'CLEAR_FUND': {
      const newState = { ...state };

      newState.fund = {
        model: {},
        fetching: false,
        fetched: false,
        request: {},
        notFound: false
      };

      newState.advisors = [];
      newState.sameManagementCompanyFunds = [];

      return { ...state, ...newState };
    }

    case 'SET_FUNDS': {
      const newState = { ...state };
      newState.funds = [...action.funds];
      return { ...state, ...newState };
    }
    case 'SET_TOP_LOCAL_FUNDS': {
      const newState = { ...state };
      newState.topLocalFunds = [...action.funds];
      return { ...state, ...newState };
    }
    case 'SET_TOP_GLOBAL_FUNDS': {
      const newState = { ...state };
      newState.topGlobalFunds = [...action.funds];
      return { ...state, ...newState };
    }
    case 'SET_MACROCATEGORIES': {
      const newState = { ...state };
      newState.macrocategories = [...action.macrocategories];
      return { ...state, ...newState };
    }
    case 'SET_CATEGORIES': {
      const newState = { ...state };
      newState.categories = [...action.categories];
      return { ...state, ...newState };
    }

    case 'SET_CATEGORY_FETCHING_STATUS': {
      const newState = { ...state };
      newState.category = {
        ...newState.category,
        fetching: action.fetching,
        fetched: action.fetched
      };
      return { ...state, ...newState };
    }
    case 'SET_CATEGORY': {
      const newState = { ...state };
      newState.category = {
        ...newState.category,
        model: action.category,
        fetching: false,
        fetched: true
      };
      return { ...state, ...newState };
    }
    case 'SET_CATEGORY_FUNDS': {
      const newState = { ...state };
      newState.funds = [...action.funds];
      return { ...state, ...newState };
    }
    case 'CLEAR_CATEGORY': {
      const newState = { ...state };
      newState.funds = [];
      newState.category = {
        model: null,
        fetching: false,
        fetched: false
      };
      return { ...state, ...newState };
    }

    case 'SET_MANAGEMENT_COMPANIES': {
      const newState = { ...state };
      newState.managementCompanies = [...action.managementCompanies];
      return { ...state, ...newState };
    }

    case 'SET_MANAGEMENT_COMPANIES_WITH_FILTER': {
      const newState = { ...state };
      newState.companies = {
        items: [...action.items]
      };
      newState.companies.fetching = false;
      newState.companies.request = action.request;
      return { ...state, ...newState };
    }

    case 'SET_MANAGEMENT_COMPANIES_WITH_FILTER_FETCHING_STATUS': {
      const newState = { ...state };
      newState.companies.fetching = action.fetching;
      return { ...state, ...newState };
    }

    case 'SET_MANAGEMENT_COMPANY': {
      const newState = { ...state };
      newState.managementCompany.model = { ...action.managementCompany };
      newState.managementCompany.fetching = false;
      newState.managementCompany.fetched = true;

      return { ...state, ...newState };
    }

    case 'SET_MANAGEMENT_COMPANY_FETCHING_STATUS': {
      const newState = { ...state };
      newState.managementCompany.fetching = action.fetching;
      newState.managementCompany.fetched = action.fetched;

      return { ...state, ...newState };
    }

    case 'SET_MANAGEMENT_COMPANY_FUNDS': {
      const newState = { ...state };
      newState.funds = [...action.funds];
      return { ...state, ...newState };
    }

    case 'CLEAR_MANAGEMENT_COMPANY': {
      const newState = { ...state };
      newState.funds = [];
      newState.managementCompany.model = {};
      return { ...state, ...newState };
    }
    case 'SET_SAME_MANAGEMENT_COMPANY_FUNDS': {
      const newState = { ...state };
      newState.sameManagementCompanyFunds = [...action.funds];
      return { ...state, ...newState };
    }

    case 'SET_FUND_LIST_FETCHING_STATUS': {
      const newState = { ...state };
      newState.fundList.fetching = action.fetching;
      newState.fundList.fetched = action.fetched;

      return { ...state, ...newState };
    }

    case 'SET_FUND_LIST': {
      const newState = { ...state };
      newState.fundList = {
        ...newState.fundList,
        items: [...action.items],
        paging: { ...action.paging },
        fetching: false,
        fetched: true
      };

      return { ...state, ...newState };
    }

    case 'ADD_FUND_LIST': {
      const newState = { ...state };
      newState.fundList.items = [...newState.fundList.items, ...action.items];
      newState.fundList.fetching = false;

      return { ...state, ...newState };
    }

    case 'SET_FUNDS_FILTERS': {
      const newState = { ...state };
      newState.filters = { ...action.filters };
      newState.fundList.fetching = true;
      return { ...state, ...newState };
    }

    case 'ADD_FUND_LIST_PAGE': {
      const newState = { ...state };
      newState.fundList.page = action.page;

      return { ...state, ...newState };
    }

    case 'SET_FUNDS_MOST_VIEWED': {
      const newState = { ...state };
      newState.mostViewed = {
        items: [...action.items]
      };
      newState.mostViewed.fetching = false;
      newState.mostViewed.request = action.request;

      return { ...state, ...newState };
    }

    case 'SET_FUNDS_MOST_VIEWED_FETCHING': {
      const newState = { ...state };
      newState.mostViewed.fetching = action.fetching;
      return { ...state, ...newState };
    }

    case 'SET_FUNDS_MOST_COMPARED': {
      const newState = { ...state };
      newState.mostCompared = {
        items: [...action.items]
      };
      newState.mostCompared.fetching = false;
      newState.mostCompared.request = action.request;

      return { ...state, ...newState };
    }

    case 'SET_FUNDS_MOST_COMPARED_FETCHING': {
      const newState = { ...state };
      newState.mostCompared.fetching = action.fetching;
      return { ...state, ...newState };
    }

    case 'SET_FUNDS_MOST_COMPARED_YTD': {
      const newState = { ...state };
      newState.mostComparedYTD = {
        items: [...action.items]
      };
      newState.mostComparedYTD.fetching = false;
      newState.mostComparedYTD.request = action.request;

      return { ...state, ...newState };
    }

    case 'SET_FUNDS_MOST_COMPARED_YTD_FETCHING': {
      const newState = { ...state };
      newState.mostComparedYTD.fetching = action.fetching;
      return { ...state, ...newState };
    }

    // getFundsByYTD
    case 'SET_FUNDS_BY_YTD': {
      const newState = { ...state };
      newState.fundsByYTD = {
        items: [...action.items]
      };
      newState.fundsByYTD.fetching = false;
      newState.fundsByYTD.request = action.request;

      return { ...state, ...newState };
    }

    case 'SET_FUNDS_BY_ASSETS': {
      const newState = { ...state };
      newState.fundsByAssets = {
        items: [...action.items]
      };
      newState.fundsByAssets.fetching = false;
      newState.fundsByAssets.request = action.request;

      return { ...state, ...newState };
    }

    case 'SET_FUNDS_BY_YTD_FETCHING_STATUS': {
      const newState = { ...state };
      newState.fundsByYTD.fetching = action.fetching;
      return { ...state, ...newState };
    }

    case 'SET_FUNDS_BY_ASSETS_FETCHING_STATUS': {
      const newState = { ...state };
      newState.fundsByAssets.fetching = action.fetching;
      return { ...state, ...newState };
    }

    // FundsByManagementCompany
    case 'SET_FUNDS_BY_MANGEMENT_COMPANY_FETCHING_STATUS': {
      const newState = { ...state };
      newState.fundsByManagementCompany.fetching = action.fetching;
      newState.fundsByManagementCompany.fetched = action.fetched;

      return { ...state, ...newState };
    }

    case 'SET_FUNDS_BY_MANGEMENT_COMPANY': {
      const newState = { ...state };
      newState.fundsByManagementCompany = {
        items: [...action.items],
        paging: { ...action.paging }
      };
      newState.fundsByManagementCompany.fetched = true;
      newState.fundsByManagementCompany.page = action.request.page;
      newState.fundsByManagementCompany.fetching = false;
      newState.fundsByManagementCompany.request = action.request;

      return { ...state, ...newState };
    }

    // FundsByCategory
    case 'SET_FUNDS_BY_CATEGORY_FETCHING_STATUS': {
      const newState = { ...state };
      newState.fundsByCategory.fetching = action.fetching;
      newState.fundsByCategory.fetched = action.fetched;

      return { ...state, ...newState };
    }

    case 'SET_FUNDS_BY_CATEGORY': {
      const newState = { ...state };
      newState.fundsByCategory = {
        items: [...action.items],
        paging: { ...action.paging }
      };

      newState.fundsByCategory.fetched = true;
      newState.fundsByCategory.fetching = false;
      newState.fundsByCategory.page = action.request.page;
      newState.fundsByCategory.request = action.request;

      return { ...state, ...newState };
    }

    default:
      return state;
  }
}
