// flag: mover a /models
import { slugify } from '@finect/front-utils/slugify';
import { base64Encode } from '@finect/front-utils/base64';
import { investmentProducts } from './models/investmentProducts';

export const articleWithSafeHTML = article => ({
  ...article,
  body: {
    ...article.body,
    text: base64Encode(article.body.text)
  }
});

const users = user => ({
  ...user,
  id: user.id,
  type: user.subtype,
  subtype: user.subtype,
  displayName: user.displayName.trim(),
  avatarURL: user.icon ? `/image/${user.icon}` : null,
  icon: user.icon ? user.icon : null,
  username: user.alias,
  alias: user.alias,
  email: user.email,
  firstName: user.name,
  lastName: user.surnames,
  headline: user.headline,
  certification: user.certification,
  adviser: user.adviser,
  stats: user.stats,
  unreadMessages: user.unreadMessages,
  isLoggedIn: true,
  risk: user.risk,
  achievements: user.achievements,
  administrations: user.administrations,
  advised: user.advised,
  aggregations: user.aggregations,
  isProfessional: user.isProfessional,
  organizations: user.organizations,
  features: user.features || [],
  notifications: user.notifications
});

const author = rest => ({
  ...rest,
  image: `/image/${rest.icon}`,
  link: `/usuario/${rest.alias}`
});

const comment = (model, authors) => {
  const profile = authors.find(({ id }) => id === model.author);

  return {
    ...model,
    author: profile ? author(profile) : null
  };
};
const comments = (rests, authors) => rests.map(rest => comment(rest, authors)).filter(item => item.author);

const review = (rest, authors) => ({
  id: rest.id,
  author: author(authors.find(el => el.id === rest.author)),
  score: rest.score,
  title: rest.title,
  features: rest.features,
  body: rest.body,
  revision: rest.revision,
  socialStats: rest.socialStats,
  comments: comments(rest.comments, authors)
});
const reviews = (rests, authors) => rests.map(rest => review(rest, authors));

const reviewFeature = rest => ({
  id: rest.id,
  description: rest.description
});
const reviewFeatures = rests => rests.map(rest => reviewFeature(rest));

const answer = (rest, authors) => {
  const answerAuthor = authors.find(({ id }) => id === rest.author);

  return {
    ...rest,
    author: answerAuthor
  };
};

const answers = (rests, authors) => rests.map(rest => answer(rest, authors)).filter(item => item);

const question = (rest, authors) => {
  if (authors.find(el => el.id === rest.author)) {
    return {
      ...rest,
      ...{
        author: author(authors.find(el => el.id === rest.author)),
        link: `/preguntas/${rest.id}/${slugify(rest.title)}`,
        answers: answers(rest.answers, authors)
      }
    };
  }
  return null;
};

const questions = (rests, authors) => rests.map(rest => question(rest, authors)).filter(item => item);

const article = (rest, authors) => {
  const authorModel = authors.find(el => el && el.hasOwnProperty('id') && el.id === rest.author);
  return {
    ...rest,
    subtitles: rest.subtitles ? typeof rest.subtitles === 'string' ? JSON.parse(rest.subtitles) : rest.subtitles : [],
    metadata: {
      ...rest.metadata || rest.body.metadata
    },
    author: authorModel ? author(authorModel) : null
  };
};

const articles = (rests, authors) => rests.map(rest => article(rest, authors));
const safeArticles = (rests, authors) => rests.map(rest => article(articleWithSafeHTML(rest), authors));

const articleComment = (rest, authors) => ({
  ...rest,
  author: author(authors.find(el => el.id === rest.author))
});
const articleComments = (rests, authors) => rests.map(rest => articleComment(rest, authors));

const products = rests =>
  rests.map(rest => ({
    ...rest.entity,
    ...{ subtype: rest.subtype }
  }));

const video = (rest, authors) => ({
  ...rest,
  author: author(authors.find(el => el.id === rest.author))
});

const videos = (rests, authors) => rests.map(rest => video(rest, authors));

export const models = {
  users,
  author,
  review,
  reviews,
  comment,
  comments,
  reviewFeature,
  reviewFeatures,
  question,
  questions,
  investmentProducts,
  article,
  articles,
  safeArticles,
  articleComment,
  articleComments,
  video,
  videos,

  products
};
