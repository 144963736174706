// @flow

// Contents
export const SET_MODAL = 'SET_MODAL';

const CONSTANTS = {
  SET_TAG: 'SET_TAG',
  CLEAR_TAG: 'CLEAR_TAG',
  SET_TAG_FETCHING_STATUS: 'SET_TAG_FETCHING_STATUS',
  SET_TAG_VIDEOS: 'SET_TAG_VIDEOS',
  SET_TAG_CONTENTS: 'SET_TAG_CONTENTS',
  SET_TAG_CONTENTS_FETCHING_STATUS: 'SET_TAG_CONTENTS_FETCHING_STATUS',
  SET_TAG_LATEST_CONTENTS: 'SET_TAG_LATEST_CONTENTS',
  SET_TAG_LATEST_CONTENTS_FETCHING_STATUS: 'SET_TAG_LATEST_CONTENTS_FETCHING_STATUS',
  SET_TAG_ENTITIES_FETCHING_STATUS: 'SET_TAG_ENTITIES_FETCHING_STATUS'
};

export default CONSTANTS;

// Banks (antiguas)
export const BANKS_CLEAR = 'BANKS_CLEAR';
export const BANKS_SET_ORGANIZATION = 'BANKS_SET_ORGANIZATION';
export const BANKS_SET_ORGANIZATION_FETCHING_STATUS = 'BANKS_SET_ORGANIZATION_FETCHING_STATUS';

export const BANKS_SET_ARTICLE = 'BANKS_SET_ARTICLE';
export const BANKS_SET_ARTICLE_FETCHING_STATUS = 'BANKS_SET_ARTICLE_FETCHING_STATUS';

export const BANKS_SET_QUESTIONS = 'BANKS_SET_QUESTIONS';
export const BANKS_SET_QUESTIONS_FETCHING_STATUS = 'BANKS_SET_QUESTIONS_FETCHING_STATUS';

export const BANKS_SET_MANAGEMENT_COMPANIES = 'BANKS_SET_MANAGEMENT_COMPANIES';
export const BANKS_SET_MANAGEMENT_COMPANIES_FETCHING_STATUS = 'BANKS_SET_MANAGEMENT_COMPANIES_FETCHING_STATUS';

export const BANKS_SET_PAGE = 'BANKS_SET_PAGE';
export const BANKS_SET_PAGE_FETCHING_STATUS = 'BANKS_SET_PAGE_FETCHING_STATUS';
