// @flow
import React from 'react';
import styled from 'styled-components';
import { getFont } from '@finect/tabular-utils/getFont';
import { Spinner } from './Spinner';

const More = styled.div`
  margin: 30px auto;
`;

const LoadButton = styled.button`
  display: inline-block;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.ui};
  border-radius: 4px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.bodyFontFamily};
  ${getFont(-1)}
  line-height: 34px;
  color: ${({ theme, light }) => (light ? theme.fontLightColor : theme.colors.primary)};
  text-align: center;
  transition: all 0.15s ease;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
  }

  &[disabled] {
    border-color: ${({ theme }) => theme.colors.ui};
  }
`;

type Props = {
  children: any,
  light?: boolean,
  onClick: Function,
  loading: boolean
};

export class LoadMore extends React.Component<Props> {
  retrieveMore = () => {
    this.props.onClick && this.props.onClick();
  };

  render() {
    const { children, light, loading } = this.props;

    return (
      <More>
        <LoadButton light={light} disabled={loading} onClick={this.retrieveMore}>
          {loading ? <Spinner /> : children}
        </LoadButton>
      </More>
    );
  }
}
