// @flow
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { BreadCrumbsContent, BreadCrumbSpan, BreadCrumbLink } from './partials';

type Props = Array<{
  text: string,
  href?: string,
  type?: 'client'
}>;

export const BreadCrumb = ({ breadCrumbs }: { breadCrumbs: Props }) => {
  const schemaORG = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadCrumbs
      .filter(crumb => crumb.href)
      .map((crumb, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@id': `https://www.finect.com${crumb.href ? crumb.href : ''}`,
          name: typeof crumb.text === 'string' ? crumb.text : 'Home'
        }
      }))
  };
  return (
    <Fragment>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaORG)}</script>
      </Helmet>
      <BreadCrumbsContent>
        {breadCrumbs.map((crumb, index) => {
          const linkProps =
            crumb.type === 'client'
              ? {
                to: crumb.href,
                as: Link
              }
              : {
                href: crumb.href
              };

          const wrap = breadCrumbs.length === index + 1;
          return (
            <Fragment key={index}>
              {!crumb.href ? (
                <BreadCrumbSpan wrap={wrap}>{crumb.text}</BreadCrumbSpan>
              ) : (
                <BreadCrumbLink wrap={wrap} {...linkProps}>
                  {crumb.text}
                </BreadCrumbLink>
              )}
              {breadCrumbs.length !== index + 1 ? ' / ' : null}
            </Fragment>
          );
        })}
      </BreadCrumbsContent>
    </Fragment>
  );
};
